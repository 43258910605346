export const set = async (key: string, value: string) => {
  return window.localStorage.setItem(key, value);
};

export const get = async (
  key: string,
  fallback?: string
): Promise<string | undefined> => {
  try {
    const value = await window.localStorage.getItem(key);
    if (value !== null) {
      return value;
    }
  } catch (e) {
    // error reading value
  }
  return fallback;
};
