import { Box, Paper, Typography } from "@material-ui/core";
import { colors } from "common/colors";
import { DisplayName } from "components/DisplayName";
import Row from "components/Row";
import { Space } from "components/Space";
import { PostReply } from "contract/data-collection/post";
import { useUserProfile } from "hooks/useUserProfile";
import React from "react";
import { useTranslation } from "react-i18next";

export interface PostReplyToProps {
  reply: PostReply;
}

const PostReplyTo: React.FC<PostReplyToProps> = ({ reply }) => {
  const { t } = useTranslation("PostReplyTo");
  const replyAuthorProfile = useUserProfile(reply.uid);

  return (
    <Paper elevation={0} style={{ padding: 8, borderRadius: 4 }}>
      <Row>
        <Typography
          variant="caption"
          style={{ color: colors["text-hint-color"] }}
        >
          {t("replyTo")!}
        </Typography>
        <Space size={1} horizontal />
        <Box>
          <DisplayName profile={replyAuthorProfile} category="caption" />
        </Box>
        <Space size={1} horizontal />
        <Typography
          variant="caption"
          // numberOfLines={1}
          style={{ flex: 1, color: colors["text-hint-color"] }}
        >
          {reply.text}
        </Typography>
      </Row>
    </Paper>
  );
};

export default PostReplyTo;
