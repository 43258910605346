import React from "react";
import { Avatar, Box, Divider, Typography } from "@material-ui/core";
import Happiness from "assets/reaction/Happiness.png";
import { colors } from "common/colors";
import { DisplayName } from "components/DisplayName";
import { ProfileEditListItemText } from "./ProfileEditListItemText";
import { formatFriendCode } from "utils/formatFriendCode";
import { UserApi } from "api/user";
import { Region, Fruit } from "contract/common";
import { formatDreamAddress } from "utils/formatDreamAddress";
import { ProfileEditListItemSelect } from "./ProfileEditListItemSelect";
import { UserProfile } from "contract/view-collection/user";
import { Space } from "components/Space";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

export interface BasicInfoSectionProps {
  user: firebase.User;
  profile: UserProfile;
}

const BasicInfoSection: React.FC<BasicInfoSectionProps> = ({
  user,
  profile,
}) => {
  const { t } = useTranslation("PassportScreenBasicInfoSection");
  const { enqueueSnackbar } = useSnackbar();
  const verified = profile.verificationLevel !== undefined;

  return (
    <Box
      style={{
        backgroundColor: "white",
        padding: "48px 16px",
        borderRadius: 8,
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Avatar
          variant="square"
          src={profile?.photoURL || Happiness}
          onClick={() => {
            enqueueSnackbar("Please update your profile photo in Link App.", {
              variant: "info",
            });
          }}
          style={{
            borderStyle: "solid",
            borderColor: colors["bg-color-off-white-500"],
            borderWidth: 4,
            borderRadius: 16,
            width: 72,
            height: 72,
          }}
        />
        <Space size={1} />
        <DisplayName profile={profile} category="h6" />
        <Typography
          variant="caption"
          style={{ color: colors["text-hint-color"] }}
        >
          {user?.email}
        </Typography>
      </Box>
      <Space size={2} />
      <ProfileEditListItemText
        title={t("displayNameLabel")}
        disabled={verified}
        value={profile?.displayName}
        validate={(s) => {
          if (!!s && s.length <= 10) {
            return undefined;
          }

          return t("errorMsg.displayName")!;
        }}
        onSubmit={(s) => {
          return UserApi.updateProfile({
            displayName: s,
          });
        }}
      />
      <ProfileEditListItemText
        title={t("islandNameLabel")}
        disabled={verified}
        value={profile?.islandName}
        validate={(s) => {
          if (!!s && s.length <= 10) {
            return undefined;
          }

          return t("errorMsg.islandName")!;
        }}
        onSubmit={(s) => {
          return UserApi.updateProfile({
            islandName: s,
          });
        }}
      />
      <ProfileEditListItemSelect
        title={t("regionLabel")}
        selected={
          profile?.region
            ? Object.values(Region).indexOf(profile?.region)
            : undefined
        }
        options={Object.values(Region).map((i) =>
          t(`region.${i}`, { ns: "shared" })
        )}
        onSubmit={(idx) => {
          return UserApi.updateProfile({
            region: Object.values(Region)[idx],
          });
        }}
      />
      <ProfileEditListItemText
        title={t("dreamAddressLabel")}
        caption={t("dreamAddressHelptext")}
        value={profile?.dreamAddress}
        valueDisplay={
          profile?.dreamAddress
            ? formatDreamAddress(profile?.dreamAddress)
            : undefined
        }
        validate={(s) => {
          if (!!s && s.length === 12) return undefined;
          if (!s) return undefined;
          return t("errorMsg.dreamAddress")!;
        }}
        onSubmit={(s) => {
          return UserApi.updateProfile({
            dreamAddress: s,
          });
        }}
      />
      <ProfileEditListItemText
        title={t("nsidLabel")}
        value={profile?.nsId}
        valueDisplay={profile.nsId ? formatFriendCode(profile.nsId) : undefined}
        validate={(s) => {
          if (!!s && s.length <= 17 && s.length >= 12) return undefined;
          if (!s) return undefined;
          return t("errorMsg.nsid")!;
        }}
        onSubmit={(s) => {
          return UserApi.updateProfile({
            nsId: s,
          });
        }}
      />
      <Space size={1} />
      <Divider />
      <Space size={1} />
      <ProfileEditListItemSelect
        title={t("fruitLabel")}
        selected={
          profile?.fruit
            ? Object.values(Fruit).indexOf(profile?.fruit)
            : undefined
        }
        options={Object.values(Fruit).map((i) =>
          t(`fruit.${i}`, { ns: "shared" })
        )}
        onSubmit={(idx) => {
          return UserApi.updateProfile({
            fruit: Object.values(Fruit)[idx],
          });
        }}
      />

      <Space size={2} />
      <Typography
        variant="caption"
        style={{ color: colors["text-hint-color"] }}
      >
        * Click to update your profile.
      </Typography>
    </Box>
  );
};

export default BasicInfoSection;
