import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Space } from "../../../components/Space";
import { LinkButton } from "../../../components/LinkButton";
import dodoairlinesImg from "../../../assets/dodoairlines.png";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export const Hero: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      bgcolor="white"
      flex={1}
      height={isMobile ? 552 : 320}
      py={8}
      px={8}
      borderRadius={4}
      display="flex"
      style={
        isMobile
          ? { flexDirection: "column-reverse" }
          : { flexDirection: "row" }
      }
    >
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        flexBasis="auto"
        justifyContent="center"
        flexShrink={0}
      >
        <Typography
          variant="h5"
          gutterBottom
          color="textPrimary"
          style={{ fontWeight: "bold" }}
        >
          Welcome to the community!
        </Typography>

        <Typography variant="subtitle1" gutterBottom color="textPrimary">
          No one should be left alone. We are all here in Link Airport
          <span role="img" aria-label="hello emoji">
            👋
          </span>{" "}
          <br /> <br /> Download the App and connect with everyone now!
        </Typography>
        <Space size={2} />

        <Box
          display="flex"
          style={
            isMobile ? { flexDirection: "column" } : { flexDirection: "row" }
          }
        >
          <LinkButton
            title="App Store"
            link="https://apps.apple.com/us/app/id1519622470"
          />
          <Space horizontal={!isMobile} size={2} />
          <LinkButton
            title="Google Play Store"
            link="https://play.google.com/store/apps/details?id=link.acnh.mobile_app"
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flexGrow={1}
        flexBasis="auto"
        flexShrink={1}
        style={isMobile ? { maxHeight: "calc(100vw - 164px)" } : undefined}
      >
        <Box
          component="img"
          // @ts-ignore
          src={dodoairlinesImg}
          width="100%"
          height="auto"
          style={{ objectFit: "contain" }}
        ></Box>
      </Box>
    </Box>
  );
};
