import React from "react";

export const useInputState = (initialValue = "") => {
  const [value, setValue] = React.useState(initialValue);
  const onChange = React.useCallback(({ target: { value: newValue } }) => {
    setValue(newValue);
  }, []);
  return {
    value,
    onChange,
    reset: () => {
      setValue("");
    },
  };
};
