import { useSnackbar } from "notistack";
import React from "react";

export function useProgress(
  successMessage: string | undefined = undefined,
  errorMessage: string | ((e: Error) => string) | undefined = undefined
) {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const { enqueueSnackbar } = useSnackbar();

  return {
    loading,
    error,
    start: () => setLoading(true),
    succeeded: () => {
      setLoading(false);
      setError("");

      if (successMessage) {
        enqueueSnackbar(successMessage, {
          variant: "success",
        });
      }
    },
    failed: (error: Error) => {
      setError(error.message);
      setLoading(false);
      if (errorMessage) {
        enqueueSnackbar(errorMessage, {
          variant: "error",
        });
      }
    },
  };
}
