import React from "react";
import { UserProfile } from "contract/view-collection/user";
import { DisplayName } from "components/DisplayName";
import { Space } from "components/Space";
import { getFruitImage } from "utils/getFruitImage";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { formatDreamAddress } from "utils/formatDreamAddress";
import { formatFriendCode } from "utils/formatFriendCode";
import { Avatar, Box, Icon, Paper, Typography } from "@material-ui/core";
import { colors } from "common/colors";
import TouchableOpacity from "components/TouchableOpacity";
import { AuthContext } from "modules/auth/AuthContext";
import { OpenReportModal } from "../Report/useReportModal";
import { ReportSubject } from "contract/common";

export interface PassportProps {
  profile: UserProfile;
  onPressReport: OpenReportModal;
}
export const Passport: React.FC<PassportProps> = ({
  profile,
  onPressReport,
}) => {
  const user = React.useContext(AuthContext);
  const { t } = useTranslation("Passport");

  const {
    uid,
    islandName,
    dreamAddress,
    nsId,
    fruit,
    createdAt,
    disabledAt,
    photoURL,
  } = profile;

  return (
    <Paper
      elevation={1}
      style={{
        borderBottomLeftRadius: 24,
        borderBottomRightRadius: 24,
        paddingBottom: 48,
        borderTopWidth: 4,
        borderTopStyle: "solid",
        borderColor: colors["bg-color-teal-500"],
        backgroundColor: colors["bg-color-white-500"],
        position: "relative",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {user && uid !== user.uid && (
        <TouchableOpacity
          onPress={() => {
            onPressReport({
              reporteeUid: uid,
              subject: ReportSubject.ISLAND,
            });
          }}
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: 8,
          }}
        >
          <Icon
            className="fas fa-flag"
            style={{
              fontSize: 8,
              marginRight: 4,
              color: colors["color-danger-500"],
            }}
          />
          <Typography
            variant="caption"
            style={{ color: colors["color-danger-500"], fontSize: 8 }}
          >
            {t("reportLabel")!}
          </Typography>
        </TouchableOpacity>
      )}
      <Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: 40,
          }}
        >
          <Box style={{ width: "40%", position: "relative" }}>
            <Box
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                style={{
                  backgroundColor: colors["bg-color-teal-500"],
                  width: "100%",
                  height: 1,
                }}
              />
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="caption"
                style={{
                  color: colors["bg-color-teal-500"],
                  paddingLeft: 12,
                  paddingRight: 12,
                  backgroundColor: colors["bg-color-white-500"],
                  fontSize: 13,
                  zIndex: 9,
                }}
              >
                {t("passportLabel")!}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingTop: 24,
            paddingBottom: 24,
            paddingLeft: 24,
            paddingRight: 24,
            marginLeft: 20,
            marginRight: 20,
            backgroundImage: "url(/leaf-pattern-2.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Avatar
              variant="rounded"
              src={
                photoURL || require("../../../../assets/reaction/Confident.png")
              }
              style={{
                marginRight: 8,
                backgroundColor: colors["bg-color-white-500"],
                borderColor: colors["bg-color-off-white-500"],
                borderWidth: 4,
                borderStyle: "solid",
                borderRadius: 8,
                width: 48,
                height: 48,
              }}
            />
            <Box
              style={{
                flex: 1,
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Box
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Icon
                    className="fas fa-umbrella-beach"
                    style={{
                      fontSize: 10,
                      marginRight: 4,
                      // color: colors["text-hint-color"],
                    }}
                  />
                  <Typography variant="caption">
                    {islandName || t("unknown.islandName", { ns: "shared" })!}
                  </Typography>
                </Box>
                {!!fruit && (
                  <Box
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={getFruitImage(fruit)}
                      style={{ marginRight: 4, width: 16, height: 16 }}
                      alt="fruit"
                    />
                    <Typography variant="caption">
                      {t(`fruit.${fruit}`, { ns: "shared" })!}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginTop: 8,
                }}
              >
                <Typography variant="caption" style={{ fontWeight: 600 }}>
                  {
                    t(`identity.${profile.membership || "TRAVELLER"}`, {
                      ns: "shared",
                    })!
                  }
                </Typography>
              </Box>
            </Box>
          </Box>

          <Space size={1} />
          <DisplayName profile={profile} category="h5" />
          <Space size={0.5} />

          <Box
            style={{
              flex: 1,
              marginTop: 8,
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Icon
                  className="fas fa-globe"
                  style={{
                    fontSize: 10,
                    marginRight: 4,
                    // color: colors["text-hint-color"],
                  }}
                />
                <Typography variant="caption" style={{ fontSize: 10 }}>
                  {
                    t(`region.${profile.region || "UNKNOWN"}`, {
                      ns: "shared",
                    })!
                  }
                </Typography>
              </Box>
            </Box>
            {!!dreamAddress && (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginTop: 8,
                }}
              >
                <Box
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Icon
                    className="fas fa-moon"
                    style={{
                      fontSize: 10,
                      marginRight: 4,
                      // color: colors["text-hint-color"],
                    }}
                  />
                  <Typography variant="caption" style={{ fontSize: 10 }}>
                    {formatDreamAddress(dreamAddress)}
                  </Typography>
                </Box>
              </Box>
            )}
            {!!nsId && (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginTop: 8,
                }}
              >
                <Box
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Icon
                    className="fas fa-satellite-dish"
                    style={{
                      fontSize: 10,
                      marginRight: 4,
                      // color: colors["text-hint-color"],
                    }}
                  />
                  <Typography variant="caption" style={{ fontSize: 10 }}>
                    {formatFriendCode(nsId)}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          position: "absolute",
          bottom: 12,
          left: 40,
          right: 40,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {disabledAt ? (
          <Typography
            variant="caption"
            style={{ color: colors["txt-color-brown-400"] }}
          >
            {
              t("invalidFrom", {
                disabledAt: format(disabledAt, "MMM dd, yyyy"),
              })!
            }
          </Typography>
        ) : (
          <Typography
            variant="caption"
            style={{ color: colors["txt-color-brown-400"] }}
          >
            {
              t("validFrom", {
                createdAt: createdAt ? format(createdAt, "MMM dd, yyyy") : "-",
              })!
            }
          </Typography>
        )}
        <Typography
          variant="caption"
          style={{
            color: colors["txt-color-brown-400"],
            letterSpacing: 1,
            fontSize: 12,
          }}
        >
          {"<<<<<<<<<<"}
        </Typography>
      </Box>
    </Paper>
  );
};
