import firebase from "common/firebase";

import { clean } from "common/word-filter";
import {
  Post,
  PostDealMessageContentType,
  PostDealRating,
} from "contract/data-collection/post";
import { rejectNil } from "utils/rejectNil";

export type PostPublishPayload = Pick<
  Post,
  "type" | "text" | "images" | "resident" | "itemOffer" | "itemRequest"
>;

export class PostApi {
  static publish(payload: PostPublishPayload) {
    return firebase
      .functions()
      .httpsCallable("postPublish")({
        ...payload,
        text: clean(payload.text),
      })
      .catch((error) => {
        // firebase.crashlytics().recordError(error);
        console.error(error);
        throw error;
      });
  }

  static reply(
    uid: string,
    postId: string,
    text: string,
    replyToReplyId?: string
  ) {
    return firebase
      .firestore()
      .collection("posts")
      .doc(postId)
      .collection("replies")
      .add(
        rejectNil({
          uid: uid,
          text: clean(text),
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          replyTo: replyToReplyId,
        })
      )
      .catch((error) => {
        // firebase.crashlytics().recordError(error);
        console.error(error);
      });
  }

  static replyDelete(postId: string, replyId: string) {
    return firebase
      .firestore()
      .collection("posts")
      .doc(postId)
      .collection("replies")
      .doc(replyId)
      .delete()
      .catch((error) => {
        // firebase.crashlytics().recordError(error);
        console.error(error);
      });
  }

  static message(
    uid: string,
    postId: string,
    postDealId: string,
    text: string
  ) {
    return firebase
      .firestore()
      .collection("posts")
      .doc(postId)
      .collection("postDeals")
      .doc(postDealId)
      .collection("postDealMessages")
      .add({
        uid: uid,
        text: clean(text),
        contentType: PostDealMessageContentType.String,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .catch((error) => {
        // firebase.crashlytics().recordError(error);
        console.error(error);
      });
  }

  static like(uid: string, postId: string) {
    return firebase
      .firestore()
      .collection("posts")
      .doc(postId)
      .collection("likes")
      .doc(uid)
      .set({
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .catch((error) => {
        // firebase.crashlytics().recordError(error);
        console.error(error);
      });
  }

  static unlike(uid: string, postId: string) {
    return firebase
      .firestore()
      .collection("posts")
      .doc(postId)
      .collection("likes")
      .doc(uid)
      .delete()
      .catch((error) => {
        // firebase.crashlytics().recordError(error);
        console.error(error);
      });
  }

  static updateInteractionFinished(
    postId: string,
    interactionFinished: boolean
  ) {
    return firebase
      .firestore()
      .collection("posts")
      .doc(postId)
      .set(
        {
          interactionFinished,
        },
        { merge: true }
      )
      .catch((error) => {
        // firebase.crashlytics().recordError(error);
        console.error(error);
      });
  }

  static confirmTransaction(postId: string, postDealId: string) {
    return firebase
      .functions()
      .httpsCallable("postDealTransationConfirm")({
        postId,
        postDealId,
      })
      .catch((error) => {
        // firebase.crashlytics().recordError(error);
        console.error(error);
        throw error;
      });
  }

  static reviewPublish(
    postId: string,
    postDealId: string,
    text: string,
    rating: PostDealRating
  ) {
    return firebase
      .functions()
      .httpsCallable("postDealReviewPublish")({
        postId,
        postDealId,
        text,
        rating,
      })
      .catch((error) => {
        // firebase.crashlytics().recordError(error);
        console.error(error);
        throw error;
      });
  }

  static likeReply(uid: string, postId: string, postReplyId: string) {
    return firebase
      .firestore()
      .collection("posts")
      .doc(postId)
      .collection("replies")
      .doc(postReplyId)
      .collection("likes")
      .doc(uid)
      .set({
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .catch((error) => {
        // firebase.crashlytics().recordError(error);
        console.error(error);
      });
  }

  static unlikeReply(uid: string, postId: string, postReplyId: string) {
    return firebase
      .firestore()
      .collection("posts")
      .doc(postId)
      .collection("replies")
      .doc(postReplyId)
      .collection("likes")
      .doc(uid)
      .delete()
      .catch((error) => {
        // firebase.crashlytics().recordError(error);
        console.error(error);
      });
  }
}
