import { Box, Typography } from "@material-ui/core";
import { colors } from "common/colors";
import { Chip } from "components/Chip";
import React from "react";

export interface ChipWithCaptionProps {
  icon: string;
  caption: React.ReactNode;
}

export function ChipWithCaption({ icon, caption }: ChipWithCaptionProps) {
  return (
    <Box style={{ display: "flex", flexDirection: "row" }}>
      <Box style={{ width: 36, display: "flex", flexDirection: "row" }}>
        <Chip size={10} icon={`fas fa-${icon}`} title="" />
      </Box>
      <Typography
        variant="caption"
        style={{ color: colors["txt-color-brown-500"], fontWeight: 600 }}
      >
        {caption}
      </Typography>
    </Box>
  );
}
