import * as firebase from "firebase/app";

import "firebase/analytics";

import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import { isDev } from "./constants";

const firebaseConfig = false
  ? {
      apiKey: "AIzaSyAhZiS3Byx4NF1xGdBfw3DLzJZoTbmnmNA",
      authDomain: "acnh-link-dev.firebaseapp.com",
      databaseURL: "https://acnh-link-dev.firebaseio.com",
      projectId: "acnh-link-dev",
      storageBucket: "acnh-link-dev.appspot.com",
      messagingSenderId: "173026027171",
      appId: "1:173026027171:web:b9117f790a6e56871a4116",
      measurementId: "G-H2L3R7YRLT",
    }
  : {
      apiKey: "AIzaSyA69KIk6OHiOpoJvRP4fdqpQpw5X3NhZf0",
      authDomain: "acnh-link.firebaseapp.com",
      databaseURL: "https://acnh-link.firebaseio.com",
      projectId: "acnh-link",
      storageBucket: "acnh-link.appspot.com",
      messagingSenderId: "317880756682",
      appId: "1:317880756682:web:64851690941bbeead24733",
      measurementId: "G-X6KNTPSHD7",
    };

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

if (isDev) {
  window.firebase = firebase;
}

export default firebase;
