import { Membership } from "contract/common";
import Citizen from "assets/badge/CITIZEN.svg";
import Councilor from "assets/badge/COUNCILOR.svg";
import Investor from "assets/badge/INVESTOR.svg";
import InvestorPlus from "assets/badge/INVESTOR_PLUS.svg";
import Resident from "assets/badge/RESIDENT.svg";
import ResidentPlus from "assets/badge/RESIDENT_PLUS.svg";
import Streamer from "assets/badge/STREAMER.svg";
import StreamerPlus from "assets/badge/STREAMER_PLUS.svg";
import Supporter from "assets/badge/SUPPORTER.svg";

export function getColor(membership: Membership | undefined) {
  if (membership === undefined) {
    return "#6b5c43";
  }

  switch (membership) {
    case Membership.SUPPORTER:
      return "#6b5c43";
    case Membership.RESIDENT:
    case Membership.RESIDENT_PLUS:
    case Membership.STREAMER:
      return "#4b9fd8";
    case Membership.CITIZEN:
    case Membership.STREAMER_PLUS:
      return "#3789c9";
    case Membership.COUNCILOR:
      return "#F9B444";
    case Membership.INVESTOR:
    case Membership.INVESTOR_PLUS:
      return "#e95454";
  }
}

export function getBadgeComponent(membership: Membership | undefined) {
  if (membership === undefined) {
    return undefined;
  }

  switch (membership) {
    case Membership.SUPPORTER:
      return Supporter;
    case Membership.RESIDENT:
      return Resident;
    case Membership.RESIDENT_PLUS:
      return ResidentPlus;
    case Membership.STREAMER:
      return Streamer;
    case Membership.CITIZEN:
      return Citizen;
    case Membership.STREAMER_PLUS:
      return StreamerPlus;
    case Membership.COUNCILOR:
      return Councilor;
    case Membership.INVESTOR:
      return Investor;
    case Membership.INVESTOR_PLUS:
      return InvestorPlus;
  }
}

export function getMembershipDisplay(membership: Membership | undefined) {
  return {
    color: getColor(membership),
    BadgeComponent: getBadgeComponent(membership),
  };
}
