import { Box, Icon, Typography } from "@material-ui/core";
import React from "react";

export interface ChipProps {
  color?: string;
  size?: number;
  icon: string;
  title?: string | ((args: { color: string; size: number }) => any);
  style?: React.CSSProperties;
}

export const Chip: React.FC<ChipProps> = ({
  color = "#6B5C43",
  size = 10,
  icon,
  title,
  style,
}) => {
  return (
    <Box
      style={{
        backgroundColor: "rgba(248, 248, 240, 1)",
        padding: "4px 8px",
        borderRadius: 4,
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        ...style,
      }}
    >
      <Icon
        className={icon}
        style={{
          fontSize: size,
          marginRight: title ? 4 : 0,
          color,
          width: "unset",
        }}
      />
      {!title ? null : typeof title === "string" ? (
        <Typography
          style={{
            color,
            fontSize: size,
            fontWeight: 600,
          }}
        >
          {title}
        </Typography>
      ) : (
        title({ color, size })
      )}
    </Box>
  );
};
