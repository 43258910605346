import * as R from "ramda";
import firebase from "common/firebase";

// import badWordFilter from "../shared/badWordFilter";
import {
  FlightPassengerType,
  Terminal,
  TurnipsFlightType,
} from "contract/common";
import {
  FlightForm,
  ResidentWithStatus,
  SpecialCharacterWithStatus,
  EntryFee,
} from "contract/data-collection/flight";

export interface FlightOpenPayload {
  terminal?: Terminal;
  message?: string;

  entryFees?: EntryFee[];

  turnipsFlightType?: TurnipsFlightType;
  price?: number;

  residents?: ResidentWithStatus[];
  specialVisitors?: SpecialCharacterWithStatus[];

  planToCloseAt?: number;
  maxQueueSize?: number;
  maxAllowed?: number;
  maxStayTime?: number;

  allowedPassengerType?: FlightPassengerType;
  requirePassword?: boolean;

  dodoCode?: string;
  password?: string;
}

export interface FlightUpdatePayload {
  code: string;
  flightPassengerType?: FlightPassengerType;
  maxAllowed: number;
  maxStayTime: number;
  maxQueueSize: number;
  message?: string;
  planToCloseAt: Date;
  price?: number;
  requirePassword?: boolean;
  terminal: Terminal;
  turnipsFlightType?: TurnipsFlightType;
}

export class FlightApi {
  static ref = {
    flight: (uid: string) => firebase.database().ref("flight").child(uid),
  };

  static open(form: FlightOpenPayload) {
    const payload: FlightOpenPayload = {
      ...form,
      residents: form.residents?.filter(
        (resident) => resident.status !== undefined
      ),
      password: form.requirePassword ? this.getNewPassword() : undefined,
    };
    return firebase.functions().httpsCallable("flightOpen")(payload);
  }

  static update(form: FlightOpenPayload, previousForm: FlightForm | null) {
    const payload: FlightOpenPayload = {
      ...form,
      residents: form.residents?.filter(
        (resident) => resident.status !== undefined
      ),
    };
    if (
      (!previousForm || !previousForm.requirePassword) &&
      form.requirePassword
    ) {
      payload.password = this.getNewPassword();
    }
    if (previousForm) {
      Object.keys(payload).forEach((key) => {
        // Always send `terminal` as required by the API
        if (key === "terminal") return;
        // Always send `price` & `turnipsFlightType` if `terminal` is TURNIPS as required by the API
        if (
          payload.terminal === Terminal.TURNIPS &&
          ["price", "turnipsFlightType"].includes(key)
        )
          return;

        if (R.pathEq([key], R.path([key])(payload))(previousForm)) {
          // @ts-ignore
          delete payload[key];
        }
      });
    }

    return firebase.functions().httpsCallable("flightUpdate")(payload);
  }

  static close() {
    return firebase.functions().httpsCallable("flightClose")();
  }

  // static reopen() {
  //   return firebase.firebase.functions().httpsCallable("flightReopen")();
  // }

  // static lock() {
  //   return firebase.firebase.functions().httpsCallable("flightLock")();
  // }

  // static unlock() {
  //   return firebase.firebase.functions().httpsCallable("flightUnlock")();
  // }

  static getDodoCode(flightCreatorId: string) {
    return firebase
      .functions()
      .httpsCallable("flightGetDodoCode")({
        flightCreatorId,
      })
      .then(({ data }) => data);
  }

  static resetPassword() {
    return firebase.functions().httpsCallable("flightUpdateResetPassword")({
      password: this.getNewPassword(),
    });
  }

  static getNewPassword() {
    return String(Math.random()).substring(2, 6);
  }
}
