import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  makeStyles,
  TextField,
  FormHelperText,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from "@material-ui/core";
import { colors } from "common/colors";
import { BubbleButton } from "components/BubbleButton";
import { InProgressModal } from "components/InProgressModal";
import Row from "components/Row";
import { SectionTitle } from "components/SectionTitle";
import { Space } from "components/Space";
import TextButton from "components/TextButton";
import {
  FlightPassengerType,
  Terminal,
  TurnipsFlightType,
} from "contract/common";
import { AuthContext } from "modules/auth/AuthContext";
import { ProfileContext } from "modules/auth/ProfileContext";
import { useCloseFlight } from "pages/FlightForm/hooks/useCloseFlight";
import {
  FlightFormField,
  useFlightForm,
} from "pages/FlightForm/hooks/useFlightForm";
import React from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { formatEndTimeFormValue } from "utils/formatEndTimeFormValue";

const useStyles = () =>
  makeStyles((theme) => ({
    formControl: {},
  }));

export const FlightForm = () => {
  const { t } = useTranslation("FlightFormScreen");
  const styles = useStyles()();

  const user = React.useContext(AuthContext);
  const profile = React.useContext(ProfileContext);
  const profileVerified = profile?.verificationLevel !== undefined;
  const {
    form,
    error,
    label,
    placeholder,
    helptext,
    updateField,
    submit,
    submitting,
    submitDisabled,
    isFlightCreated,
    // isFlightOpen,
  } = useFlightForm(user?.uid, profile, profileVerified);
  const [
    isFlightCloseConfirmModalVisible,
    setIsFlightCloseConfirmModalVisible,
  ] = React.useState(false);

  const { flightClose, flightClosing } = useCloseFlight();

  if (profile === undefined || form === undefined) {
    return (
      <Row center style={{ padding: "24px 0" }}>
        <CircularProgress />
      </Row>
    );
  }

  if (user === null) {
    return <Redirect to="/sign-in" />;
  }

  return (
    <Box
      style={{
        borderRadius: 8,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          style={{ fontWeight: "bold", color: "#6B5C43" }}
        >
          {isFlightCreated ? t("updateFlightLabel") : t("createFlightLabel")}
        </Typography>
      </Box>
      <Space size={4} />
      <SectionTitle
        title={t("purposeSectionTitleLabel")}
        variant="subtitle1"
        underscoreColor="#98d2e3"
      />
      <Space size={4} />
      <FormControl variant="outlined" className={styles.formControl}>
        <InputLabel id="flight-form-terminal-label">
          {label.Terminal}
        </InputLabel>
        <Select
          labelId="flight-form-terminal-label"
          id="flight-form-terminal"
          value={form.Terminal}
          label={label.Terminal}
          onChange={({ target: { value } }) => {
            updateField(FlightFormField.Terminal, value as Terminal, form);
          }}
        >
          <MenuItem value={Terminal.TURNIPS}>Turnips</MenuItem>
          <MenuItem value={Terminal.GENERAL}>General</MenuItem>
        </Select>
        <FormHelperText>{helptext.Terminal}</FormHelperText>
      </FormControl>
      <Space size={2} />
      <TextField
        id="flight-form-message"
        label={label.Message}
        variant="outlined"
        multiline
        rowsMax={4}
        rows={4}
        InputLabelProps={{
          shrink: true,
        }}
        value={form.Message}
        placeholder={placeholder[FlightFormField.Message]}
        helperText={helptext.Message}
        error={!!error.Message}
        onChange={({ target: { value } }) => {
          updateField(FlightFormField.Message, value as string, form);
        }}
      />
      <Space size={4} />
      <SectionTitle
        title={t("detailsSectionTitleLabel")}
        variant="subtitle1"
        underscoreColor="#98d2e3"
      />
      <Space size={4} />
      <TextField
        label={label.EntryFees}
        variant="outlined"
        value="This feature is currently only available on the mobile app."
        helperText={helptext.EntryFees}
        disabled
      />
      {form.Terminal === Terminal.TURNIPS && (
        <React.Fragment>
          <Space size={2} />
          <FormControl variant="outlined" className={styles.formControl}>
            <InputLabel id="flight-form-terminal-label">
              {label.TurnipsFlightType}
            </InputLabel>
            <Select
              labelId="flight-form-terminal-label"
              id="flight-form-terminal"
              value={form.TurnipsFlightType}
              label={label.TurnipsFlightType}
            >
              <MenuItem value={TurnipsFlightType.SELLING}>Selling</MenuItem>
              <MenuItem value={TurnipsFlightType.BUYING}>Buying</MenuItem>
            </Select>
            <FormHelperText>{helptext.TurnipsFlightType}</FormHelperText>
          </FormControl>
          <Space size={2} />
          <TextField
            label={label.Price}
            variant="outlined"
            type="number"
            defaultValue={0}
            value={form.Price}
            helperText={helptext.Price}
            error={!!error.Price}
            onChange={({ target: { value } }) => {
              updateField(FlightFormField.Price, parseInt(value, 10), form);
            }}
          />
        </React.Fragment>
      )}
      {form.Terminal === Terminal.GENERAL && (
        <React.Fragment>
          <Space size={2} />
          <TextField
            label={label.Residents}
            helperText={helptext.Residents}
            variant="outlined"
            value="This feature is currently only available on the mobile app."
            disabled
          />
          <Space size={2} />
          <TextField
            label={label.SpecialVisitors}
            helperText={helptext.SpecialVisitors}
            variant="outlined"
            value="This feature is currently only available on the mobile app."
            disabled
          />
        </React.Fragment>
      )}
      <Space size={4} />
      <SectionTitle
        title={t("basicSettingsSectionTitleLabel")}
        variant="subtitle1"
        underscoreColor="#98d2e3"
      />
      <Space size={4} />
      <TextField
        id="endtime"
        variant="outlined"
        label={label.PlanToCloseAt}
        helperText={helptext.PlanToCloseAt}
        error={!!error.PlanToCloseAt}
        type="datetime-local"
        defaultValue={formatEndTimeFormValue(new Date(form.PlanToCloseAt!))}
        onChange={({ target: { value } }) => {
          updateField(FlightFormField.PlanToCloseAt, new Date(value), form);
        }}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
      />
      <Space size={2} />
      <FormControl variant="outlined" className={styles.formControl}>
        <InputLabel id="flight-form-terminal-label">
          {label.MaxQueueSize}
        </InputLabel>
        <Select
          labelId="flight-form-terminal-label"
          id="flight-form-terminal"
          value={form.MaxQueueSize}
          label={label.MaxQueueSize}
          onChange={({ target: { value } }) => {
            updateField(FlightFormField.MaxQueueSize, value as number, form);
          }}
        >
          <MenuItem value={99}>99</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={25}>25</MenuItem>
        </Select>
        <FormHelperText>{helptext.MaxQueueSize}</FormHelperText>
      </FormControl>
      <Space size={2} />
      <FormControl variant="outlined" className={styles.formControl}>
        <InputLabel id="flight-form-terminal-label">
          {label.MaxAllowed}
        </InputLabel>
        <Select
          labelId="flight-form-terminal-label"
          id="flight-form-terminal"
          value={form.MaxAllowed}
          label={label.MaxAllowed}
          onChange={({ target: { value } }) => {
            updateField(FlightFormField.MaxAllowed, value as number, form);
          }}
        >
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          {/* <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={6}>6</MenuItem>
          <MenuItem value={7}>7</MenuItem> */}
        </Select>
        <FormHelperText>{helptext.MaxAllowed}</FormHelperText>
      </FormControl>
      <Space size={2} />
      <FormControl variant="outlined" className={styles.formControl}>
        <InputLabel id="flight-form-terminal-label">
          {label.MaxStayTime}
        </InputLabel>
        <Select
          labelId="flight-form-terminal-label"
          id="flight-form-terminal"
          value={form.MaxStayTime}
          label={label.MaxStayTime}
          onChange={({ target: { value } }) => {
            updateField(FlightFormField.MaxStayTime, value as number, form);
          }}
        >
          <MenuItem value={0}>Unlimited</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={15}>15</MenuItem>
          <MenuItem value={20}>20</MenuItem>
        </Select>
        <FormHelperText>{helptext.MaxStayTime}</FormHelperText>
      </FormControl>
      <Space size={4} />
      <SectionTitle
        title={t("advancedSettingsSectionTitleLabel")}
        variant="subtitle1"
        underscoreColor="#98d2e3"
      />
      <Space size={1} />
      <Typography
        variant="caption"
        style={{ color: colors["text-hint-color"] }}
      >
        {t("advancedSettingsSectionDescLabel")!}
      </Typography>
      <Space size={4} />
      <FormControl variant="outlined" className={styles.formControl}>
        <InputLabel id="flight-form-allowed-passenger-type-label">
          {label.FlightPassengerType}
        </InputLabel>
        <Select
          labelId="flight-form-allowed-passenger-type-label"
          id="flight-form-allowed-passenger-type"
          value={form.FlightPassengerType}
          label={label.FlightPassengerType}
          disabled={!profileVerified}
          onChange={({ target: { value } }) => {
            updateField(
              FlightFormField.FlightPassengerType,
              value as FlightPassengerType,
              form
            );
          }}
        >
          <MenuItem value={FlightPassengerType.ALL}>All</MenuItem>
          <MenuItem value={FlightPassengerType.TRUSTED}>Trusted</MenuItem>
        </Select>
        <FormHelperText>{helptext.FlightPassengerType}</FormHelperText>
      </FormControl>
      <Space size={2} />
      <FormControl variant="outlined" className={styles.formControl}>
        <InputLabel id="flight-form-require-password-label">
          {label.RequirePassword}{" "}
        </InputLabel>
        <Select
          labelId="flight-form-require-password-label"
          id="flight-form-require-password"
          value={form.RequirePassword ? 1 : 0}
          label={label.RequirePassword}
          disabled={!profileVerified}
          onChange={({ target: { value } }) => {
            updateField(FlightFormField.RequirePassword, !!value, form);
          }}
        >
          <MenuItem value={0}>No</MenuItem>
          <MenuItem value={1}>Yes</MenuItem>
        </Select>
        <FormHelperText>{helptext.RequirePassword}</FormHelperText>
      </FormControl>
      <Space size={4} />
      <SectionTitle
        title={t("dodoCodeSectionTitleLabel")}
        variant="subtitle1"
        underscoreColor="#98d2e3"
      />
      <Space size={4} />
      <TextField
        id="dodo-code"
        variant="outlined"
        label={label.Code}
        helperText={helptext.Code}
        error={!!error.Code}
        InputLabelProps={{
          shrink: true,
        }}
        placeholder={placeholder.Code}
        value={form.Code}
        onChange={({ target: { value } }) => {
          updateField(FlightFormField.Code, value as string, form);
        }}
      />
      <Space size={4} />
      {isFlightCreated && (
        <>
          <Row center>
            <Link href={`/island/${user!.uid}`} target="_blank">
              View my island
            </Link>
          </Row>
          <Space size={2} />
        </>
      )}
      <Row center>
        <BubbleButton
          title={t(
            submitting
              ? "submit.saving"
              : isFlightCreated
              ? "submit.update"
              : "submit.create"
          )}
          color="bg-color-blue-500"
          disabled={submitDisabled}
          onPress={submit}
        />
      </Row>
      {isFlightCreated && (
        <>
          <Space size={2} />
          <Row center>
            <BubbleButton
              title={t(flightClosing ? "submit.closing" : "submit.close")}
              color="color-danger-500"
              disabled={flightClosing}
              onPress={() => setIsFlightCloseConfirmModalVisible(true)}
              showArrow={false}
            />
          </Row>
          <Dialog
            open={isFlightCloseConfirmModalVisible}
            onClick={() => setIsFlightCloseConfirmModalVisible(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("endFlightDialog.title")!}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("endFlightDialog.desc")!}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={flightClosing}
                color="secondary"
                variant="contained"
                onClick={flightClose}
                autoFocus
              >
                {t("endFlightDialog.confirm")!}
              </Button>
              <Button
                color="primary"
                onClick={() => setIsFlightCloseConfirmModalVisible(false)}
              >
                {t("endFlightDialog.cancel")!}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      <InProgressModal visible={submitting || flightClosing} />
      <Space size={4} />
      <TextButton
        title={t("footer.readRules")!}
        icon="fas fa-hand-point-up"
        status="info"
        size="small"
        onPress={() => {
          // TODO
          // navigation.navigate("Article", {
          //   id: HELP_ARTICLE.CommunityStandard,
          // });
        }}
      />
      <Space size={1} />
      <Typography
        variant="caption"
        style={{ color: colors["text-hint-color"] }}
      >
        * {t("footer.note1")!}
      </Typography>
      <Space size={1} />
      <Typography
        variant="caption"
        style={{ color: colors["text-hint-color"] }}
      >
        * {t("footer.note2")!}
      </Typography>
    </Box>
  );
};
