import { SpecialCharacterWithStatus } from "contract/data-collection/flight";
import { SpecialCharacter } from "contract/common";
import { SPECIAL_CHARACTERS } from "common/constants";

export interface SpecialVisitorDetailed {
  id: string;
  name: string;
  source: string;
  statusDesc?: string;
  statusImageSource?: string;
}

export function getSpecialVisitorStatusDesc(
  t: any,
  specialVisitor: SpecialCharacterWithStatus
) {
  switch (specialVisitor.id) {
    case SpecialCharacter.CELESTE:
      return specialVisitor.hasMeteorShower
        ? t("characterStatus.meteorShower", { ns: "shared" })
        : undefined;
    default:
      return undefined;
  }
}

export function getSpecialVisitorStatusImageSource(
  specialVisitor: SpecialCharacterWithStatus
) {
  switch (specialVisitor.id) {
    case SpecialCharacter.CELESTE:
      return specialVisitor.hasMeteorShower
        ? require("assets/item/star_fragment.png")
        : undefined;
    default:
      return undefined;
  }
}

export function getSpecialVisitorDetails(
  t: any,
  specialVisitor: SpecialCharacterWithStatus
): SpecialVisitorDetailed | undefined {
  const specialCharacter = SPECIAL_CHARACTERS[specialVisitor.id];

  if (!specialCharacter) return undefined;

  return {
    id: specialVisitor.id,
    name: t(specialCharacter.displayName, { ns: "shared" }),
    source: specialCharacter.image,
    statusDesc: getSpecialVisitorStatusDesc(t, specialVisitor),
    statusImageSource: getSpecialVisitorStatusImageSource(specialVisitor),
  };
}
