import { Box, Typography } from "@material-ui/core";
import React from "react";
// import { getItemById } from "utils/getItemById";

export interface PriceTagItemProps {
  id: string;
  count: number;
  desc?: string;
  size?: number;
}

export const PriceTagItem: React.FC<PriceTagItemProps> = ({
  id,
  count,
  desc,
  size = 14,
}) => {
  // const item = React.useMemo(() => getItemById(id), [id]);

  // if (!item || !item.source) return null;

  const item = {
    source: require("assets/item/bell_bag.png"),
  };

  const finalDesc = desc
    ? desc
    : id === "MwHcZBfqcJ5vJzRqZ"
    ? "99k"
    : undefined;

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "0 8px",
      }}
    >
      <img
        src={item.source}
        style={{ width: size * 1.3, height: size * 1.3 }}
        alt={desc || "item"}
      />
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {!!finalDesc && (
          <Typography
            style={{
              fontSize: size * 0.8,
              fontWeight: 600,
            }}
          >
            {" "}
            {finalDesc}{" "}
          </Typography>
        )}
        <Typography style={{ fontSize: size * 0.8, padding: "0 4px" }}>
          {" "}
          x{" "}
        </Typography>
        <Typography
          style={{
            fontSize: size,
            fontWeight: 600,
          }}
        >
          {String(count)}
        </Typography>
      </Box>
    </Box>
  );
};
