import { Space } from "components/Space";
import { Post } from "contract/data-collection/post";
import { useUserProfile } from "hooks/useUserProfile";
import React from "react";
import PostItemContent from "./PostItemContent";
import PostItemReply from "./PostItemReply";
import usePostMetadata from "hooks/usePostMetadata";
import usePostReply from "hooks/usePostReply";
import usePostReplyMetadata from "hooks/usePostReplyMetadata";
import { Paper, PaperProps } from "@material-ui/core";

interface PostItemReplyWithReplyIdProps {
  postId: string;
  postReplyId: string;
}

const PostItemReplyWithReplyId: React.FC<PostItemReplyWithReplyIdProps> = ({
  postId,
  postReplyId,
}) => {
  const reply = usePostReply(postId, postReplyId);
  const replyMetadata = usePostReplyMetadata(postId, postReplyId);

  if (!reply) return null;

  return (
    <PostItemReply
      wrapTextWithLayout
      reply={reply}
      replyMetadata={replyMetadata}
    />
  );
};

export interface PostItemProps {
  post: Post;
  style?: PaperProps["style"];
}

const PostItem: React.FC<PostItemProps> = ({ post, style }) => {
  const authorProfile = useUserProfile(post.uid);

  const postMetadata = usePostMetadata(post.id);

  return (
    <Paper
      elevation={0}
      style={{
        padding: 24,
        display: "flex",
        flexDirection: "column",
        ...style,
      }}
    >
      <PostItemContent post={post} postMetadata={postMetadata} />
      <Space size={1} />
      {postMetadata?.featuredReplies &&
        postMetadata?.featuredReplies.length > 0 && (
          <>
            <Space size={1} />
            {postMetadata.featuredReplies.map((postReplyId) => (
              <PostItemReplyWithReplyId
                key={postReplyId}
                postId={post.id}
                postReplyId={postReplyId}
              />
            ))}
          </>
        )}
    </Paper>
  );
};

export default PostItem;
