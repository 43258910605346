import { Typography, Box, Icon, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = () =>
  makeStyles((theme) => ({
    container: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: "white",
      borderRadius: 8,
      padding: "12px 16px",
      cursor: "pointer",
      "&:active": {
        opacity: 0.8,
      },
    },
  }));

export interface ActionButtonItemProps {
  onPress?: () => void;
  title: string;
  eyebrow: string;
  loading: boolean;
}

export const ActionButtonItem: React.FC<ActionButtonItemProps> = ({
  onPress,
  title,
  eyebrow,
  loading,
}) => {
  const styles = useStyles()();

  return (
    <Box onClick={onPress} className={styles.container}>
      <Box
        style={{
          flex: 1,
        }}
      >
        {loading ? (
          <Typography variant="caption">Loading...</Typography>
        ) : (
          <React.Fragment>
            <Typography variant="caption" style={{ fontSize: 10 }}>
              {eyebrow}
            </Typography>
            <Typography style={{ color: "#6B5C43" }}>{title}</Typography>
          </React.Fragment>
        )}
      </Box>
      <Icon className="fas fa-chevron-right" style={{ color: "#6B5C43" }} />
    </Box>
  );
};
