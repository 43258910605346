import React from "react";
import { Ref } from "api/ref";
import { ReportRecord } from "contract/data-collection/report";
import { pick } from "ramda";
import { createSafeDate } from "utils/createSafeDate";

export interface ReportRecordWithId extends Omit<ReportRecord, "at"> {
  id: string;
  at: Date;
}

export function mapSnapshotToReportRecord(
  snapshot: firebase.database.DataSnapshot
): ReportRecordWithId[] {
  const val = snapshot.val();

  if (!val) {
    return [];
  }

  return Object.keys(val).map<ReportRecordWithId>((key) => {
    const data = val[key];
    const picked = pick([
      "reporteeUid",
      "reporterUid",
      "subject",
      "subjectMetadata",
      "reason",
      "reasonDescription",
    ])(data);
    return {
      ...picked,
      at: createSafeDate(data.at),
      id: key,
    };
  });
}

export function useReports(reporteeUid: string | undefined) {
  const [state, setState] = React.useState<ReportRecordWithId[] | undefined>(
    undefined
  );

  React.useEffect(() => {
    if (!reporteeUid) {
      setState(undefined);
      return;
    }

    const ref = Ref.reports(reporteeUid);
    const callback = ref.on(
      "value",
      (snapshot: firebase.database.DataSnapshot) => {
        setState(mapSnapshotToReportRecord(snapshot));
      }
    );

    return () => {
      ref.off("value", callback);
    };
  }, []);

  return state;
}
