import React from "react";
import { Helmet } from "react-helmet";
import { Announcement } from "components/Announcement";
import { Breadcrumbs, Link, makeStyles } from "@material-ui/core";
import { Space } from "components/Space";
import { SignInForm } from "./components/Form/Form";

const useStyles = makeStyles({
  container: {},
  intro: {
    padding: 24,
    position: "relative",
    "&::before": {
      borderRadius: 8,
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundImage:
        "url(https://www.animal-crossing.com/new-horizons/assets/img/global/patterns/pattern-dots-2x.png)",
      backgroundColor: "#7b6c53",
      backgroundSize: "10px 10px",
    },
  },
});

export const SignInPage: React.FC = () => {
  const styles = useStyles();

  return (
    <>
      <Helmet>
        <title>Sign In - Link - The ACNH Community</title>
        <meta
          name="apple-itunes-app"
          content="app-id=3Y3ND925XU, app-argument=https://acnh.link/"
        />
      </Helmet>
      <Announcement />
      <Space size={2} />
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          style={{ fontSize: "0.8em" }}
          color="inherit"
          href="/airport"
          onClick={() => {}}
        >
          Airport
        </Link>
        <Link
          style={{ fontSize: "0.8em" }}
          color="textPrimary"
          href="/components/breadcrumbs/"
          onClick={() => {}}
          aria-current="page"
        >
          Sign In
        </Link>
      </Breadcrumbs>
      <Space size={2} />
      {/* <div className={styles.intro}>
        <Box style={{ position: "relative" }}>
          <Typography variant="h5" style={{ textAlign: "center" }}>
            Sign In / Sign Up
          </Typography>
        </Box>
      </div>
      <Space size={2} /> */}
      <SignInForm />
    </>
  );
};
