import { ResidentWithStatus } from "./flight";
import { Fee } from "../common";

export enum PostType {
  Content = "Content",
  Resident = "Resident",
  Offer = "Offer",
  Request = "Request",
}

export interface ItemOffer {
  id: string;
  count: number;
  price?: Fee[];
}

export interface ItemRequest {
  id: string;
  count: number;
  price?: Fee[];
}

export interface Post {
  id: string;
  createdAt: Date;
  uid: string;
  type: PostType;
  text: string;
  images: string[];
  resident?: ResidentWithStatus;
  itemOffer?: ItemOffer;
  itemRequest?: ItemRequest;
  interactionFinished?: boolean; // resident taken/offer ended/request fullfiled/not wanting to interact anymore
}

export interface PostMetadata {
  likeCount: number;
  replyCount: number;
  featuredReplies: string[];
}

export interface PostReplyMetadata {
  likeCount: number;
}

export interface PostReply {
  id: string;
  createdAt: Date;
  postId: string;
  replyTo?: string;
  uid: string;
  text: string;
}

export enum PostDealRating {
  Happy,
  Unhappy,
}

export interface PostDeal {
  id: string; // use visitorUid as id to ensure uniqueness
  authorConfirmedDealAt?: Date;
  visitorConfirmedDealAt?: Date;

  lastUpdatedAt: Date;
  lastMessageId: string;
}

export enum PostDealMessageContentType {
  String,
  JSON,
}

export enum PostDealMessageSystemContentType {
  AuthorConfirmedDeal = "AUTHOR_CONFIRMED_DEAL",
  VisitorConfirmedDeal = "VISITOR_CONFIRMD_DEAL",
  AuthorReview = "AUTHOR_REVIEW",
  VisitorReview = "VISITOR_REVIEW",
}

export interface PostDealMessage {
  id: string;
  createdAt: Date;
  postId: string;
  postDealId: string;
  uid: string; // "SYSTEM" reserved uid for system notifications
  contentType: PostDealMessageContentType;
  text: string;
  read: boolean;
}

// Only two PostDealReview can exists for a PostDeal, from either the author or the visitor
export interface PostDealReview {
  // uid of the user who published this review: author uid or visitor uid
  id: string;
  postId: string;
  postDealId: string;
  // redundant data for collection group query
  postAuthorUid: string;
  reviewAuthorUid: string;
  text: string;
  rating: PostDealRating;
  createdAt: Date;
}
