import { Box, FormControlLabel, Checkbox, Typography } from "@material-ui/core";
import { Terminal } from "contract/common";
import { useFlightList } from "hooks/useFlightList";
import React from "react";
import { SectionTitle } from "components/SectionTitle";
import { Space } from "components/Space";
import { useCheckboxState } from "hooks/useCheckboxState";
import { TerminalGeneralCell } from "./TerminalGeneralCell";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "utils/useMediaQuery";
import { filterGeneralFlights } from "./filterGeneralFlights";

export const TerminalGeneral = () => {
  const { t } = useTranslation("AirportTerminalGeneralScreen");
  const mediaQuery = useMediaQuery();

  const flights = useFlightList(Terminal.GENERAL);
  const flightPerRow = mediaQuery.isMobile ? 1 : mediaQuery.isTablet ? 2 : 3;
  const flightFillers =
    flights && flights.length % flightPerRow !== 0
      ? new Array(flights.length % flightPerRow)
      : [];

  const villagerCheckbox = useCheckboxState(false);
  const specialVisitorCheckbox = useCheckboxState(false);
  const catalogCheckbox = useCheckboxState(false);
  const wishlistCheckbox = useCheckboxState(false);

  const filteredFlight = React.useMemo(
    () =>
      filterGeneralFlights(flights || [], {
        hasVillager: villagerCheckbox.checked,
        hasSpecialVisitor: specialVisitorCheckbox.checked,
        hasCatalog: catalogCheckbox.checked,
        hasWishlist: wishlistCheckbox.checked,
      }),
    [
      flights,
      villagerCheckbox.checked,
      specialVisitorCheckbox.checked,
      catalogCheckbox.checked,
      wishlistCheckbox.checked,
    ]
  );

  return (
    <Box
      style={{
        backgroundColor: "rgba(130, 215, 170, 0.2)",
        borderRadius: 8,
        padding: 16,
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="subtitle1"
          style={{ fontWeight: "bold", color: "#6B5C43" }}
        >
          GENERAL
        </Typography>
      </Box>
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box
          style={{
            height: 42,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SectionTitle
            title="Filters:"
            variant="subtitle2"
            containerStyle={{ alignSelf: undefined }}
          />
        </Box>
        <Space horizontal size={2} />
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={villagerCheckbox.checked}
                onChange={villagerCheckbox.onChange}
                name={t("villagerLabel")!}
              />
            }
            label={t("villagerLabel")!}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={specialVisitorCheckbox.checked}
                onChange={specialVisitorCheckbox.onChange}
                name={t("specialVisitorLabel")!}
              />
            }
            label={t("specialVisitorLabel")!}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={catalogCheckbox.checked}
                onChange={catalogCheckbox.onChange}
                name={t("catalogLabel")!}
              />
            }
            label={t("catalogLabel")!}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={wishlistCheckbox.checked}
                onChange={wishlistCheckbox.onChange}
                name={t("wishlistLabel")!}
              />
            }
            label={t("wishlistLabel")!}
          />
        </Box>
      </Box>
      <Space size={2} />
      {filteredFlight?.length === 0 && (
        <Box
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "16px 0",
          }}
        >
          <Typography variant="caption" style={{ color: "#8F9BB3" }}>
            {t("noFlightLabel")!}
          </Typography>
        </Box>
      )}
      {filteredFlight && filteredFlight.length > 0 && (
        <Box
          style={{
            display: "flex",
            flexDirection: mediaQuery.isMobile ? "column" : "row",
            justifyContent: "space-between",
            flexWrap: mediaQuery.isMobile ? undefined : "wrap",
            maxHeight: 560,
            overflowY: "auto",
          }}
        >
          {filteredFlight.map((flight) => (
            <TerminalGeneralCell
              key={flight.flightId}
              flight={flight}
              onPress={() => {
                window.open(`/island/${flight.flightCreatorId}`, "_blank");
              }}
              filters={{
                hasCatalog: catalogCheckbox.checked,
                hasWishlist: wishlistCheckbox.checked,
              }}
            />
          ))}
          {flightFillers.fill(undefined).map((_, index) => (
            <Box
              key={index}
              style={{
                marginBottom: 8,
                padding: 16,
                flex: mediaQuery.isTablet ? "0 0 48%" : "0 0 32%",
                boxSizing: "border-box",
              }}
            ></Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
