import { ReportRecordWithId } from "./useReports";
import { ReportSubject } from "contract/common";

export function hasReported(
  uid: string | undefined,
  subject: ReportSubject | undefined,
  reports: ReportRecordWithId[] | undefined
) {
  if (reports === undefined) return false;
  return reports.find((r) => r.reporterUid === uid && r.subject === subject);
}
