import React from "react";
import { Space } from "components/Space";
import { ChatMessage } from "../ChatMessage/ChatMessage";
import { RoundedBackgroundLabel } from "components/RoundedBackgroundTitle";
import { useTranslation } from "react-i18next";

import { FlightPublic } from "contract/view-collection/flight";
import { ProfileContext } from "modules/auth/ProfileContext";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Icon,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import { colors } from "common/colors";
import { useFlightChat } from "hooks/useFlightChat";
import { ChatApi } from "api/chat";
import { useSnackbar } from "notistack";
import { useEULAConsent } from "hooks/useEULAConsent";
import Row from "components/Row";
import { useInputState } from "hooks/useInputState";
import { openHelpArticle } from "utils/openHelpArticle";
import { HELP_ARTICLE } from "common/constants";
import { OpenReportModal } from "../Report/useReportModal";

export interface ChatSectionProps {
  disabled?: boolean;
  flight: FlightPublic;
  onPressReport: OpenReportModal;
}

export const ChatSection: React.FC<ChatSectionProps> = ({
  disabled = true,
  flight,
  onPressReport,
}) => {
  const { t } = useTranslation("ChatSection");
  const { enqueueSnackbar } = useSnackbar();

  const chatInputState = useInputState();
  const profile = React.useContext(ProfileContext);

  const chats = useFlightChat(flight.flightCreatorId);

  const scrollViewRef = React.createRef<HTMLDivElement>();
  React.useEffect(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTop = scrollViewRef.current.scrollHeight;
    }
  }, [chats]);

  const [loading, setLoading] = React.useState(false);

  const profileVerified = !!profile?.verificationLevel;
  const chatingSpeedRef = React.useRef({
    count: 0,
    from: Date.now(),
  });

  const send = React.useCallback(() => {
    const gap = Date.now() - chatingSpeedRef.current.from;
    if (!profileVerified && gap < 30000 && chatingSpeedRef.current.count >= 1) {
      enqueueSnackbar(t("error.tooFrequent"), {
        variant: "error",
      });
      return;
    }

    if (gap > 30000) {
      chatingSpeedRef.current.count = 1;
    } else {
      chatingSpeedRef.current.count += 1;
    }

    chatingSpeedRef.current.from = Date.now();

    setLoading(true);
    ChatApi.send(flight.flightCreatorId, chatInputState.value)
      .then(() => {
        setLoading(false);
        chatInputState.reset();
      })
      .catch((e) => {
        enqueueSnackbar(t("error.general", { ns: "shared", msg: e.message }), {
          variant: "error",
        });
        setLoading(false);
      });
  }, [
    flight.flightCreatorId,
    chatInputState.value,
    chatInputState.onChange,
    profileVerified,
    enqueueSnackbar,
    t,
    chatInputState,
  ]);

  const eulaConsent = useEULAConsent();

  return (
    <Box>
      <RoundedBackgroundLabel
        title={t("chatLabel")}
        accessoryRight={true}
        accessoryRightOnPress={() => {
          openHelpArticle(HELP_ARTICLE.FlightChat);
        }}
      />
      <Space size={2} />
      <Box
        {...{ ref: scrollViewRef }}
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "16px 0",
          backgroundColor: colors["color-basic-200"],
          borderRadius: 8,
          height: 520,
          overflowY: "auto",
        }}
      >
        {chats?.map((message) => (
          <ChatMessage
            key={message.id}
            flightCreatorId={flight.flightCreatorId}
            flightId={flight.flightId}
            message={message}
            onPressReport={onPressReport}
          />
        ))}
        {(!chats || chats.length === 0) && (
          <Typography variant="caption" style={{ textAlign: "center" }}>
            {t("noChat")!}
          </Typography>
        )}
        <Space size={4} />
      </Box>
      <Space size={1} />
      <Row>
        <FormControlLabel
          control={
            <Checkbox
              key={String(eulaConsent.consented)}
              defaultChecked={eulaConsent.consented}
              checked={eulaConsent.consented}
              onChange={eulaConsent.onChange}
              disabled={eulaConsent.loading}
              name="eula-consent"
            />
          }
          label={
            <Typography variant="caption">
              {t("iHaveReadAndAgreed")!}{" "}
              <Link
                href="/news/eula"
                target="_blank"
                style={{
                  fontWeight: "bold",
                }}
              >
                {t("EULA")!}
              </Link>
              .
            </Typography>
          }
          disabled={disabled || eulaConsent.loading}
        />
      </Row>
      <Space size={2} />
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <TextField
          {...chatInputState}
          placeholder={
            eulaConsent.consented
              ? t("chatInputPlaceholder")
              : t("requireEULAConsent")
          }
          variant="outlined"
          multiline={true}
          rowsMax={3}
          style={{ flex: 1, maxHeight: 45 }}
          disabled={disabled || loading || !eulaConsent.consented}
          InputProps={{
            style: { padding: "12px 0 12px 16px" },
            endAdornment: (
              <Button
                size="small"
                startIcon={
                  <Icon
                    className="fas fa-paper-plane"
                    style={{ color: colors["txt-color-brown-500"] }}
                  ></Icon>
                }
                onClick={send}
                disabled={disabled || loading || !eulaConsent.consented}
              ></Button>
            ),
          }}
        />
      </Box>
    </Box>
  );
};
