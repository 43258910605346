import {
  Paper,
  Typography,
  Avatar,
  Icon,
  Divider,
  Box,
} from "@material-ui/core";
import { Chip } from "components/Chip";
import { DisplayName } from "components/DisplayName";
import PriceTag from "components/PriceTag";
import { Space } from "components/Space";
import TouchableImageListContainer from "components/TouchableImageList/List";
import SpecialCharacterItem from "components/TouchableImageList/SpecialVisitorItem";
import VillagerItem from "components/TouchableImageList/VillagerItem";
import {
  CatalogAndWishlistItemType,
  FlightPassengerType,
} from "contract/common";
import { FlightPublic } from "contract/view-collection/flight";
import { useUserProfile } from "hooks/useUserProfile";
import React from "react";
import { useTranslation } from "react-i18next";
import { filterCatalogAndWishlist } from "utils/filterCatalogAndWishlist";
import { getFruitImage } from "utils/getFruitImage";
import { useMediaQuery } from "utils/useMediaQuery";

export function isEmpty(list: any[] | undefined) {
  return !list || list.length === 0;
}
export interface TerminalGeneralCellProps {
  flight: FlightPublic;
  onPress: () => void;
  filters: { hasCatalog: boolean; hasWishlist: boolean };
}

export const TerminalGeneralCell: React.FC<TerminalGeneralCellProps> = ({
  flight,
  onPress,
  filters,
}) => {
  const { t } = useTranslation("FlightListCellGeneral");
  const mediaQuery = useMediaQuery();

  const profile = useUserProfile(flight.flightCreatorId);
  // TODO
  // const [messageModalVisible, setMessageModalVisible] = React.useState(false);
  // const [
  //   catalogAndWishlistModalVisible,
  //   setCatalogAndWishlistModalVisible,
  // ] = React.useState(false);

  const catalog = filterCatalogAndWishlist(
    profile?.catalogAndWishlist || [],
    CatalogAndWishlistItemType.CATALOG
  );
  const wishlist = filterCatalogAndWishlist(
    profile?.catalogAndWishlist || [],
    CatalogAndWishlistItemType.WISHLIST
  );

  if (filters.hasCatalog && isEmpty(catalog)) return null;
  if (filters.hasWishlist && isEmpty(wishlist)) return null;

  return (
    <Paper
      style={{
        marginBottom: 8,
        padding: 16,
        flex: mediaQuery.isMobile
          ? 1
          : mediaQuery.isTablet
          ? "0 0 48%"
          : "0 0 32%",
        boxSizing: mediaQuery.isMobile ? undefined : "border-box",
        cursor: "pointer",
        pointerEvents: "auto",
      }}
      onClick={onPress}
      elevation={0}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        {flight.allowedPassengerType === FlightPassengerType.TRUSTED && (
          <Chip
            size={10}
            icon="fas fa-user-shield"
            title={t("verifiedOnlyLabel")}
            style={{ marginRight: 8 }}
          />
        )}
        {flight.requirePassword && (
          <Chip
            size={10}
            icon="fas fa-key"
            title=""
            style={{ marginRight: 8 }}
          />
        )}
        {flight.queueLocked && (
          <Chip
            size={10}
            icon="fas fa-lock"
            title=""
            style={{ marginRight: 8 }}
          />
        )}
        <Chip
          size={10}
          icon="fas fa-user"
          title={({ color, size }) => (
            <>
              <Typography
                style={{
                  color,
                  fontSize: 12,
                  fontWeight: 600,
                }}
              >
                {flight.queueSize || 0}
              </Typography>
              {!!flight.maxQueueSize && (
                <Typography
                  style={{
                    color,
                    fontSize: size,
                  }}
                >
                  {" "}
                  / {flight.maxQueueSize}
                </Typography>
              )}
            </>
          )}
        ></Chip>
      </Box>
      <Space size={0.5} />
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Avatar
          src={profile?.photoURL || require("assets/reaction/Confident.png")}
          style={{
            marginRight: 8,
          }}
        />
        <Box style={{ flex: 1 }}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: 4,
            }}
          >
            <DisplayName profile={profile} category="h6" />
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Icon
              className="fas fa-map-marker-alt"
              style={{
                fontSize: 10,
                marginRight: 4,
                color: "#8F8F4C",
              }}
            />
            <Typography
              variant="caption"
              style={{ fontSize: 10, color: "#8F8F4C" }}
            >
              {profile?.islandName ||
                t("unknown.islandName", { ns: "shared" })!}
            </Typography>
            {!!profile?.fruit && (
              <Box
                style={{
                  marginLeft: 16,
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <img
                  src={getFruitImage(profile.fruit)}
                  style={{ marginRight: 2, width: 16, height: 16 }}
                />
                <Typography
                  variant="caption"
                  style={{ fontSize: 10, color: "#8F8F4C" }}
                >
                  {t(`fruit.${profile.fruit}`, { ns: "shared" })!}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Space size={2} />
      <Divider />
      <Space size={1} />
      <Box style={{ padding: 8 }}>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="caption"
            align="center"
            style={{ color: "#8F8F4C", fontWeight: 600 }}
          >
            {t("messageLabel")!}
          </Typography>
        </Box>
        <Space size={1} />
        <Typography
          variant="body2"
          style={{
            maxHeight: 100,
            overflow: "auto",
            whiteSpace: "break-spaces",
          }}
        >
          {flight.message}
        </Typography>
      </Box>
      {(!isEmpty(flight.residents) || !isEmpty(flight.specialVisitors)) && (
        <React.Fragment>
          <Divider />
          <Space size={1} />
          <TouchableImageListContainer
            fillerSize={32}
            containerStyle={{ padding: 0 }}
          >
            {flight.residents?.map((resident) => (
              <VillagerItem
                key={resident.id}
                size={32}
                id={resident.id}
                status={resident.status}
              />
            ))}
            {flight.specialVisitors?.map((specialVisitor) => (
              <SpecialCharacterItem
                key={specialVisitor.id}
                size={32}
                specialVisitor={specialVisitor}
              />
            ))}
          </TouchableImageListContainer>
          <Space size={1} />
        </React.Fragment>
      )}
      <Divider />
      <Space size={1} />
      {flight.entryFees ? (
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-end",
          }}
        >
          <Typography
            variant="caption"
            style={{ marginRight: 4, color: "#8F8F4C" }}
          >
            {t("entryFeeLabel")!}
          </Typography>
          <PriceTag.List
            style={{
              padding: 0,
              backgroundColor: "transparent",
            }}
          >
            {flight.entryFees?.map((entryFee) => (
              <PriceTag.Item id={entryFee.id} count={entryFee.count} />
            ))}
          </PriceTag.List>
        </Box>
      ) : (
        <Box>
          <Typography
            variant="caption"
            style={{ display: "block", textAlign: "center", color: "#8F8F4C" }}
          >
            {t("noEntryFeeLabel")!}
          </Typography>
        </Box>
      )}
    </Paper>
  );
};
