import React from "react";
import {
  Box,
  Divider,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Link,
  useTheme,
  Typography,
  ListSubheader,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../../assets/logo.png";
import { Space } from "../Space";
import { NavLink } from "./NavLink";
import { useLocation } from "react-router-dom";
import { useIsMobile } from "hooks/useIsMobile";
import { AuthContext } from "modules/auth/AuthContext";
import { useSignOut } from "modules/auth/signOut";
import { useMenu } from "hooks/useMenu";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
    padding: "60px 0",
    position: "relative",
    boxSizing: "border-box",
  },
  logo: {
    width: 32,
    height: 32,
    borderRadius: 8,
  },
});

export const NavBar: React.FC = () => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const classes = useStyles();
  const { pathname } = useLocation();
  const user = React.useContext(AuthContext);

  const signOut = useSignOut();

  const menu = useMenu();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection="row"
      py={4}
    >
      <Box
        component="a"
        // @ts-ignore
        href="/"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        style={{ textDecoration: "none" }}
      >
        <img
          src={Logo}
          className={classes.logo}
          alt="Link logo"
          style={{ flexShrink: 0 }}
        ></img>
        <Space horizontal size={1} />
        <Box fontWeight="bold" color={theme.palette.text.primary}>
          {" "}
          {isMobile ? "ACNH Link" : "Link - The ACNH Community"}{" "}
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        height={40}
      >
        <NavLink title="Home" link="/" active={pathname === "/"} />
        <NavLink
          title="Airport"
          link="/airport"
          active={
            pathname.startsWith("/airport") ||
            (pathname.startsWith("/island") && "semi")
          }
        />

        {user === null ? (
          <>
            <Space horizontal size={1} />
            <Box
              style={{
                width: 2,
                height: 8,
                backgroundColor: theme.palette.text.primary,
              }}
            ></Box>
            <Space horizontal size={1} />
            <NavLink
              title="Sign In"
              link="/sign-in"
              active={pathname === "/sign-in"}
            />
          </>
        ) : (
          <>
            <Space horizontal size={1} />
            <IconButton
              aria-label="menu"
              onClick={menu.handleClick}
              disabled={user === undefined}
            >
              <Icon className="fas fa-bars" style={{ fontSize: 18 }} />
            </IconButton>
          </>
        )}
      </Box>

      <Menu
        anchorEl={menu.anchorEl}
        keepMounted
        open={Boolean(menu.anchorEl)}
        onClose={menu.close}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => {
            window.open("/passport", "_blank");
          }}
        >
          <Typography variant="subtitle2">Passport</Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={signOut}>
          {" "}
          <Typography variant="subtitle2">Logout</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};
