import { Icon, Typography } from "@material-ui/core";
import { colors } from "common/colors";
import React from "react";
import { Space } from "./Space";
import TouchableOpacity, { TouchableOpacityProps } from "./TouchableOpacity";

export interface TextButtonProps extends TouchableOpacityProps {
  status: "primary" | "info";
  size: "small" | "tiny";
  title: string;
  icon?: string;
}

const TextButton: React.FC<TextButtonProps> = ({
  status,
  size,
  title,
  icon,
  style,
  ...props
}) => {
  const fontSize = size === "small" ? 12 : 11;
  const iconSize = size === "small" ? 12 : 11;
  const iconColor =
    status === "primary"
      ? colors["txt-color-brown-500"]
      : colors["txt-color-blue-500"];

  return (
    <TouchableOpacity
      style={{
        padding: "4px 16px",
        ...style,
      }}
      {...props}
    >
      {!!icon && (
        <>
          <Icon
            className={icon}
            style={{ color: iconColor, fontSize: iconSize }}
          />
          <Space size={1} horizontal />
        </>
      )}
      <Typography
        style={{ color: iconColor, fontSize: fontSize, fontWeight: 600 }}
      >
        {title}
      </Typography>
    </TouchableOpacity>
  );
};

export default TextButton;
