import { Ref } from "api/ref";
import { PostMetadata } from "contract/data-collection/post";
import React from "react";

export function getPostMetadata(
  data: firebase.firestore.DocumentSnapshot
): PostMetadata {
  return {
    likeCount: data.get("likeCount") || 0,
    replyCount: data.get("replyCount") || 0,
    featuredReplies: data.get("featuredReplies") || [],
  };
}

export default function usePostMetadata(postId: string) {
  const [state, setState] = React.useState<PostMetadata | null | undefined>();

  React.useEffect(() => {
    Ref.postMetadata(postId).onSnapshot((snapshot) => {
      if (!snapshot || !snapshot.exists) {
        setState(null);
        return;
      }

      setState(getPostMetadata(snapshot));
    });
  }, []);

  return state;
}
