import firebase from "../common/firebase";
import * as R from "ramda";
import { rejectNil } from "../utils/rejectNil";
import { Ref } from "./ref";

export class UserApi {
  static updateProfile(payload: any) {
    return firebase.functions().httpsCallable("profileUpdateGeneral")(payload);
  }

  // TODO: decide whether or not to change all profile related update to realtime
  static updateProfileRealtime(payload: any) {
    const user = firebase.auth().currentUser;
    if (!user) return new Promise((_, reject) => reject());

    const allowed = rejectNil(R.pick(["photoURL"])(payload));

    return firebase
      .firestore()
      .collection("users")
      .doc(user.uid)
      .update({
        ...allowed,
      });
  }

  static requestVerification() {
    const user = firebase.auth().currentUser;
    if (!user) return new Promise((_, reject) => reject());

    return Ref.verificationRequest(user.uid).set({
      at: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }

  static syncVerificationFromTurnipsExchange() {
    return firebase
      .functions()
      .httpsCallable("syncVerificationFromTurnipsExchange")();
  }

  static blockUser(uid: string) {
    const user = firebase.auth().currentUser;
    if (!user) return new Promise((_, reject) => reject());

    return Ref.userPrivate(user.uid).set(
      {
        blockList: firebase.firestore.FieldValue.arrayUnion(uid),
      },
      { merge: true }
    );
  }

  static unblockUser(uid: string) {
    const user = firebase.auth().currentUser;
    if (!user) return new Promise((_, reject) => reject());

    return Ref.userPrivate(user.uid).set(
      {
        blockList: firebase.firestore.FieldValue.arrayRemove(uid),
      },
      { merge: true }
    );
  }
}
