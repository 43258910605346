import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { Announcement } from "../components/Announcement";
import { useArticle } from "../hooks/useArticle";
import { makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import ReactMarkdown from "react-markdown";
import { Space } from "../components/Space";
import format from "date-fns/format";

const useStyles = makeStyles({
  container: {
    color: "#6b5c43",
    "& h1": {
      color: "#008378",
    },
    "& h2": {
      color: "#008378",
    },
    "& h3": {
      color: "#008378",
    },
    "& h4": {
      color: "#008378",
    },
    "& h5": {
      color: "#008378",
    },
    "& h6": {
      color: "#008378",
    },
    "& p": {
      color: "#6b5c43",
    },
    "& img": {
      maxWidth: "80%",
      maxHeight: 240,
      display: "block",
      margin: "32px auto",
    },
    "& a": {
      color: "#008378",
      fontWeight: "800",
      textDecoration: "none",
      fontStyle: "italic",
    },
    "& a:hover": {
      textDecoration: "underline",
    },
  },
  meta: {
    fontWeight: "bold",
    color: "#8f9bb3 !important",
  },
  image: {
    objectFit: "contain",
    height: "240px  !important",
    width: "auto !important",
    maxHeight: "unset !important",
    maxWidth: "100% !important",
    display: "block",
    margin: "0 auto !important",
  },
  link: {
    color: "#6B5C43",
    fontSize: "bold",
  },
});

export const NewsPage: React.FC = () => {
  const styles = useStyles();

  let { newsId } = useParams<{ newsId: string }>();

  const article = useArticle(newsId);

  if (article === undefined) {
    return (
      <>
        <Helmet>
          <title>Link - The ACNH Community</title>
          <meta
            name="apple-itunes-app"
            content="app-id=3Y3ND925XU, app-argument=https://acnh.link/"
          />
        </Helmet>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            padding: 32,
          }}
        >
          <Skeleton animation="wave" />
        </div>
      </>
    );
  }

  if (article === null) {
    return (
      <>
        <Helmet>
          <title>Link - The ACNH Community</title>
          <meta
            name="apple-itunes-app"
            content="app-id=3Y3ND925XU, app-argument=https://acnh.link/"
          />
        </Helmet>
        <div
          style={{
            flex: 1,
          }}
        >
          <Alert severity="warning">
            <AlertTitle>Not found!</AlertTitle>
            This news doesn't exist, return to{" "}
            <a className={styles.link} href="/">
              <strong>Home</strong>
            </a>
            .
          </Alert>
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{article.title} - Link - The ACNH Community</title>
        <meta
          name="apple-itunes-app"
          content={`app-id=3Y3ND925XU, app-argument=${`https://acnh.link/news/${newsId}`}`}
        />
      </Helmet>
      <Announcement />
      <div className={styles.container}>
        <h1>{article.title}</h1>
        <p className={styles.meta}>
          {article.author} · {format(article.publishedAt, "MMM dd, yyyy")}
        </p>
        {article.image && (
          <>
            <Space size={2} />
            <img
              src={article.image}
              alt={article.title}
              className={styles.image}
            />
          </>
        )}
        <Space size={2} />
        <ReactMarkdown source={article.content} />
      </div>
    </>
  );
};
