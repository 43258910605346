import React from "react";

export const useCheckboxState = (initialCheck = false) => {
  const [checked, setChecked] = React.useState(initialCheck);
  const onChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, newChecked: boolean) => {
      setChecked(newChecked);
    },
    []
  );
  return { checked, onChange };
};
