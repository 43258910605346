import { Box, Button, Paper, Typography } from "@material-ui/core";
import { PostApi } from "api/post";
import { colors } from "common/colors";
import PriceTag from "components/PriceTag";
import Row from "components/Row";
import { Space } from "components/Space";
import ItemItem from "components/TouchableImageList/ItemItem";
import { ItemRequest } from "contract/data-collection/post";
import useItem from "hooks/useItem";
import { AuthContext } from "modules/auth/AuthContext";
import React from "react";
import { useTranslation } from "react-i18next";
import InactiveOverlay from "./InactiveOverlay";

export interface PostItemRequestProps {
  request: ItemRequest;
  postId: string;
  active: boolean;
  shouldShowCTA?: boolean;
}

const PostItemRequest: React.FC<PostItemRequestProps> = ({
  postId,
  request,
  active,
  shouldShowCTA = true,
}) => {
  const { t } = useTranslation("PostItemRequest");
  const { id, count, price } = request;
  const item = useItem(id);
  const user = React.useContext(AuthContext);

  return (
    <Paper
      elevation={0}
      style={{
        borderRadius: 8,
        padding: 8,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="caption"
        style={{
          textAlign: "center",
        }}
      >
        {t("title")!}
      </Typography>
      <Space size={1} />
      <Row
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          backgroundColor: colors["background-basic-color-2"],
          borderRadius: 8,
          padding: 8,
        }}
      >
        <Box
          style={{
            width: 56,
            height: 56,
            borderRadius: 28,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: colors["bg-color-off-white-500"],
          }}
        >
          <ItemItem id={id} size={32} hideTitle />
        </Box>
        <Space horizontal size={2} />
        <Box style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <Typography variant="h6">
            {item?.nameWithVariation || "Unknown"}
          </Typography>
          <Row style={{ alignItems: "center" }}>
            <Typography variant="caption" style={{ marginRight: 4 }}>
              x
            </Typography>
            <Typography variant="subtitle1"> {count || 1}</Typography>
          </Row>
        </Box>
      </Row>

      {price && (
        <>
          <Space size={1} />
          <Typography variant="caption" style={{ textAlign: "center" }}>
            {t("price")!}
          </Typography>
          <Space size={1} />
          <PriceTag.List
            containerStyle={{
              backgroundColor: colors["background-basic-color-2"],
            }}
          >
            {price.map((i) => (
              <PriceTag.Item
                key={`${postId}${i.id}`}
                id={i.id}
                count={i.count}
              />
            ))}
          </PriceTag.List>
        </>
      )}
      {shouldShowCTA && (
        <>
          <Space size={2} />
          <Row style={{ justifyContent: "center" }}>
            <Button
              size="small"
              variant="outlined"
              disabled={!active}
              onClick={() => {
                if (!user) return;
                PostApi.message(user.uid, postId, user.uid, t("cta")!);
                // navigation.navigate("Post", {
                //   id: postId,
                //   defaultConversationId: user.uid,
                // });
              }}
            >
              {t("cta")!}
            </Button>
          </Row>
        </>
      )}
      {!active && <InactiveOverlay />}
    </Paper>
  );
};

export default PostItemRequest;
