import React from "react";
import { FlightQueue } from "contract/view-collection/flight";
import { Ref } from "api/ref";
import * as R from "ramda";
import { getDateOrUndefined } from "utils/getDateOrUndefined";

export function mapDataToFlightQueueIndividual(queue: any) {
  return Object.keys(queue).reduce((acc, cur) => {
    const passenger = queue[cur];

    return {
      ...acc,
      [cur]: {
        joinedAt: getDateOrUndefined(passenger.joinedAt),
        queueType: passenger.queueType,
      },
    };
  }, {});
}

export function mapDataToFlightQueue(queue: any): FlightQueue {
  return {
    visiting: R.compose(
      mapDataToFlightQueueIndividual,
      R.pathOr({}, ["visiting"])
    )(queue),
    firstClass: R.compose(
      mapDataToFlightQueueIndividual,
      R.pathOr({}, ["firstClass"])
    )(queue),
    standard: R.compose(
      mapDataToFlightQueueIndividual,
      R.pathOr({}, ["standard"])
    )(queue),
  };
}

export function useFlightQueue(flightCreatorId: string | undefined) {
  const [state, setState] = React.useState<FlightQueue | undefined>(undefined);

  React.useEffect(() => {
    if (!flightCreatorId) return;
    const ref = Ref.flightQueue(flightCreatorId);
    const callback = ref.on(
      "value",
      (snapshot: firebase.database.DataSnapshot) => {
        if (!snapshot.exists()) {
          setState({
            visiting: {},
            firstClass: {},
            standard: {},
          });
          return;
        }
        setState(mapDataToFlightQueue(snapshot.val()));
      }
    );

    return () => {
      ref.off("value", callback);
    };
  }, [flightCreatorId]);

  return state;
}
