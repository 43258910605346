import { Box, Typography } from "@material-ui/core";
import { colors } from "common/colors";
import React from "react";

export interface TouchableImageListItemProps {
  title: string;
  image: string;
  size: number;
  statusImage?: string;
  statusImageSize?: number;
  _flexBasis?: number;
}
export default function TouchableImageListItem({
  title,
  image,
  size,
  statusImage,
  statusImageSize = size * 0.46,
  _flexBasis = 20,
}: TouchableImageListItemProps) {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        flexBasis: `${_flexBasis}%`,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        style={{
          position: "relative",
        }}
      >
        <img
          src={image}
          style={{ width: size, height: size, borderRadius: "50%" }}
          alt={title}
        ></img>
        {!!statusImage && (
          <img
            src={statusImage}
            alt={`status of ${title}`}
            style={{
              width: statusImageSize,
              height: statusImageSize,
              position: "absolute",
              right: 0,
              bottom: 0,
              objectFit: "contain",
            }}
          />
        )}
      </Box>
      {!!title && (
        <Typography
          variant="caption"
          style={{
            textAlign: "center",
            color: colors["text-hint-color"],
          }}
        >
          {title}
        </Typography>
      )}
    </Box>
  );
}
