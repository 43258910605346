import { FlightPublic } from "contract/view-collection/flight";

export function filterTernipFlights(
  flights: FlightPublic[],
  filters: { noFee: boolean; verifiedOnly: boolean }
): FlightPublic[] {
  return flights.filter((flight) => {
    if (filters.noFee) {
      if (flight.entryFees !== undefined && flight.entryFees.length > 0) {
        return false;
      }
    }

    if (filters.verifiedOnly) {
      if (!flight.hostVerified) {
        return false;
      }
    }

    return true;
  });
}
