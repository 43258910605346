import React from "react";
import { Ref } from "api/ref";

export interface UserConfig {
  tokens: string[];
  blockList: string[];
}

const DEFAULT_CONFIG = {
  tokens: [],
  blockList: [],
};

export function useUserPrivate(id: string | undefined) {
  const [state, setState] = React.useState<UserConfig>(DEFAULT_CONFIG);

  React.useEffect(() => {
    if (id === undefined) return;

    Ref.userPrivate(id).onSnapshot((snapshot) => {
      if (!snapshot || !snapshot.exists) {
        setState(DEFAULT_CONFIG);
        return;
      }
      const { tokens, blockList } = snapshot.data()!;

      setState({
        tokens: tokens || [],
        blockList: blockList || [],
      });
    });
  }, [id]);

  return state;
}
