import * as persistUtils from "common/persist/utils";
import firebase from "common/firebase";
import { isDev } from "common/constants";

export const PERSIST_KEY = "emailForSignIn";

export async function sendSignInLink(email: string) {
  const actionCodeSettings = {
    handleCodeInApp: true,
    // URL must be whitelisted in the Firebase Console.
    ...(isDev
      ? {
          url: `${window.location.origin}/sign-in`,
        }
      : {
          url: "https://acnh.link/sign-in",
          iOS: {
            bundleId: "link.acnh.mobile-app",
          },
          android: {
            packageName: "link.acnh.mobile_app",
            installApp: true,
            minimumVersion: "12",
          },
        }),
  };

  // Save the email for latter usage
  await persistUtils.set(PERSIST_KEY, email);

  // TODO: error handling

  await firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings);
}
