import React from "react";
import { FlightInfo, InQueueType } from "utils/getFlightInfo";
import { useTranslation } from "react-i18next";
import { useDodoCode } from "hooks/useDodoCode";
import { Space } from "components/Space";

import { FlightPublic } from "contract/view-collection/flight";
import AnnouncementBanner from "components/AnnouncementBanner";
import { Icon, Typography } from "@material-ui/core";
import { colors } from "common/colors";
import { useIsMobile } from "hooks/useIsMobile";

export function getFlightBannerTitle(
  t: any,
  flightInfo: FlightInfo,
  dodoCodeLoading: boolean
): string {
  if (dodoCodeLoading) {
    return t("loadingDodoCode");
  }

  if (!flightInfo.inQueue) {
    return t("islandOpen");
  }

  if (flightInfo.inQueue.type === InQueueType.VISITING) {
    return t("inVisitingQueue");
  }

  return t("inQueue", {
    position: flightInfo.inQueue.position,
    totalLength: flightInfo.inQueue.length,
  });
}

export interface IslandBannerProps {
  flight: FlightPublic;
  flightInfo: FlightInfo;
}

export const IslandBanner: React.FC<IslandBannerProps> = ({
  flight,
  flightInfo,
}) => {
  const { t } = useTranslation("IslandBanner");
  const isMobile = useIsMobile();

  const { dodoCode, loading, fetch, isStale } = useDodoCode(
    flight.flightCreatorId,
    flight.dodoCodeLastChangedAt
  );
  const isVisiting = flightInfo.inQueue?.type === InQueueType.VISITING;

  if (dodoCode) {
    return (
      <AnnouncementBanner
        onPress={isVisiting ? fetch : undefined}
        disabled={loading || !isVisiting}
        style={{
          marginLeft: 0,
          width: "100%",
          ...(isMobile
            ? { position: "fixed", top: 90, left: 0, right: 0 }
            : {}),
        }}
      >
        <Typography
          style={{
            color: colors["txt-color-white-500"],
            fontFamily: "sans-serif",
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          Dodo Code:
        </Typography>
        <Space size={1} horizontal />
        <Typography
          style={{
            color: colors["txt-color-blue-500"],
            fontFamily: "sans-serif",
            fontSize: 16,
            fontWeight: 800,
          }}
        >
          {dodoCode}
        </Typography>
        <Space size={1} horizontal />
        <Icon
          className="fas fa-sync"
          style={{ fontSize: 14, color: colors["txt-color-white-500"] }}
        />
        {/* <Typography
          style={{
            position: "absolute",
            top: 2,
            fontFamily: "sans-serif",
            fontSize: 9,
          }}>
          {t("endTime", { endTime: formatEndTime(flight.planToCloseAt) })!}
        </Typography> */}
        {isStale && (
          <Typography
            style={{
              position: "absolute",
              bottom: 3,
              color: colors["color-danger-500"],
              fontFamily: "sans-serif",
              fontSize: 10,
              fontWeight: 600,
            }}
          >
            {t("newDodoCodeAvailable")!}
          </Typography>
        )}
      </AnnouncementBanner>
    );
  }

  return (
    <AnnouncementBanner
      onPress={
        flightInfo.inQueue?.type === InQueueType.VISITING ? fetch : undefined
      }
      disabled={loading}
      style={{
        // position: "absolute",
        // top: Dimensions.get("window").height * 0.1,
        // left: "-5%",
        marginLeft: 0,
        width: "100%",
        ...(isMobile ? { position: "fixed", top: 90, left: 0, right: 0 } : {}),
      }}
    >
      <Typography
        style={{
          color: colors["txt-color-white-500"],
          fontFamily: "sans-serif",
          fontSize: 16,
          fontWeight: 600,
        }}
      >
        {getFlightBannerTitle(t, flightInfo, loading)}
      </Typography>
      {/* <Typography
        style={{
          position: "absolute",
          top: 2,
          fontFamily: "sans-serif",
          fontSize: 9,
        }}>
        {t("endTime", { endTime: formatEndTime(flight.planToCloseAt) })!}
      </Typography> */}
    </AnnouncementBanner>
  );
};
