import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Space } from "../Space";
import { FooterLink } from "./FooterLink";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export const Footer: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      fontSize={14}
      display="flex"
      color={theme.palette.text.primary}
      style={isMobile ? { flexDirection: "column" } : { flexDirection: "row" }}
    >
      <Box flex={1} display="flex" flexDirection="column">
        <Box style={{ fontSize: 12, fontWeight: 600 }}>
          © {new Date().getFullYear()} Link - The ACNH Community
        </Box>
        <Space size={1} />
        <Box style={{ fontSize: 12 }}>Presented by LahK Studio</Box>
      </Box>
      <Space horizontal={!isMobile} size={4} />
      <Box style={{ minWidth: 120 }}>
        <Typography
          variant="subtitle1"
          gutterBottom
          style={{ fontWeight: 600 }}
        >
          About
        </Typography>
        <FooterLink title="News" link="/news" />
        <Space size={1} />
        <FooterLink title="FAQ" link="/news/faq" />
      </Box>
      <Space horizontal={!isMobile} size={4} />
      <Box style={{ minWidth: 120 }}>
        <Typography
          variant="subtitle1"
          gutterBottom
          style={{ fontWeight: 600 }}
        >
          Legal
        </Typography>
        <FooterLink
          title="Terms and Conditions"
          link="/news/terms-and-conditions"
        />
        <Space size={1} />
        <FooterLink title="Privacy Policy" link="/news/privacy-policy" />
      </Box>
      <Space horizontal={!isMobile} size={4} />
      <Box style={{ minWidth: 120 }}>
        <Typography
          variant="subtitle1"
          gutterBottom
          style={{ fontWeight: 600 }}
        >
          Contact
        </Typography>
        <FooterLink
          title="Twitter @acnhdotlink"
          link="https://twitter.com/acnhdotlink"
        />
        <Space size={1} />
        <FooterLink title="Discord" link="https://discord.gg/hy29EBb" />
        <Space size={1} />
        <FooterLink title="hi@acnh.link" link="mailto:hi@acnh.link" />
      </Box>
    </Box>
  );
};
