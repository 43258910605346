import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import screenshot1 from "../../assets/screenshots/screenshot1.jpg";
import screenshot2 from "../../assets/screenshots/screenshot2.jpg";
import screenshot3 from "../../assets/screenshots/screenshot3.jpg";
import screenshot4 from "../../assets/screenshots/screenshot4.jpg";
import screenshot5 from "../../assets/screenshots/screenshot5.jpg";
import { Space } from "../Space";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Screenshot } from "./Screenshot";

const useClasses = (isMobile: boolean) =>
  makeStyles((theme) => ({
    title: {
      color: theme.palette.text.primary,
      fontWeight: 600,
      textAlign: isMobile ? "center" : "left",
    },
    content: {
      flexDirection: isMobile ? "column" : "row",
      overflowX: isMobile ? "unset" : "scroll",
      "&::-webkit-scrollbar": {
        display: isMobile ? "unset" : "none",
      },
    },
  }));

export const Showcase: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const classes = useClasses(isMobile)();

  return (
    <Box>
      <Typography variant="h6" className={classes.title}>
        Screenshots
      </Typography>
      <Space size={2} />
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        className={classes.content}
      >
        {[screenshot1, screenshot2, screenshot3, screenshot5, screenshot4].map(
          (src) => {
            return (
              <Screenshot key={src} src={src} isMobile={isMobile}></Screenshot>
            );
          }
        )}
      </Box>
    </Box>
  );
};
