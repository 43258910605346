import React from "react";
import { Icon } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useAnnouncement } from "../hooks/useAnnouncement";

export const Announcement: React.FC = () => {
  const announcement = useAnnouncement();

  if (!announcement) return null;

  return (
    <Alert
      severity="info"
      icon={<Icon className="fas fa-bullhorn" style={{ fontSize: 18 }} />}
    >
      <a
        href={announcement.url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "unset", textDecoration: "none" }}
      >
        {announcement.title}
      </a>
    </Alert>
  );
};
