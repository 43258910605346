import React from "react";
import { ReactComponent as Ellipse } from "assets/pattern/text-bubble.svg";
import { Box, BoxProps, Typography } from "@material-ui/core";
import { colors } from "common/colors";

export interface BubbleTextProps {
  title: string;
  bgColor?: keyof typeof colors;
  txtColor?: keyof typeof colors;
  style?: BoxProps["style"];
}

export const BubbleText: React.FC<BubbleTextProps> = ({
  title,
  bgColor = "bg-color-yellow-500",
  txtColor = "txt-color-brown-500",
  style,
}) => {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...style,
      }}
    >
      <Box
        style={{
          flex: 1,
          padding: "4px 16px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Ellipse
          fill={colors[bgColor]}
          height="100%"
          width="100%"
          style={{ position: "absolute" }}
        />
        <Box
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Typography
            variant="caption"
            style={{
              fontWeight: 600,
              textAlign: "center",
              color: colors[txtColor],
            }}
          >
            {title}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
