import { isDate } from "./isDate";

export function getDateOrUndefined(a: any) {
  const d = new Date(a);

  if (isDate(d)) {
    return d;
  }

  return undefined;
}
