import { Box } from "@material-ui/core";
import { ColouredCell } from "components/ColouredCell";
import { SectionTitle } from "components/SectionTitle";
import { Space } from "components/Space";
import { ProfileContext } from "modules/auth/ProfileContext";
import React from "react";
import { useTranslation } from "react-i18next";
import Confident from "../../../../assets/reaction/Confident.png";
import { AirportCurrentFlight } from "./CurrentFlight";

export const IWantVisitors = () => {
  const { t } = useTranslation("AirportScreen");
  const userProfile = React.useContext(ProfileContext);

  return (
    <Box style={{ display: "flex", flexDirection: "column" }}>
      <SectionTitle title="I want visitors!" variant="h6" />
      <Space size={2} />
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <ColouredCell
          image={Confident}
          title={
            userProfile?.flightStatus === "open"
              ? t("updateFlight")
              : t("createFlight")
          }
          eyebrow={t("flightPlanLabel")}
          href="/flight/manage"
        />
      </Box>
      <Space size={2} />
      <AirportCurrentFlight />
    </Box>
  );
};
