import { Box } from "@material-ui/core";
import { ColouredCell } from "components/ColouredCell";
import { SectionTitle } from "components/SectionTitle";
import { Space } from "components/Space";
import React from "react";
import Turnip from "assets/Turnip.png";
import Flourish from "assets/reaction/Flourish.png";
import { TerminalGeneral } from "../TerminalGeneral/TerminalGeneral";
import { TerminalTurnips } from "../TerminalTurnips/TerminalTurnips";
import { Terminal } from "contract/common";
import { useAirportData } from "pages/Airport/hooks/useAirportData";
import { useTranslation } from "react-i18next";
import { CurrentQueueing } from "./CurrentQueueing";

function useSearchParamsToTerminal(
  selectedTerminal: Terminal | null | undefined,
  setSelectedTerminal: (t: Terminal | null | undefined) => void
) {
  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const terminal = searchParams.get("terminal");

    if (
      terminal &&
      [String(Terminal.GENERAL), String(Terminal.TURNIPS)].includes(terminal)
    ) {
      setSelectedTerminal(terminal as Terminal);
    } else {
      setSelectedTerminal(null);
    }
  }, []);
  React.useEffect(() => {
    if (selectedTerminal === undefined) return;
    const url = new URL(window.location.origin + window.location.pathname);
    if (selectedTerminal) {
      url.searchParams.set("terminal", selectedTerminal);
    }
    window.history.pushState({}, "", url.toString());
  }, [selectedTerminal]);
}

export const IWannaFly: React.FC = () => {
  const { t } = useTranslation("AirportScreen");
  const airportData = useAirportData();

  const [selectedTerminal, setSelectedTerminal] = React.useState<
    Terminal | null | undefined
  >(undefined);

  useSearchParamsToTerminal(selectedTerminal, setSelectedTerminal);

  const toggleTerminal = React.useCallback((terminal: Terminal) => {
    setSelectedTerminal((prev) => (prev === terminal ? null : terminal));
  }, []);

  return (
    <Box style={{ display: "flex", flexDirection: "column" }}>
      <SectionTitle title="I wanna fly!" variant="h6" />
      <Space size={2} />
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <ColouredCell
          image={Turnip}
          title={t("terminalTurnips", {
            count: airportData.turnips || 0,
            context: `${airportData.turnips ? "plural" : "0"}`, // https://github.com/i18next/i18next/issues/1220#issuecomment-654161038
          })}
          eyebrow={t("terminalLabel")}
          onPress={() => {
            toggleTerminal(Terminal.TURNIPS);
          }}
          selected={selectedTerminal === Terminal.TURNIPS}
        />
        <ColouredCell
          image={Flourish}
          title={t("terminalGeneral", {
            count: airportData.general || 0,
            context: `${airportData.general ? "plural" : "0"}`,
          })}
          eyebrow={t("terminalLabel")}
          onPress={() => {
            toggleTerminal(Terminal.GENERAL);
          }}
          selected={selectedTerminal === Terminal.GENERAL}
        />
      </Box>
      <Space size={2} />
      <CurrentQueueing />
      {selectedTerminal === Terminal.TURNIPS && (
        <>
          <Space size={2} />
          <TerminalTurnips />
        </>
      )}
      {selectedTerminal === Terminal.GENERAL && (
        <>
          <Space size={2} />
          <TerminalGeneral />
        </>
      )}
    </Box>
  );
};
