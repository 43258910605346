import {
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
  Tabs,
  Tab,
  withStyles,
} from "@material-ui/core";
import TabPanel from "components/TabPanel";
import { Terminal, TurnipsFlightType } from "contract/common";
import { useFlightList } from "hooks/useFlightList";
import React from "react";
import { useTranslation } from "react-i18next";
import { SectionTitle } from "../../../../components/SectionTitle";
import { Space } from "../../../../components/Space";
import { useCheckboxState } from "../../../../hooks/useCheckboxState";
import { filterTernipFlights } from "./filterTernipFlights";
import { groupTernipFlights } from "./groupTernipFlights";
import { TerminalTurnipsCell } from "./TerminalTurnipsCell";

const StyledTabs = withStyles({
  root: {
    backgroundColor: "#ffffff",
  },
})(Tabs);

function useTernipsTerminalFlights() {
  const flights = useFlightList(Terminal.TURNIPS);
  const noFeeCheckbox = useCheckboxState();
  const verifiedHostCheckbox = useCheckboxState();

  const filteredFlights = React.useMemo(
    () =>
      filterTernipFlights(flights || [], {
        noFee: noFeeCheckbox.checked,
        verifiedOnly: verifiedHostCheckbox.checked,
      }),
    [flights, noFeeCheckbox.checked, verifiedHostCheckbox.checked]
  );

  const groupedFlights = React.useMemo(
    () => groupTernipFlights(filteredFlights),
    [filteredFlights]
  );

  const sellingFlights = groupedFlights[TurnipsFlightType.SELLING] || [];
  const buyingFlights = groupedFlights[TurnipsFlightType.BUYING] || [];

  return {
    noFeeCheckbox,
    verifiedHostCheckbox,
    sellingFlights,
    buyingFlights,
  };
}

export const TerminalTurnips = () => {
  const { t } = useTranslation("AirportTerminalTurnipsScreen");

  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const {
    noFeeCheckbox,
    verifiedHostCheckbox,
    sellingFlights,
    buyingFlights,
  } = useTernipsTerminalFlights();

  return (
    <Box
      style={{
        backgroundColor: "rgba(243, 158, 100, 0.2)",
        borderRadius: 8,
        padding: 16,
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="subtitle1"
          style={{ fontWeight: "bold", color: "#6B5C43" }}
        >
          TURNIPS
        </Typography>
      </Box>
      <Box
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <SectionTitle
          title="Filters:"
          variant="subtitle2"
          containerStyle={{ alignSelf: undefined }}
        />
        <Space horizontal size={2} />
        <FormControlLabel
          control={
            <Checkbox
              checked={noFeeCheckbox.checked}
              onChange={noFeeCheckbox.onChange}
              name="No Fees"
            />
          }
          label="No Fees"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={verifiedHostCheckbox.checked}
              onChange={verifiedHostCheckbox.onChange}
              name="Verified host"
            />
          }
          label="Verified host"
        />
      </Box>
      <Space size={2} />
      <StyledTabs
        value={selectedTabIndex}
        onChange={(event, newValue) => setSelectedTabIndex(newValue)}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab label={t("typeSellingLabel")!} />
        <Tab label={t("typeBuyingLabel")!} />
      </StyledTabs>
      <Space size={1} />
      <TabPanel value={selectedTabIndex} index={0}>
        {sellingFlights?.length === 0 && (
          <Box
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "16px 0",
            }}
          >
            <Typography variant="caption" style={{ color: "#8F9BB3" }}>
              {t("noFlightLabel")!}
            </Typography>
          </Box>
        )}
        {sellingFlights && sellingFlights.length > 0 && (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              maxHeight: 560,
              overflowY: "auto",
            }}
          >
            {sellingFlights.map((flight) => (
              <TerminalTurnipsCell
                key={flight.flightId}
                flight={flight}
                onPress={() => {
                  window.open(`/island/${flight.flightCreatorId}`, "_blank");
                }}
              />
            ))}
          </Box>
        )}
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={1}>
        {buyingFlights?.length === 0 && (
          <Box
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "16px 0",
            }}
          >
            <Typography variant="caption" style={{ color: "#8F9BB3" }}>
              {t("noFlightLabel")!}
            </Typography>
          </Box>
        )}
        {buyingFlights && buyingFlights.length > 0 && (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              maxHeight: 560,
              overflowY: "auto",
            }}
          >
            {buyingFlights.map((flight) => (
              <TerminalTurnipsCell
                key={flight.flightId}
                flight={flight}
                onPress={() => {
                  window.open(`/island/${flight.flightCreatorId}`, "_blank");
                }}
              />
            ))}
          </Box>
        )}
      </TabPanel>
    </Box>
  );
};
