import { User } from "modules/auth/AuthContext";

import { FlightPublic } from "contract/view-collection/flight";
import { FlightQueue } from "contract/view-collection/flight";
import { QueueType } from "contract/common";
import { UserProfile } from "contract/data-collection/user";

export enum InQueueType {
  VISITING,
  FIRST_CLASS,
  STANDARD,
}

export interface GetFlightInfoArgs {
  flight: FlightPublic | null | undefined;
  flightQueue: FlightQueue | undefined;
  user: User;
  userProfile: UserProfile | null | undefined;
}

export interface FlightInfo {
  isHost: boolean;
  isMember: boolean;
  isVerified: boolean;
  isTrusted: boolean;
  queueSize: {
    total: number;
    standard: number;
    firstClass: number;
  };
  inQueue:
    | {
        type: InQueueType;
        queueType: QueueType;
        position: number;
        length: number;
      }
    | undefined;
}

export function getQueueSizeIndividual(flightQueue: FlightQueue | undefined) {
  if (flightQueue === undefined) {
    return {
      standard: 0,
      firstClass: 0,
    };
  }

  const { visiting, firstClass, standard } = flightQueue;

  const firstClassInVisiting = Object.values(visiting).filter(
    (p) => p.queueType === QueueType.FIRST_CLASS
  );

  return {
    standard:
      Object.keys(standard).length +
      (Object.keys(visiting).length - firstClassInVisiting.length),
    firstClass: Object.keys(firstClass).length + firstClassInVisiting.length,
  };
}

export function getInQueue(
  queue: FlightQueue | undefined,
  user: User
):
  | {
      type: InQueueType;
      queueType: QueueType;
      position: number;
      length: number;
    }
  | undefined {
  if (!queue || !user) return undefined;

  function getIsInQueueIndividual(
    q: FlightQueue["firstClass"],
    uid: string
  ): { position: number; queueType: QueueType; length: number } | undefined {
    const found = Object.keys(q).indexOf(uid) + 1;
    if (found === 0) return undefined;

    return {
      position: found,
      length: Object.keys(q).length,
      queueType: q[Object.keys(q)[found - 1]].queueType,
    };
  }

  const inVisitingQueue = getIsInQueueIndividual(queue.visiting, user.uid);
  if (inVisitingQueue) {
    return {
      ...inVisitingQueue,
      type: InQueueType.VISITING,
    };
  }

  const inFirstClassQueue = getIsInQueueIndividual(queue.firstClass, user.uid);
  if (inFirstClassQueue) {
    return {
      ...inFirstClassQueue,
      type: InQueueType.FIRST_CLASS,
    };
  }

  const inStandardQueue = getIsInQueueIndividual(queue.standard, user.uid);
  if (inStandardQueue) {
    return {
      ...inStandardQueue,
      type: InQueueType.STANDARD,
    };
  }

  return undefined;
}

export function getFlightInfo({
  flight,
  flightQueue,
  user,
  userProfile,
}: GetFlightInfoArgs): FlightInfo {
  const isHost = !!user && !!flight && user.uid === flight.flightCreatorId;
  const isMember = !!userProfile?.membership;
  const isVerified = userProfile?.verificationLevel !== undefined;
  const queueSize = {
    ...getQueueSizeIndividual(flightQueue),
    total: flight?.queueSize || 0,
  };
  const inQueue = getInQueue(flightQueue, user);

  return {
    isHost,
    isMember,
    isVerified,
    isTrusted: isMember || isVerified,
    queueSize,
    inQueue,
  };
}
