import React from "react";
import {
  Box,
  TextField,
  FormHelperText,
  Typography,
  Link,
} from "@material-ui/core";
import { Space } from "components/Space";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { BubbleButton } from "components/BubbleButton";
import { sendSignInLink } from "modules/auth/sendSignInLink";
import { useProgress } from "hooks/useProgress";
import { signInWithEmailLink } from "modules/auth/signInWithEmailLink";
import { Alert, AlertTitle } from "@material-ui/lab";
import { colors } from "common/colors";

export const SignInForm: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [email, setEmail] = React.useState("");
  const magicLinkSignInProgress = useProgress("Welcome!");
  const sendMagicLinkProgress = useProgress(
    `Magic Link should arrive at ${email} soon. Please check your inbox.`
  );

  React.useEffect(() => {
    magicLinkSignInProgress.start();
    signInWithEmailLink()
      .then(() => {
        magicLinkSignInProgress.succeeded();
      })
      .catch((error) => {
        magicLinkSignInProgress.failed(error);
      });
  }, []);

  return (
    <Box
      bgcolor="white"
      flex={1}
      borderRadius={4}
      display="flex"
      alignItems="center"
      py={4}
      px={4}
      style={{ flexDirection: "column" }}
    >
      <Box
        display="flex"
        style={{
          flex: 1,
          maxWidth: 320,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" color="primary">
          Sign In with Magic Link
        </Typography>
        <Space size={4} />
        <Alert severity="info" style={{ position: "relative" }}>
          <Box
            component="img"
            // @ts-ignore
            src="/Timmy_and_Tommy.png"
            width={120}
            height="auto"
            style={{
              objectFit: "contain",
              position: "absolute",
              bottom: -56,
              right: -48,
            }}
          ></Box>
          <AlertTitle>What is Magic Link? And why?</AlertTitle>
          Magic Link is a link sent to your email to sign in without a password.
          <br />
          Using Magic Link helps us prevent malicious users and protect everyone
          in the community.
        </Alert>
        <Space size={6} />
        <TextField
          label="Email"
          placeholder="Enter your email address."
          type="email"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          style={{ width: "100%" }}
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        {sendMagicLinkProgress.error && (
          <FormHelperText>{sendMagicLinkProgress.error}</FormHelperText>
        )}
        <Space size={4} />
        <Box style={{ display: "flex", flexDirection: "row" }}>
          <BubbleButton
            title="Send Magic Link"
            disabled={!email || sendMagicLinkProgress.loading}
            onPress={() => {
              sendMagicLinkProgress.start();
              sendSignInLink(email)
                .then(() => {
                  sendMagicLinkProgress.succeeded();
                })
                .catch((error) => {
                  sendMagicLinkProgress.failed(error);
                });
            }}
          />
        </Box>
        <Space size={4} />
        <Typography
          variant="caption"
          style={{ color: colors["text-hint-color"] }}
        >
          * If you come from Turnips Exchange, please sign in with the same
          email address so we can automatically synchronize profile information
          for you.
        </Typography>
        <Space size={2} />
        <Typography
          variant="caption"
          style={{ color: colors["text-hint-color"] }}
        >
          * If you prefer password, please download{" "}
          <Link href="/">Link App</Link> to setup/reset password.
        </Typography>
      </Box>
    </Box>
  );
};
