import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./locale/en.json";

i18next.use(initReactI18next).init({
  fallbackLng: "en",
  debug: true,
  resources: {
    en,
  },
  react: {
    useSuspense: false,
  },
});

export const i18n = i18next;
