import { Ref } from "api/ref";
import React from "react";
import { User } from "modules/auth/AuthContext";

export function useUserQueueing(user: User) {
  const [state, setState] = React.useState<string[] | undefined>(undefined);

  React.useEffect(() => {
    if (!user) return;

    const unsubscribe = Ref.queueing(user.uid).onSnapshot((s) => {
      if (!s || s.empty) {
        // TODO: handle no value case
        setState([]);
        return;
      }

      setState(s.docs.map((doc) => doc.id));
      return;
    }, console.error);

    return () => {
      unsubscribe();
    };
  }, [user]);

  return state;
}
