import React from "react";
import { ChatRecord } from "contract/view-collection/chat";
import { Ref } from "api/ref";
import { AuthContext } from "modules/auth/AuthContext";
import { useUserPrivate } from "./useUserPrivate";

export function mapChatRecord(key: string, val: any): ChatRecord {
  const { uid, contentType, content, at } = val;
  return {
    id: key,
    uid,
    contentType,
    content,
    at: new Date(at),
  };
}

export function useFlightChat(flightCreatorId: string) {
  const [chats, setChats] = React.useState<ChatRecord[] | undefined>(undefined);

  const currentUser = React.useContext(AuthContext)!;
  const userConfig = useUserPrivate(currentUser?.uid);

  React.useEffect(() => {
    let ref: firebase.database.Query;
    let callback: any; // 🐑 Lazy any here.

    Ref.flightChat(flightCreatorId)
      .orderByChild("at")
      .limitToLast(25)
      .once("value")
      .then((snapshot) => {
        if (!snapshot.exists()) {
          setChats([]);
          return;
        }

        const data = snapshot.val();

        const list = Object.keys(data)
          .map((k) => mapChatRecord(k, data[k]))
          .sort((a, b) => (a.at > b.at ? 1 : -1));

        setChats(list);

        const lastMessageId =
          list.length > 0 ? list[list.length - 1].id : undefined;
        const lastMessage =
          list.length > 0 ? data[list[list.length - 1].id] : undefined;

        ref = Ref.flightChat(flightCreatorId)
          .orderByChild("at")
          .startAt(list.length > 0 ? lastMessage!.at + 1000 : Date.now());

        callback = ref.on(
          "child_added",
          (childSnapshot: firebase.database.DataSnapshot) => {
            if (lastMessageId && childSnapshot.key === lastMessageId) return;

            setChats((prev) => [
              ...(prev || []),
              mapChatRecord(childSnapshot.key!, childSnapshot.val()),
            ]);
          }
        );
      });

    return () => {
      if (ref) {
        ref.off("child_added", callback);
      }
    };
  }, [flightCreatorId]);

  const filteredChat = React.useMemo(() => {
    return chats?.filter((chat) => !userConfig.blockList.includes(chat.uid));
  }, [chats, userConfig]);

  return filteredChat;
}
