import { Box, BoxProps } from "@material-ui/core";
import React from "react";

export interface RoundedWhiteContainerProps {
  containerStyle?: BoxProps["style"];
}

const RoundedWhiteContainer: React.FC<RoundedWhiteContainerProps> = ({
  children,
  containerStyle,
}) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: "white",
        borderRadius: 8,
        padding: 16,
        ...containerStyle,
      }}
    >
      {children}
    </Box>
  );
};

export default RoundedWhiteContainer;
