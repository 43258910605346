import firebase from "../common/firebase";

export class Ref {
  static verificationRequests = firebase
    .firestore()
    .collection("verificationRequests");
  static passportImage = (uid: string) =>
    firebase.storage().ref(`users/${uid}/passport`);

  static airport = firebase.database().ref("screens/airport");
  static userPrivate = (uid: string) =>
    firebase.firestore().collection("userPrivate").doc(uid);
  static profile = (uid: string) =>
    firebase.firestore().collection("users").doc(uid);
  static queueing = (uid: string) => Ref.profile(uid).collection("queueing");
  static flightCurrent = (uid: string) =>
    firebase.firestore().collection("flightCurrent").doc(uid);
  static flight = (uid: string) =>
    firebase.database().ref("flights").child(uid);
  static flightList = firebase.database().ref("flights");
  static flightQueue = (flightCreatorId: string) =>
    firebase.database().ref("flightQueue").child(flightCreatorId);
  static flightChat = (flightCreatorId: string) =>
    firebase.database().ref("chatFlight").child(flightCreatorId);
  static article = (id: string) =>
    firebase.firestore().collection("article").doc(id);
  static airportSlides = firebase.database().ref("airportSlides");
  static reports = (reporteeUid: string) =>
    firebase
      .database()
      .ref("report")
      .orderByChild("reporteeUid")
      .equalTo(reporteeUid);
  static verificationRequest = (uid: string) =>
    firebase.firestore().collection("verificationRequest").doc(uid);
  static configContact = firebase
    .firestore()
    .collection("config")
    .doc("contact");
  static configFeatures = firebase
    .firestore()
    .collection("config")
    .doc("features");

  static posts = firebase.firestore().collection("posts");
  static post = (postId: string) => Ref.posts.doc(postId);
  static postMetadata = (postId: string) =>
    firebase.firestore().collection("postsMetadata").doc(postId);
  static postReplies = (postId: string) =>
    Ref.posts.doc(postId).collection("replies");
  static postReply = (postId: string, replyId: string) =>
    Ref.postReplies(postId).doc(replyId);
  static postReplyLike = (postId: string, postReplyId: string, uid: string) =>
    Ref.posts
      .doc(postId)
      .collection("replies")
      .doc(postReplyId)
      .collection("likes")
      .doc(uid);
  static postReplyMetadata = (postId: string, postReplyId: string) =>
    firebase
      .firestore()
      .collection("postReplyMetadata")
      .doc(postId)
      .collection("replies")
      .doc(postReplyId);
}

// const url = await Ref.passportImage("123").getDownloadURL();
