import isAfter from "date-fns/fp/isAfter";
import isBefore from "date-fns/fp/isBefore";
import addHours from "date-fns/fp/addHours";

export function isWithinHours(hours: number) {
  return (d: Date) => {
    const now = new Date();
    return isAfter(now)(d) && isBefore(addHours(hours)(now))(d);
  };
}
