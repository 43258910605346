import React from "react";
import { useReportForm } from "./useReportForm";
import { ReportRecord } from "contract/data-collection/report";
import { BubbleButton } from "components/BubbleButton";
import { ReportReason } from "contract/common";
import { Space } from "components/Space";
import { useTranslation } from "react-i18next";
import { InProgressModal } from "components/InProgressModal";
import Row from "components/Row";
import {
  Button,
  DialogActions,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

export interface ReportFormProps {
  reporteeUid: ReportRecord["reporteeUid"];
  subject: ReportRecord["subject"];
  subjectMetadata: ReportRecord["subjectMetadata"];
  onRequestClose: () => void;
}

export const ReportForm: React.FC<ReportFormProps> = ({
  reporteeUid,
  subject,
  subjectMetadata,
  onRequestClose,
}) => {
  const { t } = useTranslation("ReportForm");

  const form = useReportForm({
    reporteeUid,
    subject,
    subjectMetadata,
  });

  return (
    <React.Fragment>
      <FormControl variant="outlined" error={!!form.reason.error}>
        <InputLabel id="flight-form-terminal-label">
          {form.reason.label}
        </InputLabel>
        <Select
          value={
            form.reason.value
              ? t(`ReportReason.${form.reason.value}`, { ns: "shared" })!
              : undefined
          }
          label={form.reason.label}
          onChange={({ target: { value } }) => {
            form.reason.onChange(value as ReportReason);
          }}
        >
          {Object.values(ReportReason).map((item) => (
            <MenuItem
              value={item}
              title={t(`ReportReason.${item}`, { ns: "shared" })!}
              key={item}
            >
              {t(`ReportReason.${item}`, { ns: "shared" })!}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          {form.reason.error || form.reason.helpText}
        </FormHelperText>
      </FormControl>
      <Space size={2} />
      <TextField
        label={form.description.label}
        variant="outlined"
        multiline
        rowsMax={4}
        rows={4}
        InputLabelProps={{
          shrink: true,
        }}
        value={form.description.value}
        helperText={form.description.error || form.description.helpText}
        error={!!form.description.error}
        onChange={({ target: { value } }) => {
          form.description.onChange(value as string);
        }}
      />
      <Space size={2} />
      <InProgressModal visible={form.loading} />
      <Row style={{ justifyContent: "flex-end" }}>
        <Button onClick={onRequestClose}>Cancel</Button>
        <Button
          variant="contained"
          onClick={form.submit}
          color="secondary"
          disabled={!form.canSubmit}
        >
          {t(`submit.${subject}`)}
        </Button>
      </Row>
    </React.Fragment>
  );
};
