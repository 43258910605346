import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

const useStyles = makeStyles(theme=>({
  root: {
    color: theme.palette.text.primary,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

export interface FooterLinkProps {
  title: string;
  link: string;
}

export const FooterLink: React.FC<FooterLinkProps> = ({ title, link }) => {
  const styles = useStyles();

  return (
    <Box
      component="a"
      display="block"
      // @ts-ignore
      href={link}
      target="_blank"
      className={styles.root}
    >
      {title}
    </Box>
  );
};
