import React from "react";
import { FlightApi } from "api/flight";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

export function useDodoCode(
  flightCreatorId: string,
  dodoCodeLastChangedAt: string | undefined
) {
  const { t } = useTranslation("useDodoCode");
  const { enqueueSnackbar } = useSnackbar();

  const [dodoCode, setDodoCode] = React.useState<string | undefined>(undefined);
  const [loading, setLoading] = React.useState(false);
  const [isStale, setIsStale] = React.useState(false);

  const fetch = React.useCallback(() => {
    setLoading(true);
    setDodoCode(undefined);
    setIsStale(false);
    FlightApi.getDodoCode(flightCreatorId)
      .then((code) => {
        setDodoCode(code);
        setLoading(false);
      })
      .catch((e) => {
        enqueueSnackbar(t("error.general", { ns: "shared", msg: e.message }), {
          variant: "error",
        });
        setLoading(false);
      });
  }, [flightCreatorId]);

  React.useEffect(() => {
    setIsStale(true);
  }, [dodoCodeLastChangedAt]);

  return {
    dodoCode,
    fetch,
    loading,
    isStale,
  };
}
