import {
  Avatar,
  Box,
  Button,
  Icon,
  Paper,
  Typography,
} from "@material-ui/core";
import { PostApi } from "api/post";
import { colors } from "common/colors";
import { DisplayName } from "components/DisplayName";
import Row from "components/Row";
import { Space } from "components/Space";
import TouchableOpacity from "components/TouchableOpacity";
import { PostReply, PostReplyMetadata } from "contract/data-collection/post";
import { formatDistanceToNow } from "date-fns";
import usePostReply from "hooks/usePostReply";
import { usePostReplyLiked } from "hooks/usePostReplyLiked";
import { useUserProfile } from "hooks/useUserProfile";
import { AuthContext } from "modules/auth/AuthContext";
import React from "react";
import PostReplyTo from "./PostReplyTo";

export interface PostItemReplyProps {
  reply: PostReply;
  replyMetadata?: PostReplyMetadata | null | undefined;
  wrapTextWithLayout?: boolean;
  onPress?: (postReply: PostReply) => void;
  onPressReply?: (postReply: PostReply) => void;
  disabled?: boolean;
  avatarSize?: "small" | "medium" | "large";
  showActions?: boolean;
}

const PostItemReply: React.FC<PostItemReplyProps> = ({
  reply,
  wrapTextWithLayout = false,
  avatarSize = "small",
}) => {
  const authorProfile = useUserProfile(reply.uid);

  const user = React.useContext(AuthContext);
  const liked = usePostReplyLiked(reply.postId, reply.id, user?.uid);

  const replyToReply = usePostReply(reply.postId, reply.replyTo);

  return (
    <Box
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        padding: "8px 0",
      }}
    >
      <Row style={{ alignItems: "flex-start", flex: 1 }}>
        <Avatar
          variant="rounded"
          src={authorProfile?.photoURL}
          style={{
            borderColor: colors["bg-color-off-white-500"],
            borderWidth: 2,
            width: avatarSize === "small" ? 24 : 32,
            height: avatarSize === "small" ? 24 : 32,
            objectFit: "cover",
          }}
        />
        <Space horizontal size={1} />
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <DisplayName profile={authorProfile} category="caption" />
            <Typography
              variant="caption"
              style={{
                color: colors["text-hint-color"],
              }}
            >
              {formatDistanceToNow(reply.createdAt)}
            </Typography>
          </Box>
          {!!reply.replyTo && replyToReply && (
            <>
              <Space size={1} />
              <PostReplyTo reply={replyToReply} />
            </>
          )}
          <Space size={0.5} />
          {wrapTextWithLayout ? (
            <Paper elevation={0} style={{ borderRadius: 4 }}>
              <Typography variant="body2">{reply.text}</Typography>
            </Paper>
          ) : (
            <Typography variant="body2">{reply.text}</Typography>
          )}
        </Box>
      </Row>
    </Box>
  );
};

export default PostItemReply;
