import { Ref } from "api/ref";
import { ResidentWithStatus } from "contract/data-collection/flight";
import { ItemOffer, ItemRequest, Post } from "contract/data-collection/post";
import React from "react";
import { getFirebaseDateOrNow } from "utils/getFirebaseDateOrNow";

export function getPost(
  data:
    | firebase.firestore.QueryDocumentSnapshot
    | firebase.firestore.DocumentSnapshot
): Post {
  const resident: any = data.get("resident");
  const itemOffer: any = data.get("itemOffer");
  const itemRequest: any = data.get("itemRequest");
  return {
    id: data.id,
    createdAt: getFirebaseDateOrNow(data.get("createdAt")),
    uid: data.get("uid"),
    type: data.get("type"),
    text: data.get("text"),
    images: data.get("images"),
    resident: resident ? (resident as ResidentWithStatus) : undefined,
    itemOffer: itemOffer ? (itemOffer as ItemOffer) : undefined,
    itemRequest: itemRequest ? (itemRequest as ItemRequest) : undefined,
    interactionFinished: !!data.get("interactionFinished"),
  };
}

export default function usePost(postId: string | undefined) {
  const [state, setState] = React.useState<Post | null | undefined>();

  React.useEffect(() => {
    if (!postId) return;
    Ref.post(postId).onSnapshot((snapshot) => {
      if (!snapshot || !snapshot.exists) {
        setState(null);
        return;
      }

      setState(getPost(snapshot));
    });
  }, [postId]);

  return state;
}
