import { Box, BoxProps, Typography } from "@material-ui/core";
import { colors } from "common/colors";
import RoundedWhiteContainer from "components/RoundedWhiteContainer";
import React from "react";
import { useTranslation } from "react-i18next";

export interface TouchableImageListContainerProps {
  numColumns?: number;
  fillerSize: number;
  containerStyle?: BoxProps["style"];
}

const TouchableImageListContainer: React.FC<TouchableImageListContainerProps> = ({
  children,
  fillerSize,
  numColumns = 4,
  containerStyle,
}) => {
  const { t } = useTranslation("TouchableImageList");

  const childrenCount = React.Children.toArray(children).filter(
    (child) => !!child
  ).length;

  const fillerItemCount = React.useMemo(() => {
    const remainder = childrenCount % numColumns;
    return remainder === 0 ? 0 : numColumns - remainder;
  }, [childrenCount, numColumns]);

  const data = React.useMemo(() => {
    return [
      ...React.Children.toArray(children),
      ...new Array(fillerItemCount).fill({ filler: true }),
    ];
  }, [children, fillerItemCount]);

  return (
    <RoundedWhiteContainer
      containerStyle={{
        flexWrap: "wrap",
        overflowY: "auto",
        maxHeight: 144,
        ...containerStyle,
      }}
    >
      {childrenCount === 0 && (
        <Typography
          variant="caption"
          style={{
            flex: 1,
            textAlign: "center",
            color: colors["text-hint-color"],
          }}
        >
          {t("emptyListLabel")!}
        </Typography>
      )}
      {childrenCount > 0 &&
        data.map((item) => {
          if (item.filler === true) {
            return (
              <Box
                style={{
                  width: fillerSize,
                  height: fillerSize,
                }}
              />
            );
          }
          return item;
        })}
    </RoundedWhiteContainer>
  );
};

export default TouchableImageListContainer;
