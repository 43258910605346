import React, { ReactElement } from "react";
import { ReactComponent as SpeechBolb } from "assets/pattern/speech-bubble.svg";
import { BubbleText } from "./BubbleText";
import { BoxProps, Box, Typography } from "@material-ui/core";
import { colors } from "common/colors";

export interface SpeechProps {
  characterName: string;
  text: React.ReactNode;
  speechContainerWidth?: number | string;
  bubbleTxtColor?: keyof typeof colors;
  bubbleBgColor?: keyof typeof colors;
  style?: BoxProps;
}

export const Speech: React.FC<SpeechProps> = ({
  text,
  speechContainerWidth = "100%",
  characterName,
  bubbleBgColor,
  bubbleTxtColor,
  style,
}) => {
  return (
    <Box
      style={{
        width: speechContainerWidth,
        ...style,
      }}
    >
      <SpeechBolb
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          width: "100%",
          height: "100%",
          filter: "drop-shadow(0 4px 6px rgba(0,0,0,0.1))",
        }}
      />
      <Box style={{ padding: "48px 64px", position: "relative" }}>
        {typeof text === "string" ? (
          <Typography
            style={{
              color: "txt-color-brown-500",
            }}
            variant="h6"
          >
            {text}
          </Typography>
        ) : (
          text
        )}
      </Box>
      <BubbleText
        title={characterName}
        bgColor={bubbleBgColor}
        txtColor={bubbleTxtColor}
        style={{
          position: "absolute",
          top: 12,
          left: 24,
          transform: "rotate(-8deg)",
        }}
      />
    </Box>
  );
};
