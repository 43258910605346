import React from "react";
import { ReportRecordWithId } from "./useReports";
import { formatEndTime } from "utils/formatEndTime";
import { Space } from "components/Space";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";
import { colors } from "common/colors";

export interface ReportReportedProps {
  report: ReportRecordWithId;
}

export const ReportReported: React.FC<ReportReportedProps> = ({ report }) => {
  const { t } = useTranslation("ReportReported");

  return (
    <Box>
      <Space size={1} />
      <Typography variant="caption" style={{ textAlign: "center" }}>
        {t("label.thanksForReporting")!}
      </Typography>
      <Space size={2} />
      <Typography
        variant="caption"
        style={{ color: colors["text-hint-color"] }}
      >
        {t("label.reason")!}
      </Typography>
      <Typography>
        {t(`ReportReason.${report.reason}`, { ns: "shared" })!}
      </Typography>
      <Space size={1} />
      <Typography
        variant="caption"
        style={{ color: colors["text-hint-color"] }}
      >
        {t("label.desc")!}
      </Typography>
      <Typography>{report.reasonDescription}</Typography>
      <Space size={1} />
      <Typography
        variant="caption"
        style={{ color: colors["text-hint-color"] }}
      >
        {t("label.when")!}
      </Typography>
      <Typography>{formatEndTime(report.at)}</Typography>
    </Box>
  );
};
