import useItem from "hooks/useItem";
import React from "react";
import QuestionMark from "assets/reaction/QuestionMark.png";
import TouchableImageListItem, { TouchableImageListItemProps } from "./Item";

export interface ItemItemProps
  extends Omit<TouchableImageListItemProps, "title" | "image"> {
  id: string;
  hideTitle?: boolean;
}

const ItemItem: React.FC<ItemItemProps> = ({ id, hideTitle, ...props }) => {
  const item = useItem(id);

  if (!item) return null;

  return (
    <TouchableImageListItem
      title={hideTitle ? "" : item.name}
      image={item.image || QuestionMark}
      {...props}
    />
  );
};

export default ItemItem;
