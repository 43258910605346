import { Ref } from "api/ref";
import React from "react";
import { UserProfile } from "contract/view-collection/user";

export function useUserProfile(uid?: string) {
  const [state, setState] = React.useState<UserProfile | null | undefined>(
    undefined
  );

  React.useEffect(() => {
    if (!uid) return;

    const unsubscribe = Ref.profile(uid).onSnapshot((s) => {
      if (!s.exists) {
        setState(null);
        return;
      }

      const { createdAt, disabledAt, ...rest } = s.data()!;

      setState({
        ...rest,
        uid,
        createdAt: createdAt
          ? (createdAt as firebase.firestore.Timestamp).toDate()
          : undefined,
        disabledAt: disabledAt
          ? (disabledAt as firebase.firestore.Timestamp).toDate()
          : undefined,
      } as UserProfile);
      return;
    });

    return () => {
      unsubscribe();
    };
  }, [uid]);

  return state;
}
