import { Typography, Icon } from "@material-ui/core";
import { Space } from "components/Space";
import TouchableOpacity from "components/TouchableOpacity";
import React from "react";

export interface VisitMyIslandButtonProps {
  user: firebase.User;
}

const VisitMyIslandButton: React.FC<VisitMyIslandButtonProps> = ({ user }) => {
  return (
    <TouchableOpacity
      style={{ justifyContent: "flex-end", padding: "16px 8px" }}
      onPress={() => {
        window.open(`/island/${user.uid}`, "_blank");
      }}
    >
      <Typography variant="caption" style={{ fontWeight: 600 }}>
        View my island
      </Typography>
      <Space size={1} horizontal />
      <Icon className="fas fa-chevron-right" style={{ fontSize: 12 }} />
    </TouchableOpacity>
  );
};

export default VisitMyIslandButton;
