export function capitalizeAll(s: any) {
  if (typeof s !== "string") return s;
  return s
    .split(" ")
    .map((chunk) => {
      if (chunk.length === 0) {
        return chunk;
      }

      return `${chunk[0].toUpperCase()}${chunk.substring(1)}`;
    })
    .join(" ");
}
