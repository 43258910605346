import { Link, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { colors } from "common/colors";
import { HELP_ARTICLE } from "common/constants";
import { RoundedBackgroundLabel } from "components/RoundedBackgroundTitle";
import RoundedWhiteContainer from "components/RoundedWhiteContainer";
import { Space } from "components/Space";
import ItemItem from "components/TouchableImageList/ItemItem";
import TouchableImageListContainer from "components/TouchableImageList/List";
import VillagerItem from "components/TouchableImageList/VillagerItem";
import { CatalogAndWishlistItemType } from "contract/common";
import { UserProfile } from "contract/view-collection/user";
import { UserConfig } from "hooks/useUserPrivate";
import React from "react";
import { useTranslation } from "react-i18next";
import { filterCatalogAndWishlist } from "utils/filterCatalogAndWishlist";
import { openHelpArticle } from "utils/openHelpArticle";
import BlockListItem from "./BlockListItem";

export interface DetailsSectionProps {
  profile: UserProfile;
  userPrivate: UserConfig;
}

const DetailsSection: React.FC<DetailsSectionProps> = ({
  profile,
  userPrivate,
}) => {
  const { t } = useTranslation();

  const catalog = React.useMemo(
    () =>
      filterCatalogAndWishlist(
        profile.catalogAndWishlist,
        CatalogAndWishlistItemType.CATALOG
      ),
    [profile.catalogAndWishlist]
  );

  const wishlist = React.useMemo(
    () =>
      filterCatalogAndWishlist(
        profile.catalogAndWishlist,
        CatalogAndWishlistItemType.WISHLIST
      ),
    [profile.catalogAndWishlist]
  );

  return (
    <>
      <Alert severity="info">
        Please update the below from the{" "}
        <Link href="/" target="_blank">
          Link App
        </Link>
        .
      </Alert>
      <Space size={2} />
      <RoundedBackgroundLabel
        title="Residents"
        accessoryRight
        accessoryRightOnPress={() => {
          openHelpArticle(HELP_ARTICLE.ProfileResident);
        }}
      />
      <Space size={2} />
      <TouchableImageListContainer fillerSize={45}>
        {profile.residents?.map((villagerId, index) => {
          return <VillagerItem id={villagerId} size={45} key={villagerId} />;
        })}
      </TouchableImageListContainer>
      <Space size={4} />
      <RoundedBackgroundLabel
        title="Catalog"
        accessoryRight
        accessoryRightOnPress={() => {
          openHelpArticle(HELP_ARTICLE.ProfileCatalog);
        }}
      />
      <Space size={2} />
      <TouchableImageListContainer fillerSize={45}>
        {catalog.map((itemId, index) => {
          return <ItemItem id={itemId} size={45} key={index} />;
        })}
      </TouchableImageListContainer>
      <Space size={4} />
      <RoundedBackgroundLabel
        title="Wishlist"
        accessoryRight
        accessoryRightOnPress={() => {
          openHelpArticle(HELP_ARTICLE.ProfileWishlist);
        }}
      />
      <Space size={2} />
      <TouchableImageListContainer fillerSize={45}>
        {wishlist.map((itemId, index) => {
          return <ItemItem id={itemId} size={45} key={index} />;
        })}
      </TouchableImageListContainer>
      <Space size={4} />
      <RoundedBackgroundLabel
        title="Message"
        accessoryRight
        accessoryRightOnPress={() => {
          openHelpArticle(HELP_ARTICLE.ProfileMessage);
        }}
      />
      <Space size={2} />
      <RoundedWhiteContainer>
        {profile.message ? (
          <Typography variant="body2" style={{ whiteSpace: "break-spaces" }}>
            {profile.message}
          </Typography>
        ) : (
          <Typography
            variant="caption"
            style={{
              flex: 1,
              textAlign: "center",
              color: colors["text-hint-color"],
            }}
          >
            No message.
          </Typography>
        )}
      </RoundedWhiteContainer>
      <Space size={4} />
      <RoundedBackgroundLabel
        title="Verification"
        accessoryRight
        accessoryRightOnPress={() => {
          openHelpArticle(HELP_ARTICLE.ProfileVerification);
        }}
      />
      <Space size={2} />
      <RoundedWhiteContainer>
        {profile.verificationLevel ? (
          <Typography
            variant="caption"
            style={{
              flex: 1,
              textAlign: "center",
              color: colors["text-hint-color"],
            }}
          >
            Verified! <br />
            Thanks for joining the effort to build a trusting community 🥳
          </Typography>
        ) : (
          <Typography
            variant="caption"
            style={{
              flex: 1,
              textAlign: "center",
              color: colors["text-hint-color"],
            }}
          >
            Not verified. <br />
            Please use download <Link href="/">Link App</Link> to upload in-game
            passport for verification.
          </Typography>
        )}
      </RoundedWhiteContainer>
      <Space size={4} />
      <RoundedBackgroundLabel
        title="Block List"
        accessoryRight
        accessoryRightOnPress={() => {
          openHelpArticle(HELP_ARTICLE.BlockList);
        }}
      />
      <Space size={2} />
      <RoundedWhiteContainer>
        {userPrivate.blockList.map((uid) => (
          <BlockListItem uid={uid} />
        ))}
        {userPrivate.blockList.length === 0 && (
          <Typography
            variant="caption"
            style={{
              flex: 1,
              textAlign: "center",
              color: colors["text-hint-color"],
            }}
          >
            No blocked user.
          </Typography>
        )}
      </RoundedWhiteContainer>
    </>
  );
};

export default DetailsSection;
