import React from "react";
import { UserProfile } from "contract/view-collection/user";
import Verified1 from "assets/badge/VERIFIED_1.svg";
import { getMembershipDisplay } from "utils/getMembershipDisplay";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";

export type Variant =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "subtitle1"
  | "subtitle2"
  | "body1"
  | "body2"
  | "caption"
  | "button"
  | "overline";

export const iconSizeMap: {
  [k in Variant]: number;
} = {
  h1: 16,
  h2: 16,
  h3: 16,
  h4: 16,
  h5: 16,
  h6: 16,
  subtitle1: 12,
  subtitle2: 12,
  body1: 12,
  body2: 12,
  caption: 12,
  button: 12,
  overline: 12,
};

export const iconSpacingMap: {
  [k in Variant]: number;
} = {
  h1: 8,
  h2: 8,
  h3: 8,
  h4: 8,
  h5: 8,
  h6: 8,
  subtitle1: 4,
  subtitle2: 4,
  body1: 4,
  body2: 4,
  caption: 4,
  button: 4,
  overline: 4,
};

function getIconStyle(category: Variant) {
  const size = iconSizeMap[category];
  const marginLeft = iconSpacingMap[category];

  return {
    marginLeft,
    width: size,
    height: size,
  };
}

export interface DisplayNameProps {
  profile: UserProfile | null | undefined;
  category: Variant;
  displayNameOverwrite?: string;
  displayNameStyleOverwrite?: React.CSSProperties;
  style?: React.CSSProperties;
}

export const DisplayName: React.FC<DisplayNameProps> = ({
  profile,
  category,
  displayNameOverwrite,
  displayNameStyleOverwrite,
  style,
}) => {
  const { t } = useTranslation("DisplayName");

  const { color: membershipColor, BadgeComponent } = getMembershipDisplay(
    profile?.membership
  );

  const iconStyle = getIconStyle(category);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        flex: 1,
        ...style,
      }}
    >
      <Typography
        variant={category}
        style={displayNameStyleOverwrite || { color: membershipColor }}
      >
        {displayNameOverwrite ||
          profile?.displayName ||
          t("unknown.displayName", { ns: "shared" })!}
      </Typography>
      {BadgeComponent && (
        <img src={BadgeComponent} style={iconStyle} alt="badge" />
      )}
      {profile?.verificationLevel && (
        <img src={Verified1} style={iconStyle} alt="verification" />
      )}
    </Box>
  );
};
