import { SpecialCharacterWithStatus } from "contract/data-collection/flight";
import React from "react";
import { useTranslation } from "react-i18next";
import { getSpecialVisitorDetails } from "utils/getSpecialVisitorDetails";
import TouchableImageListItem, { TouchableImageListItemProps } from "./Item";
import QuestionMark from "assets/reaction/QuestionMark.png";

export interface SpecialCharacterItemProps
  extends Omit<TouchableImageListItemProps, "title" | "image"> {
  specialVisitor: SpecialCharacterWithStatus;
}

const SpecialCharacterItem: React.FC<SpecialCharacterItemProps> = ({
  specialVisitor,
  ...props
}) => {
  const { t } = useTranslation("TouchableImageItemResident");
  const specialVisitorDetailed = getSpecialVisitorDetails(t, specialVisitor);

  return (
    <TouchableImageListItem
      title={specialVisitorDetailed?.name || "unknown"}
      image={specialVisitorDetailed?.source || QuestionMark}
      statusImage={specialVisitorDetailed?.statusImageSource}
      {...props}
    />
  );
};

export default SpecialCharacterItem;
