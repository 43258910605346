import React from "react";
import { useSwitchState } from "hooks/useSwitchState";
import { QueueType } from "contract/common";

export function useJoinPrivateFlightModalState() {
  const modal = useSwitchState();
  const [flightQueueType, setFlightQueueType] = React.useState<
    QueueType | undefined
  >(undefined);
  const _open = (queueType: QueueType) => {
    setFlightQueueType(queueType);
    modal.turnOn();
  };
  const _close = () => {
    setFlightQueueType(undefined);
    modal.turnOff();
  };

  return {
    flightQueueType,
    modal: {
      visible: modal.on,
      open: _open,
      close: _close,
    },
  };
}
