import { Fruit } from "contract/common";

const Apple = require("assets/item/apple.png");
const Cherry = require("assets/item/cherry.png");
const Orange = require("assets/item/orange.png");
const Peach = require("assets/item/peach.png");
const Pear = require("assets/item/pear.png");

export function getFruitImage(f: Fruit): any {
  switch (f) {
    case Fruit.Apple:
      return Apple;

    case Fruit.Cherry:
      return Cherry;

    case Fruit.Orange:
      return Orange;

    case Fruit.Peach:
      return Peach;

    case Fruit.Pear:
      return Pear;
  }
}
