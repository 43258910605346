import { ResidentWithStatus } from "contract/data-collection/flight";
import { capitalizeAll } from "./capitaliseAll";
import { Gender, Personality } from "assets/acdb/villagers";
import { Villager } from "hooks/useVillager";
import { ResidentStatus } from "contract/common";

export function getResidentStatusDesc(
  t: any,
  status: ResidentWithStatus["status"]
) {
  switch (status) {
    case "DIY":
      return t(`characterStatus.diying`, { ns: "shared" });
    case "MOVING_OUT":
      return t(`characterStatus.movingOut`, { ns: "shared" });
    default:
      return undefined;
  }
}

export function getResidentStatusImageSource(
  status: ResidentWithStatus["status"] | undefined
) {
  switch (status) {
    case "DIY":
      return require("assets/item/recipe.png");
    case "MOVING_OUT":
      return require("assets/item/delivery.png");
    default:
      return undefined;
  }
}

export interface ResidentDetailed {
  id: string;
  name: string;
  source: string;
  birthday: string;
  species: string;
  gender: Gender;
  personality: Personality;
  favoriteSaying: string;
  statusDesc?: string;
  statusImageSource?: string;
}

export function getResidentDetails(
  t: any,
  villager: Villager,
  status: ResidentStatus | undefined
): ResidentDetailed | undefined {
  return {
    id: villager.id,
    name: capitalizeAll(villager.name),
    source: villager.source,
    species: villager.species,
    gender: villager.gender,
    personality: villager.personality,
    favoriteSaying: villager.favoriteSaying,
    statusDesc: getResidentStatusDesc(t, status),
    statusImageSource: getResidentStatusImageSource(status),
    birthday: villager.birthday,
  };
}
