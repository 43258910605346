import React from "react";
import { Box, BoxProps } from "@material-ui/core";

export interface SpaceProps {
  size: number;
  horizontal?: boolean;
  style?: BoxProps["style"];
}

export function Space({ size, horizontal = false, style = {} }: SpaceProps) {
  if (horizontal) {
    return <Box style={{ height: "100%", width: size * 8, ...style }}></Box>;
  }
  return <Box style={{ width: "100%", height: size * 8, ...style }}></Box>;
}
