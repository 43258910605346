import React from "react";

import { Chip } from "components/Chip";
import { DisplayName } from "components/DisplayName";
import PriceTag from "components/PriceTag";
import { Space } from "components/Space";

import { FlightPassengerType } from "contract/common";
import {
  Typography,
  Box,
  Paper,
  Avatar,
  Icon,
  Divider,
} from "@material-ui/core";
import { FlightPublic } from "contract/view-collection/flight";
import { useUserProfile } from "hooks/useUserProfile";
import { useTranslation } from "react-i18next";

export interface TerminalTurnipsCellProps {
  flight: FlightPublic;
  onPress: () => void;
}

export const TerminalTurnipsCell: React.FC<TerminalTurnipsCellProps> = ({
  flight,
  onPress,
}) => {
  const { t } = useTranslation("FlightListCellTurnips");

  const profile = useUserProfile(flight.flightCreatorId);

  return (
    <Paper
      style={{
        marginBottom: 8,
        padding: 16,
      }}
      onClick={onPress}
      elevation={0}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        {flight.allowedPassengerType === FlightPassengerType.TRUSTED && (
          <Chip
            size={10}
            icon="fas fa-user-shield"
            title={t("verifiedOnlyLabel")}
            style={{ marginRight: 8 }}
          />
        )}
        {flight.requirePassword && (
          <Chip
            size={10}
            icon="fas fa-key"
            title=""
            style={{ marginRight: 8 }}
          />
        )}
        {flight.queueLocked && (
          <Chip
            size={10}
            icon="fas fa-lock"
            title=""
            style={{ marginRight: 8 }}
          />
        )}
        <Chip
          size={10}
          icon="fas fa-user"
          title={({ color, size }) => (
            <>
              <Typography
                style={{
                  color,
                  fontSize: 12,
                  fontWeight: 600,
                }}
              >
                {flight.queueSize || 0}
              </Typography>
              {!!flight.maxQueueSize && (
                <Typography
                  style={{
                    color,
                    fontSize: size,
                  }}
                >
                  {" "}
                  / {flight.maxQueueSize}
                </Typography>
              )}
            </>
          )}
        ></Chip>
      </Box>
      <Space size={0.5} />
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Avatar
          src={profile?.photoURL || require("assets/reaction/Confident.png")}
          style={{
            marginRight: 8,
          }}
        />
        <Box style={{ flex: 1 }}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: 4,
            }}
          >
            <DisplayName profile={profile} category="h6" />
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Icon
              className="fas fa-map-marker-alt"
              style={{
                fontSize: 10,
                marginRight: 4,
                color: "#8F8F4C",
              }}
            />
            <Typography variant="caption" style={{ fontSize: 10 }}>
              {profile?.islandName ||
                t("unknown.islandName", { ns: "shared" })!}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box>
            <Typography
              variant="h5"
              style={{ color: "#6B5C43", fontWeight: "bold" }}
            >
              {flight.price}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Space size={2} />
      <Divider />
      <Space size={1} />
      {flight.entryFees ? (
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle2" style={{ marginRight: 4 }}>
            {t("entryFeeLabel")!}
          </Typography>
          <PriceTag.List style={{ padding: 0 }}>
            {flight.entryFees?.map((entryFee) => (
              <PriceTag.Item
                key={entryFee.id}
                id={entryFee.id}
                count={entryFee.count}
              />
            ))}
          </PriceTag.List>
        </Box>
      ) : (
        <Box>
          <Typography
            variant="caption"
            style={{ textAlign: "center", color: "#8F8F4C" }}
          >
            {t("noEntryFeeLabel")!}
          </Typography>
        </Box>
      )}
    </Paper>
  );
};
