import React from "react";
import { FlightApi } from "api/flight";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

export function useCloseFlight() {
  const { t } = useTranslation("useCloseFlight");
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [flightClosing, setFlightClosing] = React.useState(false);

  const flightClose = React.useCallback(() => {
    setFlightClosing(true);

    FlightApi.close()
      .then(() => {
        enqueueSnackbar(t("success.end"), { variant: "success" });
        setFlightClosing(false);

        history.push("/airport");
      })
      .catch((error) => {
        // crashlytics().recordError(error);
        enqueueSnackbar(
          t("error.end", {
            msg: error.message,
          }),
          { variant: "error" }
        );
        setFlightClosing(false);
      });
  }, []);

  return {
    flightClosing,
    flightClose,
  };
}
