import { Typography, IconButton, Icon, Link } from "@material-ui/core";
import { colors } from "common/colors";
import { HELP_ARTICLE } from "common/constants";
import Row from "components/Row";
import { Space } from "components/Space";
import { FlightPassengerType } from "contract/common";
import { FlightForm } from "contract/data-collection/flight";
import { FlightPublic } from "contract/view-collection/flight";
import { AuthContext } from "modules/auth/AuthContext";
import { ProfileContext } from "modules/auth/ProfileContext";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatEndTime } from "utils/formatEndTime";
import { FlightInfo } from "utils/getFlightInfo";
import { ChipWithCaption } from "../ChipWithCaption";

export interface QueueInfoSectionProps {
  flight: FlightPublic;
  flightInfo: FlightInfo;
  flightHost: FlightForm | null | undefined;
  onPressResetPassword: () => void;
}

const QueueInfoSection: React.FC<QueueInfoSectionProps> = ({
  flight,
  flightInfo,
  flightHost,
  onPressResetPassword,
}) => {
  const { t } = useTranslation("QueueSection");
  const profile = React.useContext(ProfileContext);

  return (
    <>
      <ChipWithCaption
        icon="stopwatch"
        caption={
          t("endsAtLabel", { time: formatEndTime(flight.planToCloseAt) })!
        }
      />
      <Space size={2} />
      <ChipWithCaption
        icon="users"
        caption={t("maxAllowedLabel", { number: flight.maxAllowed })!}
      />
      <Space size={2} />
      {!!flight.maxStayTime && (
        <>
          <ChipWithCaption
            icon="user-clock"
            caption={t("maxStayTimeLabel", { time: flight.maxStayTime })!}
          />
          <Space size={2} />
        </>
      )}
      {flight.allowedPassengerType === FlightPassengerType.TRUSTED && (
        <>
          <ChipWithCaption
            icon="user-shield"
            caption={
              <span>
                {t("verifiedOnlyFlightLabel")!}{" "}
                {profile?.verificationLevel === undefined && (
                  <Link
                    href={`/news/${HELP_ARTICLE.ProfileVerification}`}
                    target="_blank"
                  >
                    What is Verified User?
                  </Link>
                )}
              </span>
            }
          />
          <Space size={2} />
        </>
      )}
      {flight.requirePassword && (
        <>
          <ChipWithCaption
            icon="key"
            caption={
              flightInfo.isHost ? (
                <Row style={{ alignItems: "center" }}>
                  <Typography
                    variant="caption"
                    style={{
                      color: colors["txt-color-brown-500"],
                      fontWeight: 600,
                    }}
                  >
                    {t("privateFlightLabelHost")!}
                  </Typography>
                  <Space size={1} horizontal />
                  <Typography
                    variant="h6"
                    style={{
                      color: colors["txt-color-blue-500"],
                      lineHeight: 0,
                    }}
                  >
                    {" "}
                    {flightHost?.password || "-"}
                  </Typography>
                  <Space size={1} horizontal />
                  <IconButton
                    aria-label="redo"
                    onClick={onPressResetPassword}
                    style={{ padding: 0 }}
                  >
                    <Icon className="fas fa-redo" style={{ fontSize: 10 }} />
                  </IconButton>
                </Row>
              ) : (
                <Typography
                  variant="caption"
                  style={{
                    color: colors["txt-color-brown-500"],
                    fontWeight: 600,
                  }}
                >
                  {t("privateFlightLabel")!}
                </Typography>
              )
            }
          />
          <Space size={2} />
        </>
      )}
    </>
  );
};

export default QueueInfoSection;
