import React from "react";
import { Helmet } from "react-helmet";
import { Announcement } from "../components/Announcement";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    color: "#6b5c43",
    "& h1": {
      color: "#008378",
    },
    "& h2": {
      color: "#008378",
    },
    "& h3": {
      color: "#008378",
    },
    "& h4": {
      color: "#008378",
    },
    "& h5": {
      color: "#008378",
    },
    "& h6": {
      color: "#008378",
    },
    "& p": {
      color: "#6b5c43",
    },
    "& img": {
      maxWidth: "80%",
      maxHeight: 240,
      display: "block",
      margin: "32px auto",
    },
    "& a": {
      color: "#6b5c43",
      fontWeight: "800",
      textDecoration: "none",
      fontStyle: "italic",
    },
    "& a:hover": {
      textDecoration: "underline",
    },
  },
  meta: {
    fontWeight: "bold",
    color: "#8f9bb3 !important",
  },
  image: {
    objectFit: "contain",
    height: "240px  !important",
    width: "auto !important",
    maxHeight: "unset !important",
    maxWidth: "100% !important",
    display: "block",
    margin: "0 auto !important",
  },
  link: {
    color: "#6B5C43",
    fontSize: "bold",
  },
});

export const NewsListPage: React.FC = () => {
  const styles = useStyles();

  return (
    <>
      <Helmet>
        <title>News - Link - The ACNH Community</title>
      </Helmet>
      <Announcement />
      <div className={styles.container}>
        <h2>News</h2>
        <p>
          Take some time to read the new articles and get familiar with every
          detail of the App and the Community!
        </p>
        <h3>In-App features</h3>
        <ul>
          <li>
            <a href="/news/airport">Airport</a>
          </li>
          <li>
            <a href="/news/flight-queue">Queue</a>
          </li>
          <li>
            <a href="/news/flight-chat">Chat</a>
          </li>
          <li>
            <a href="/news/flight-message">Flight Message</a>
          </li>
          <li>
            <a href="/news/flight-entry-fee">Entry Fee</a>
          </li>
        </ul>
        <ul>
          <li>
            <a href="/news/residents">Residents</a>
          </li>
          <li>
            <a href="/news/special-visitors">Special Visitors</a>
          </li>
          <li>
            <a href="/news/search-villager">Search Villager</a>
          </li>
          <li>
            <a href="/news/catalog-and-wishlist">Catalog and Wish List</a>
          </li>
          <li>
            <a href="/news/search-item">Search Item</a>
          </li>
        </ul>
        <ul>
          <li>
            <a href="/news/verification">Verification</a>
          </li>
          <li>
            <a href="/news/report">Report</a>
          </li>
          <li>
            <a href="/news/block-list">Block List</a>
          </li>
        </ul>
        <h3>About us</h3>
        <ul>
          <li>
            <a href="/news/community-standards">Community Standards</a>
          </li>
          <li>
            <a href="/news/linc-inc">Link Inc.</a>
          </li>
        </ul>
      </div>
    </>
  );
};
