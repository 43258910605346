import React from "react";
import { Box, Typography, TypographyProps } from "@material-ui/core";

export interface SectionTitleProps {
  title: string;
  variant: TypographyProps["variant"];
  underscoreColor?: string;
  containerStyle?: React.CSSProperties;
}

export const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  variant,
  underscoreColor = "#f1e26f",
  containerStyle,
}) => {
  return (
    <Box
      style={{
        alignSelf: "flex-start",
        position: "relative",
        paddingBottom: 4,
        ...containerStyle,
      }}
    >
      <Box
        style={{
          position: "relative",
          paddingLeft: 8,
          paddingRight: 8,
        }}
      >
        <Box
          style={{
            position: "absolute",
            bottom: -4,
            left: 0,
            right: 0,
            height: "56%",
            backgroundColor: underscoreColor,
            borderRadius: 8,
          }}
        ></Box>
        <Typography
          variant={variant}
          style={{ color: "#6B5C43", position: "relative", fontWeight: 800 }}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
};
