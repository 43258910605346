import { Box, BoxProps, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = (disabled: boolean, activeOpacity: number, active: boolean) =>
  makeStyles((theme) => ({
    container: {
      ...(active
        ? {
            cursor: disabled ? "not-allowed" : "pointer",
            opacity: disabled ? activeOpacity : 1,
            "&:active": {
              opacity: activeOpacity,
            },
            "&:hover": {
              opacity: activeOpacity,
            },
          }
        : {
            opacity: 1,
          }),
      position: "relative",
      textDecoration: "none",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
  }));

export interface TouchableOpacityProps {
  disabled?: boolean;
  onPress?: BoxProps["onClick"];
  activeOpacity?: number;
  style?: BoxProps["style"];
}

const TouchableOpacity: React.FC<TouchableOpacityProps> = ({
  children,
  disabled = false,
  activeOpacity = 0.8,
  onPress,
  style,
}) => {
  const styles = useStyles(disabled, activeOpacity, !!onPress)();

  return (
    <Box className={styles.container} style={style} onClick={onPress}>
      {children}
    </Box>
  );
};

export default TouchableOpacity;
