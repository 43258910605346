import firebase from "common/firebase";
import { PERSIST_KEY } from "./sendSignInLink";

export function signInWithEmailLink() {
  // Confirm the link is a sign-in with email link.
  if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    let email = window.localStorage.getItem(PERSIST_KEY);
    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      email = window.prompt("Please provide your email for confirmation");
    }

    // The client SDK will parse the code from the link for you.
    return firebase
      .auth()
      .signInWithEmailLink(email!, window.location.href)
      .then(function (result) {
        // Clear email from storage.
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
        window.location.replace("/profile");
      })
      .finally(() => {
        window.localStorage.removeItem(PERSIST_KEY);
      });
  }

  return Promise.reject(new Error("No email link."));
}
