import { Box, BoxProps } from "@material-ui/core";
import { colors } from "common/colors";
import React from "react";
import { Speech, SpeechProps } from "./Speech";

export interface CharacterWithSpeeachProps {
  character: any;
  characterName: string;
  height: number;
  text: SpeechProps["text"];
  width: number;
  speechContainerWidth?: number | string;

  bubbleTxtColor?: keyof typeof colors;
  bubbleBgColor?: keyof typeof colors;

  containerHeight?: number;

  style?: BoxProps["style"];
}

export function CharacterWithSpeeach({
  character,
  characterName,
  text,
  height,
  width,
  speechContainerWidth,

  bubbleTxtColor,
  bubbleBgColor,

  containerHeight = 240,
  style,
}: CharacterWithSpeeachProps) {
  return (
    <Box
      style={{
        flex: 1,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-end",
        position: "relative",
        height: containerHeight,
        ...style,
      }}
    >
      <Speech
        characterName={characterName}
        text={text}
        speechContainerWidth={speechContainerWidth}
        bubbleBgColor={bubbleBgColor}
        bubbleTxtColor={bubbleTxtColor}
        style={{
          position: "absolute",
          bottom: -32,
          left: -32,
          right: -32,
        }}
      />
      <img
        style={{
          position: "absolute",
          top: 0,
          right: -16,
          width,
          height,
          objectFit: "contain",
        }}
        src={character}
      />
    </Box>
  );
}
