import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

const themes = responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: {
        main: "#6B5C43",
      },
      secondary: {
        main: "#ec7175",
      },
      info: {
        main: "#008077",
      },
      background: {
        default: "#f8f8f0",
      },
      text: {
        primary: "#6B5C43",
      },
    },
    typography: {
      fontFamily: [
        "Zilla Slab",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  })
);

export default themes;
