import React, { ReactNode } from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

const useStyles = (title: string) =>
  makeStyles((theme) => ({
    root: {
      color: theme.palette.text.primary,
      textDecoration: "none",
      borderRadius: 4,
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: "transparent",
      "&:hover": {
        fontWeight: 800,
      },
      "&::after": {
        display: "block",
        content: `"${title}"`,
        fontWeight: 800,
        height: 0,
        overflow: "hidden",
        visibility: "hidden",
      },
      textAlign: "center",
    },
    active: {
      borderColor: theme.palette.text.primary,
      backgroundColor: theme.palette.text.primary,
      color: "white",
      fontWeight: 800,
    },
    semi: {
      borderColor: theme.palette.text.primary,
      borderStyle: "dashed",
      fontWeight: 800,
    },
  }));

export interface NavLinkProps {
  title: string;
  link: string;
  active?: boolean | "semi";
}

export const NavLink: React.FC<NavLinkProps> = ({ title, link, active }) => {
  const classes = useStyles(title)();

  return (
    <Box
      component="a"
      // @ts-ignore
      href={link}
      py={0.5}
      px={1}
      fontSize={12}
      className={classnames(
        classes.root,
        active === true
          ? classes.active
          : active === "semi"
          ? classes.semi
          : undefined
      )}
    >
      {title}
    </Box>
  );
};
