import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const PostItemInactiveOverlay = () => {
  const { t } = useTranslation("PostItemInactiveOverlay");

  return null;

  return (
    <Box
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "white",
        opacity: 0.7,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="subtitle2">{t("inactive")!}</Typography>
    </Box>
  );
};

export default PostItemInactiveOverlay;
