import React from "react";
import { FlightQueue } from "contract/view-collection/flight";
import { RoundedBackgroundLabel } from "components/RoundedBackgroundTitle";
import { useTranslation } from "react-i18next";
import { QueueType } from "contract/common";
import { Space } from "components/Space";
import { QueueListItem } from "./QueueListItem";
import { isBefore } from "date-fns";
import { Dialog, DialogContent, Box, Typography } from "@material-ui/core";
import { FixedSizeList as List } from "react-window";
import { colors } from "common/colors";

export function useQueueDetailModal() {
  const [state, setState] = React.useState<QueueType | undefined>(undefined);

  const open = React.useCallback((type: QueueType) => {
    setState(type);
  }, []);

  const close = React.useCallback(() => {
    setState(undefined);
  }, []);

  return {
    visible: state,
    close,
    open,
  };
}

export interface QueueDetailModalProps {
  visible: QueueType | undefined;
  onRequestClose: () => void;
  queue: FlightQueue["standard"];
  visitingQueue: FlightQueue["visiting"];
  isHost: boolean;
}

export const QueueDetailModal: React.FC<QueueDetailModalProps> = ({
  visible,
  onRequestClose,
  queue,
  visitingQueue,
  isHost,
}) => {
  const { t } = useTranslation("QueueDetailModal");

  const mergedQueue = React.useMemo(() => {
    const sortedVisitingQueue = Object.keys(visitingQueue)
      .filter((k) => visitingQueue[k].queueType === visible)
      .sort((a, b) => {
        return isBefore(visitingQueue[a].joinedAt, visitingQueue[b].joinedAt)
          ? -1
          : 1;
      });
    const sortedQueue = Object.keys(queue).sort((a, b) => {
      return isBefore(queue[a].joinedAt, queue[b].joinedAt) ? -1 : 1;
    });
    return [
      ...sortedVisitingQueue.map((k) => ({
        ...visitingQueue[k],
        uid: k,
        id: k,
      })),
      ...sortedQueue.map((k) => ({
        ...queue[k],
        uid: k,
        id: k,
      })),
    ];
  }, [queue, visitingQueue, visible]);

  return (
    <Dialog
      open={!!visible}
      fullWidth={true}
      maxWidth="xs"
      onClose={onRequestClose}
    >
      <DialogContent>
        <Box style={{ padding: "0 8px" }}>
          <RoundedBackgroundLabel
            title={t(
              visible === QueueType.STANDARD
                ? "standardQueueLabel"
                : "firstClassQueueLabel"
            )}
          />
        </Box>
        <Space size={2} />
        {mergedQueue.length !== 0 && (
          <List
            height={46 * 8}
            itemCount={mergedQueue.length}
            itemSize={46}
            width="100%"
          >
            {({ index, style }) => {
              const passenger = mergedQueue[index];
              return (
                <QueueListItem
                  key={passenger.uid}
                  index={index}
                  passenger={passenger}
                  passengerUid={passenger.uid}
                  visiting={!!visitingQueue[passenger.uid]}
                  isHost={isHost}
                />
              );
            }}
          </List>
        )}
        {mergedQueue.length === 0 && (
          <Typography
            variant="caption"
            style={{
              display: "block",
              textAlign: "center",
              color: colors["text-hint-color"],
            }}
          >
            {t("emptyQueueLabel")!}
          </Typography>
        )}
        <Space size={1} />
      </DialogContent>
    </Dialog>
  );
};
