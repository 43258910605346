import { useMediaQuery as _useMediaQuery } from "@material-ui/core";

export function useMediaQuery() {
  const isMobile = _useMediaQuery("(max-width:576px)");
  const isTablet = _useMediaQuery("(min-width:576px) and (max-width:768px)");
  const isScreen = _useMediaQuery("(min-width:768px)");

  return {
    isMobile,
    isTablet,
    isScreen,
  };
}
