import { Items, Source, Variant } from "assets/acdb/items";
import acdb from "modules/acdb";
import React from "react";
import { capitalizeAll } from "utils/capitaliseAll";
import { getItemImage } from "utils/getItemImage";

export function getItemVariantById(
  item: Items,
  id: string
): Variant | undefined {
  const variant = item.variants.find((i) => i.uniqueEntryId === id);

  if (!variant) return undefined;

  return variant;
}

export interface Item {
  id: string;
  name: string;
  nameWithVariation: string;
  image?: string;
  buy: number;
  sell: number;
  variants: Variant[];
  diy: boolean;
  source: Source[];
}

export default function useItem(id: string): Item | undefined {
  const [item, setItem] = React.useState<Item | undefined>(undefined);

  React.useEffect(() => {
    acdb.getItemById(id).then((item) => {
      const variant = getItemVariantById(item, id);

      if (!variant) return;

      const image = getItemImage(variant);

      setItem({
        id,
        name: item.name,
        nameWithVariation: `${capitalizeAll(item.name)}${
          variant.variation ? ` (${capitalizeAll(variant.variation)})` : ""
        }`,
        image: image,
        buy: variant.buy,
        sell: variant.sell || -1,
        variants: item.variants.filter((i) => i.uniqueEntryId !== id),
        diy: item.diy || false,
        source: variant.source,
      });
    });
  }, [id]);

  return item;
}
