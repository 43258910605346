import { Ref } from "api/ref";
import { PostReply } from "contract/data-collection/post";
import React from "react";
import { getFirebaseDateOrNow } from "utils/getFirebaseDateOrNow";

export function getPostReply(
  postId: string,
  snapshot: firebase.firestore.DocumentSnapshot
): PostReply {
  return {
    id: snapshot.id,
    postId: postId,
    uid: snapshot.get("uid"),
    text: snapshot.get("text"),
    replyTo: (snapshot.get("replyTo") as string) || undefined,
    createdAt: getFirebaseDateOrNow(snapshot.get("createdAt")),
  };
}

export default function usePostReply(
  postId: string,
  replyId: string | undefined
) {
  const [state, setState] = React.useState<PostReply | null | undefined>();

  React.useEffect(() => {
    if (!replyId) return;

    Ref.postReply(postId, replyId)
      .get()
      .then((snapshot) => {
        if (!snapshot || !snapshot.exists) {
          setState(null);
          return;
        }

        setState(getPostReply(postId, snapshot));
      });
  }, [postId, replyId]);

  return state;
}
