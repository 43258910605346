import React from "react";
import { Helmet } from "react-helmet";
import { Announcement } from "../../components/Announcement";

import { Space } from "../../components/Space";
import { Hero } from "./components/Hero/Hero";

import { Confetti } from "../../components/Confetti";
import { IWannaFly } from "./components/IWannaFly/IWannaFly";
import { IWantVisitors } from "./components/IWantVisitors/IWantVisitors";

export const AirportPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Airport - Link - The ACNH Community</title>
        <meta
          name="apple-itunes-app"
          content="app-id=3Y3ND925XU, app-argument=https://acnh.link/airport"
        />
      </Helmet>
      <Announcement />
      <Space size={2} />
      <Hero />
      <Space size={6} />
      <Confetti />
      <Space size={6} />
      <IWannaFly />
      <Space size={4} />
      <IWantVisitors />
    </>
  );
};
