import { Button } from "@material-ui/core";
import { FlightApi } from "api/flight";
import { QueueApi } from "api/queue";
import { HELP_ARTICLE } from "common/constants";
import { InProgressModal } from "components/InProgressModal";
import { RoundedBackgroundLabel } from "components/RoundedBackgroundTitle";
import Row from "components/Row";
import { Space } from "components/Space";
import { QueueType } from "contract/common";
import { FlightPublic, FlightQueue } from "contract/view-collection/flight";
import useFlightFormData from "hooks/useFlightFormData";
import { useSwitchState } from "hooks/useSwitchState";
import { useSnackbar } from "notistack";
import { useCloseFlight } from "pages/FlightForm/hooks/useCloseFlight";
import React from "react";
import { useTranslation } from "react-i18next";
import { FlightInfo } from "utils/getFlightInfo";
import { openHelpArticle } from "utils/openHelpArticle";
import CloseFlightDialog from "./CloseFlightModal";
import FirstClassQueue from "./FirstClassQueue";
import JoinPrivateFlightModal from "./JoinPrivateFlightModal";
import { QueueDetailModal, useQueueDetailModal } from "./QueueDetailModal";
import QueueInfoSection from "./QueueInfoSection";
import StandardQueue from "./StandardQueue";
import { useJoinPrivateFlightModalState } from "./useJoinPrivateFlightModalState";

function useQueueActions(flight: FlightPublic, flightInfo: FlightInfo) {
  const { t } = useTranslation("QueueSection");
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const queueDetailModal = useQueueDetailModal();
  const joinPrivateFlightModalState = useJoinPrivateFlightModalState();
  const closeFlightModal = useSwitchState();

  const flightHost = useFlightFormData(
    flightInfo.isHost ? flight.flightCreatorId : undefined
  );

  const { flightClose, flightClosing } = useCloseFlight();

  const onPressJoin = (queueType: QueueType) => {
    setLoading(true);
    QueueApi.join({
      flightCreatorId: flight.flightCreatorId,
      queueType,
    })
      .then(() => {
        enqueueSnackbar(t("success.join")!, { variant: "success" });
        setLoading(false);
      })
      .catch((e) => {
        enqueueSnackbar(t("error.join", { msg: e.message })!, {
          variant: "error",
        });
        setLoading(false);
      });
  };

  const onPressJoinPrivate = (password: string) => {
    if (!joinPrivateFlightModalState.flightQueueType) return; // Shouldn't happen IRL.

    setLoading(true);
    QueueApi.joinPrivate({
      flightCreatorId: flight.flightCreatorId,
      queueType: joinPrivateFlightModalState.flightQueueType!,
      password,
    })
      .then(() => {
        enqueueSnackbar(t("success.join")!, { variant: "success" });
        setLoading(false);
        joinPrivateFlightModalState.modal.close();
      })
      .catch((e) => {
        enqueueSnackbar(t("error.join", { msg: e.message })!, {
          variant: "error",
        });
        setLoading(false);
        joinPrivateFlightModalState.modal.close();
      });
  };

  const onPressLeave = () => {
    setLoading(true);
    QueueApi.leave({
      flightCreatorId: flight.flightCreatorId,
    })
      .then(() => {
        enqueueSnackbar(t("success.leave")!, { variant: "success" });
        setLoading(false);
      })
      .catch((e) => {
        enqueueSnackbar(t("error.leave", { msg: e.message })!, {
          variant: "error",
        });
        setLoading(false);
      });
  };

  const onPressFinish = () => {
    setLoading(true);
    QueueApi.finish({
      flightCreatorId: flight.flightCreatorId,
    })
      .then(() => {
        enqueueSnackbar(t("success.finish")!, { variant: "success" });
        setLoading(false);
      })
      .catch((e) => {
        enqueueSnackbar(t("error.finish", { msg: e.message })!, {
          variant: "error",
        });
        setLoading(false);
      });
  };

  const onPressLock = () => {
    setLoading(true);
    QueueApi.lock()
      .then(() => {
        enqueueSnackbar(t("success.lock")!, { variant: "success" });
        setLoading(false);
      })
      .catch((e) => {
        enqueueSnackbar(t("error.lock", { msg: e.message })!, {
          variant: "error",
        });
        setLoading(false);
      });
  };

  const onPressUnlock = () => {
    setLoading(true);
    QueueApi.unlock()
      .then(() => {
        enqueueSnackbar(t("success.unlock")!, { variant: "success" });
        setLoading(false);
      })
      .catch((e) => {
        enqueueSnackbar(t("error.unlock", { msg: e.message })!, {
          variant: "error",
        });
        setLoading(false);
      });
  };

  const onPressResetPassword = () => {
    setLoading(true);
    FlightApi.resetPassword()
      .then(() => {
        enqueueSnackbar(t("success.resetPassword")!, { variant: "success" });
        setLoading(false);
      })
      .catch((e) => {
        enqueueSnackbar(t("error.resetPassword", { msg: e.message })!, {
          variant: "error",
        });
        setLoading(false);
      });
  };

  return {
    flightHost,
    loading,
    setLoading,
    flightClose,
    flightClosing,
    onPressJoin,
    onPressJoinPrivate,
    onPressLeave,
    onPressLock,
    onPressUnlock,
    onPressFinish,
    onPressResetPassword,
    queueDetailModal,
    joinPrivateFlightModalState,
    closeFlightModal,
  };
}

export interface QueueSectionProps {
  flight: FlightPublic;
  flightInfo: FlightInfo;
  flightQueue: FlightQueue | undefined;
  disabled?: boolean;
}

const QueueSection: React.FC<QueueSectionProps> = ({
  flight,
  flightInfo,
  flightQueue,
  disabled = true,
}) => {
  const { t } = useTranslation("QueueSection");

  const {
    flightHost,
    loading,
    setLoading,
    flightClose,
    flightClosing,
    onPressJoin,
    onPressJoinPrivate,
    onPressLeave,
    onPressLock,
    onPressUnlock,
    onPressFinish,
    onPressResetPassword,
    queueDetailModal,
    joinPrivateFlightModalState,
    closeFlightModal,
  } = useQueueActions(flight, flightInfo);

  return (
    <>
      <RoundedBackgroundLabel
        title="Queue"
        accessoryRight
        accessoryRightOnPress={() => {
          openHelpArticle(HELP_ARTICLE.FlightQueue);
        }}
      />
      <Space size={2} />
      <QueueInfoSection
        flight={flight}
        flightInfo={flightInfo}
        flightHost={flightHost}
        onPressResetPassword={onPressResetPassword}
      />
      <StandardQueue
        flight={flight}
        flightInfo={flightInfo}
        loading={loading}
        openQueueDetailModal={() => queueDetailModal.open(QueueType.STANDARD)}
        openJoinPrivateFlightModal={() =>
          joinPrivateFlightModalState.modal.open(QueueType.STANDARD)
        }
        onPressJoin={() => {
          onPressJoin(QueueType.STANDARD);
        }}
        onPressLeave={onPressLeave}
        onPressFinish={onPressFinish}
        disabled={disabled}
      />
      <FirstClassQueue
        flight={flight}
        flightInfo={flightInfo}
        loading={loading}
        openQueueDetailModal={() =>
          queueDetailModal.open(QueueType.FIRST_CLASS)
        }
        openJoinPrivateFlightModal={() =>
          joinPrivateFlightModalState.modal.open(QueueType.FIRST_CLASS)
        }
        onPressJoin={() => {
          onPressJoin(QueueType.FIRST_CLASS);
        }}
        onPressLeave={onPressLeave}
        onPressFinish={onPressFinish}
        disabled={disabled}
      />

      {flightInfo.isHost && (
        <>
          <Space size={2} />
          <Row center>
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={() => {
                window.open("/flight/manage", "_blank");
              }}
              disabled={loading}
            >
              {t("updateFlightLabel")!}
            </Button>
            <Space horizontal={true} size={2} />
            <Button
              color="secondary"
              size="small"
              variant="contained"
              onClick={closeFlightModal.turnOn}
              disabled={loading || flightClosing}
            >
              {t("endFlightLabel")!}
            </Button>
            <Space horizontal={true} size={2} />
            {flight.queueLocked ? (
              <Button
                color="secondary"
                size="small"
                variant="contained"
                disabled={loading}
                onClick={() => onPressUnlock()}
              >
                {t("unlockQueueLabel")!}
              </Button>
            ) : (
              <Button
                color="secondary"
                size="small"
                variant="contained"
                disabled={loading}
                onClick={() => onPressLock()}
              >
                {t("lockQueueLabel")!}
              </Button>
            )}
          </Row>
        </>
      )}
      {!!flightQueue && (
        <QueueDetailModal
          visible={queueDetailModal.visible}
          onRequestClose={queueDetailModal.close}
          queue={
            queueDetailModal.visible === QueueType.STANDARD
              ? flightQueue.standard
              : flightQueue.firstClass
          }
          visitingQueue={flightQueue.visiting}
          isHost={flightInfo.isHost}
        />
      )}
      {flight.requirePassword && (
        <JoinPrivateFlightModal
          loading={loading}
          visible={joinPrivateFlightModalState.modal.visible}
          onRequestClose={joinPrivateFlightModalState.modal.close}
          onPressJoinPrivate={onPressJoinPrivate}
        />
      )}
      {flightInfo.isHost && (
        <CloseFlightDialog
          loading={flightClosing}
          visible={closeFlightModal.on}
          onRequestClose={closeFlightModal.turnOff}
          onPressConfirm={flightClose}
        />
      )}
      <InProgressModal visible={loading} />
    </>
  );
};

export default QueueSection;
