import Filter from "bad-words";

export const filter = new Filter();
filter.removeWords("lesbian");

export function clean(s: string) {
  try {
    return filter.clean(s);
  } catch (error) {
    return s;
  }
}
