import React from "react";
import { AuthContext } from "modules/auth/AuthContext";
import { useFlight } from "hooks/useFlight";
import { ProfileContext } from "modules/auth/ProfileContext";
import { useTranslation } from "react-i18next";
import { Terminal } from "contract/common";
import { FlightPublic } from "contract/view-collection/flight";
import { ActionButtonItem } from "components/ActionButton/Item";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";

export function getEyebrow(t: any, flight: FlightPublic | null | undefined) {
  if (!flight) return "-";

  let eyebrow = `${t(`terminal.${flight.terminal!}`, {
    ns: "shared",
  })}`;

  if (flight.terminal === Terminal.TURNIPS) {
    eyebrow += ` (${flight.price} ${t("miscellaneous.Bells", {
      ns: "shared",
    })})`;
  }

  return eyebrow.toUpperCase();
}

export interface AirportCurrentFlightProps {}

export const AirportCurrentFlight: React.FC<AirportCurrentFlightProps> = () => {
  const { t } = useTranslation("AirportCurrentFlight");
  const history = useHistory();

  const user = React.useContext(AuthContext);
  const userProfile = React.useContext(ProfileContext);
  const flight = useFlight(user?.uid);

  const eyebrow = getEyebrow(t, flight);

  const onPress = React.useCallback(() => {
    history.push(`/island/${user!.uid}`);
  }, [user, history]);

  if (userProfile?.flightStatus !== "open" || flight === null) {
    return null;
  }

  const loading = userProfile === undefined || flight === undefined;

  return (
    <Box>
      <ActionButtonItem
        eyebrow={eyebrow}
        title={t("titleLabel")!}
        loading={loading}
        onPress={onPress}
      />
    </Box>
  );
};
