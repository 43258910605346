import { Box, Typography } from "@material-ui/core";
import { colors } from "common/colors";
import React from "react";
import * as R from "ramda";
import RoundedWhiteContainer from "../RoundedWhiteContainer";

export interface EntryFeeContainerProps {
  delimeter?: string;
}

const EntryFeeContainer: React.FC<EntryFeeContainerProps> = ({
  children,
  delimeter = "or",
}) => {
  const interspersedChildren = React.useMemo(
    () =>
      R.intersperse(
        <Typography
          variant="caption"
          style={{ color: colors["text-hint-color"] }}
        >
          {delimeter}
        </Typography>,
        React.Children.toArray(children).filter((child) => !R.isNil(child))
      ),
    [children, delimeter]
  );

  return (
    <RoundedWhiteContainer
      containerStyle={{
        position: "relative",
      }}
    >
      {interspersedChildren.length > 0 ? (
        <Box
          style={{
            position: "relative",
            paddingLeft: "100%",
            height: 28,
          }}
        >
          <Box
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
            }}
          >
            <Box
              className="no-scrollbar"
              style={{
                width: "100%",
                overflowX: "auto",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {interspersedChildren}
            </Box>
          </Box>
        </Box>
      ) : (
        <Typography
          variant="caption"
          style={{
            flex: 1,
            textAlign: "center",
            color: colors["text-hint-color"],
          }}
        >
          {/* TODO: i18n */}
          No entry fee.
        </Typography>
      )}
    </RoundedWhiteContainer>
  );
};

export default EntryFeeContainer;
