import { Box } from "@material-ui/core";
import React from "react";

export interface ScreenshotProps {
  src: string;
  isMobile: boolean;
}

export const Screenshot: React.FC<ScreenshotProps> = ({src, isMobile}) => {
  
  return (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    style={isMobile ? { marginBottom: 32 } : { marginRight: 16 }}
  >
    <Box
      component="img"
      borderRadius={4}
      // @ts-ignore
      src={src}
      style={
        isMobile
          ? { width: "80%", height: "auto" }
          : { width: 323.6, height: 200 }
      }
    ></Box>
  </Box>
  )
};