import { Box, Button } from "@material-ui/core";
import { UserApi } from "api/user";
import { DisplayName } from "components/DisplayName";
import Row from "components/Row";
import { useProgress } from "hooks/useProgress";
import { useUserProfile } from "hooks/useUserProfile";
import React from "react";

export interface BlockListItemProps {
  uid: string;
}

const BlockListItem: React.FC<BlockListItemProps> = ({ uid }) => {
  const profile = useUserProfile(uid);
  const progress = useProgress("Unblocked!", "Failed to unblock user.");

  const unblock = () => {
    progress.start();
    UserApi.unblockUser(uid)
      .then(() => {
        progress.succeeded();
      })
      .catch((error) => {
        progress.failed(error);
      });
  };

  return (
    <Row style={{ alignItems: "center", flex: 1 }}>
      <Box flex={1}>
        <DisplayName profile={profile} category="caption" />
      </Box>
      <Button color="secondary" onClick={unblock} disabled={progress.loading}>
        Unblock
      </Button>
    </Row>
  );
};

export default BlockListItem;
