import { Box, Typography, Icon, Button } from "@material-ui/core";
import { Space } from "components/Space";
import {
  QueueType,
  FlightPassengerType,
  MAX_QUEUE_SIZE_FIRST_CLASS,
} from "contract/common";
import { FlightPublic } from "contract/view-collection/flight";
import React from "react";
import { useTranslation } from "react-i18next";
import { FlightInfo, InQueueType } from "utils/getFlightInfo";

export interface FirstClassQueueProps {
  flight: FlightPublic;
  flightInfo: FlightInfo;
  loading: boolean;
  disabled?: boolean;
  openQueueDetailModal: () => void;
  openJoinPrivateFlightModal: () => void;
  onPressJoin: () => void;
  onPressLeave: () => void;
  onPressFinish: () => void;
}

const FirstClassQueue: React.FC<FirstClassQueueProps> = ({
  flight,
  flightInfo,
  loading,
  disabled = true,
  openQueueDetailModal,
  openJoinPrivateFlightModal,
  onPressJoin,
  onPressLeave,
  onPressFinish,
}) => {
  const { t } = useTranslation("QueueSection");

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: "4px 0",
        }}
        onClick={openQueueDetailModal}
      >
        <Typography variant="subtitle2">{t("firstClassLabel")!}</Typography>
        <Space horizontal={true} size={2} />
        <Icon
          className="fas fa-user"
          style={{
            fontSize: 12,
            marginRight: 4,
          }}
        />
        <Typography>
          {flightInfo.queueSize.firstClass}/{MAX_QUEUE_SIZE_FIRST_CLASS}
        </Typography>
      </Box>
      {!flightInfo.isHost &&
        (flightInfo.inQueue !== undefined ? (
          flightInfo.inQueue.queueType === QueueType.FIRST_CLASS ? (
            flightInfo.inQueue.type === InQueueType.VISITING ? (
              <Button
                color="secondary"
                size="small"
                variant="contained"
                disabled={disabled || loading}
                onClick={() => onPressFinish()}
              >
                {t("tripFinishedLabel")!}
              </Button>
            ) : (
              <Button
                color="secondary"
                size="small"
                variant="contained"
                disabled={disabled || loading}
                onClick={() => onPressLeave()}
              >
                {t("leaveQueueLabel")!}
              </Button>
            )
          ) : null
        ) : flight.queueLocked ? (
          <Button
            color="primary"
            size="small"
            variant="contained"
            disabled={true}
          >
            {t("lockedLabel")!}
          </Button>
        ) : flight.allowedPassengerType === FlightPassengerType.TRUSTED &&
          !flightInfo.isTrusted ? (
          <Button
            color="primary"
            size="small"
            variant="contained"
            disabled={true}
          >
            {t("trustedOnlyLabel")!}
          </Button>
        ) : (
          <Button
            color="primary"
            size="small"
            variant="contained"
            disabled={disabled || loading}
            onClick={() => {
              if (flight.requirePassword) {
                openJoinPrivateFlightModal();
                return;
              }
              onPressJoin();
            }}
          >
            {t("joinQueueLabel")!}
          </Button>
        ))}
      {flightInfo.isHost && (
        <Button
          color="primary"
          size="small"
          variant="contained"
          disabled={disabled || loading}
          onClick={openQueueDetailModal}
        >
          {t("manageQueueLabel")!}
        </Button>
      )}
    </Box>
  );
};

export default FirstClassQueue;
