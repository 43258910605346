import { Box, Typography } from "@material-ui/core";
import { colors } from "common/colors";
import TouchableOpacity, {
  TouchableOpacityProps,
} from "components/TouchableOpacity";
import React from "react";

export interface AnnouncementBannerProps {
  title?: string;
  disabled?: TouchableOpacityProps["disabled"];
  onPress?: TouchableOpacityProps["onPress"];
  style?: TouchableOpacityProps["style"];
}

export const AnnouncementBanner: React.FC<AnnouncementBannerProps> = ({
  children,
  title,
  disabled,
  onPress,
  style,
}) => {
  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={onPress}
      style={{
        backgroundColor: "rgba(130, 215, 170, 0.94)",
        padding: "2px 0",
        overflow: "hidden",
        zIndex: 99,
        ...style,
      }}
    >
      <Box
        style={{
          borderColor: colors["bg-color-mint-200"],
          borderWidth: 2,
          borderStyle: "dashed",
          padding: "16px 0",
          width: "120%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        {title ? (
          <Typography
            style={{
              color: colors["txt-color-white-500"],
              fontFamily: "sans-serif",
              fontSize: 16,
              fontWeight: 600,
            }}
          >
            {title}
          </Typography>
        ) : (
          children
        )}
      </Box>
    </TouchableOpacity>
  );
};

export default AnnouncementBanner;
