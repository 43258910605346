import React from "react";
import { DisplayName } from "components/DisplayName";
import { formatChatMessageDatetime } from "utils/formatChatMessageDatetime";
import { Space } from "components/Space";
import { useUserProfile } from "hooks/useUserProfile";
import { AuthContext } from "modules/auth/AuthContext";
import { useTranslation } from "react-i18next";
import { UserApi } from "api/user";
import { Box, makeStyles, Menu, MenuItem, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { colors } from "common/colors";
import { useMenu } from "hooks/useMenu";
import { OpenReportModal } from "../Report/useReportModal";
import { ReportSubject } from "contract/common";
import { InProgressModal } from "components/InProgressModal";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: "4px 16px",
  },
  containerNotSelf: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: "4px 16px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
});

export interface ChatMessageUserProps {
  flightCreatorId: string;
  flightId: string;
  chatId: string;
  uid: string;
  content: string;
  at: Date;
  onPressReport: OpenReportModal;
}

export const ChatMessageUser: React.FC<ChatMessageUserProps> = ({
  flightCreatorId,
  flightId,
  chatId,
  uid,
  content,
  at,
  onPressReport,
}) => {
  const { t } = useTranslation("ChatMessageUser");
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const user = React.useContext(AuthContext);
  const authorProfile = useUserProfile(uid);
  const [loading, setLoading] = React.useState(false);

  const isSelf = user?.uid === uid;

  const actionMenu = useMenu();

  const handleReportMessage = React.useCallback(() => {
    // Note: this means page not ready yet, shouldn't happen in reallife
    if (authorProfile === undefined || authorProfile === null) return;

    onPressReport({
      reporteeUid: authorProfile.uid,
      reporteeMessage: content,
      subject: ReportSubject.CHAT,
      subjectMetadata: {
        flightId,
        chatId,
      },
    });
  }, [authorProfile, flightId, chatId]);

  const handleBlockUser = React.useCallback(() => {
    setLoading(true);
    UserApi.blockUser(uid)
      .then(async () => {
        enqueueSnackbar(t("success.general", { ns: "shared" }), {
          variant: "success",
        });
        setLoading(false);
      })
      .catch((error) => {
        enqueueSnackbar(
          t("error.general", { ns: "shared", msg: error.message }),
          { variant: "error" }
        );
        setLoading(false);
      });
  }, [uid]);

  return (
    <>
      <Box
        onClick={!user || isSelf ? undefined : actionMenu.handleClick}
        className={!user || isSelf ? styles.container : styles.containerNotSelf}
      >
        <Box
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <DisplayName
            profile={authorProfile}
            category="caption"
            displayNameOverwrite={isSelf ? t("self") : undefined}
          />

          <Typography
            variant="caption"
            style={{
              color: colors["text-hint-color"],
            }}
          >
            {formatChatMessageDatetime(at)}
          </Typography>
        </Box>
        <Space size={0.5} />
        <Typography style={{ width: "100%", whiteSpace: "break-spaces" }}>
          {content}
        </Typography>
        <InProgressModal visible={loading} />
      </Box>
      <Menu
        anchorEl={actionMenu.anchorEl}
        open={Boolean(actionMenu.anchorEl)}
        onClose={actionMenu.close}
        disableAutoFocusItem
      >
        {flightCreatorId !== uid && (
          <MenuItem
            onClick={() => {
              window.open(`/island/${uid}`, "_blank");
            }}
          >
            {t("action.visit", { ns: "ReportActionSheet" })}
          </MenuItem>
        )}
        {!isSelf && (
          <MenuItem onClick={handleReportMessage}>
            <Typography variant="subtitle1" color="secondary">
              {t("action.report", { ns: "ReportActionSheet" })}
            </Typography>
          </MenuItem>
        )}
        {!isSelf && (
          <MenuItem onClick={handleBlockUser}>
            <Typography variant="subtitle1" color="secondary">
              {t("action.block", { ns: "ReportActionSheet" })}
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
