import firebase from "common/firebase";
import { ReportRecord } from "contract/data-collection/report";
import { rejectNil } from "utils/rejectNil";

export type ReportPayload = Pick<
  ReportRecord,
  "subject" | "subjectMetadata" | "reason" | "reasonDescription" | "reporteeUid"
>;

export class ReportApi {
  static report(payload: ReportPayload) {
    return firebase.functions().httpsCallable("reportAdd")(rejectNil(payload));
  }
}
