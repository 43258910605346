import { Link } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Space } from "components/Space";
import { FlightPublic, FlightQueue } from "contract/view-collection/flight";
import { AuthContext } from "modules/auth/AuthContext";
import React from "react";
import { FlightInfo } from "utils/getFlightInfo";
import { ChatSection } from "./ChatSection/ChatSection";
import QueueSection from "./QueueSection/QueueSection";
import { OpenReportModal } from "./Report/useReportModal";

export interface FlightInteractionSectionProps {
  flight: FlightPublic;
  flightInfo: FlightInfo;
  flightQueue: FlightQueue | undefined;
  onPressReport: OpenReportModal;
}

const FlightInteractionSection: React.FC<FlightInteractionSectionProps> = ({
  flight,
  flightInfo,
  flightQueue,
  onPressReport,
}) => {
  const user = React.useContext(AuthContext);
  const disabled = user === null;

  return (
    <>
      {disabled && (
        <>
          <Alert severity="warning">
            To join this Queue and Chat, please click here to{" "}
            <Link href="/sign-in" target="_blank">
              sign in
            </Link>
            .
          </Alert>
          <Space size={2} />
        </>
      )}
      <QueueSection
        flight={flight}
        flightInfo={flightInfo}
        flightQueue={flightQueue}
        disabled={disabled}
      />
      <Space size={4} />
      <ChatSection
        flight={flight}
        disabled={disabled}
        onPressReport={onPressReport}
      />
    </>
  );
};

export default FlightInteractionSection;
