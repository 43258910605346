import {
  Button,
  Box,
  Typography,
  Icon,
  IconButtonProps,
} from "@material-ui/core";
import { colors } from "common/colors";
import React from "react";

export interface RoundedBackgroundLabelProps {
  title: string;
  accessoryRight?: boolean;
  accessoryRightOnPress?: IconButtonProps["onClick"];
  accessoryRightIcon?: string;
}

export const RoundedBackgroundLabel: React.FC<RoundedBackgroundLabelProps> = ({
  title,
  accessoryRight,
  accessoryRightOnPress,
  accessoryRightIcon,
}) => {
  return (
    <Box
      style={{
        backgroundColor: colors["bg-color-brown-transparent-100"],
        borderRadius: 8,
        padding: "8px 32px",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        variant="caption"
        style={{ color: colors["txt-color-brown-400"], fontWeight: "bold" }}
      >
        {title}
      </Typography>
      {accessoryRight && (
        <Button
          onClick={accessoryRightOnPress}
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            bottom: 0,
          }}
        >
          <Icon
            className={accessoryRightIcon || "fas fa-question-circle"}
            style={{ fontSize: 12 }}
          />
        </Button>
      )}
    </Box>
  );
};
