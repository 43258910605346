import React from "react";
import firebase from "common/firebase";
import { User } from "modules/auth/AuthContext";

export function useFirebaseUser() {
  const [state, setState] = React.useState<User>();

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged((potentialUser) => {
      setState(potentialUser);
      if (potentialUser) {
        firebase.analytics().setUserId(potentialUser.uid);
      }
    });
  }, []);

  return state;
}
