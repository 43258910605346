import React from "react";
import { useTranslation } from "react-i18next";
import { Space } from "components/Space";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Icon,
  ListItem,
  ListItemText,
  TextField,
  TextFieldProps,
  Typography,
} from "@material-ui/core";
import { colors } from "common/colors";
import { useSwitchState } from "hooks/useSwitchState";
import { useSnackbar } from "notistack";

export interface ProfileEditListItemTextProps {
  title: string;
  caption?: string;
  value?: string;
  valueDisplay?: string;
  disabled?: boolean;
  onSubmit: (value: string | undefined) => Promise<any>;
  validate: (value: string | undefined) => string | undefined;
  keyboardType?: TextFieldProps["type"];
}

export const ProfileEditListItemText: React.FC<ProfileEditListItemTextProps> = ({
  title,
  caption,
  value,
  valueDisplay,
  disabled = false,
  validate,
  onSubmit,
  keyboardType,
}) => {
  const { t } = useTranslation("ProfileEditListItemText");
  const { enqueueSnackbar } = useSnackbar();

  const [newValue, setNewValue] = React.useState(value);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setNewValue(value);
  }, [value]);

  const dialog = useSwitchState();

  const valueWithPlaceholder = valueDisplay || value || "-";

  const validationError = newValue === value ? undefined : validate(newValue);

  return (
    <React.Fragment>
      <ListItem
        onClick={dialog.turnOn}
        disabled={disabled}
        button
        style={{ padding: "4px 0" }}
      >
        <ListItemText>
          <Typography variant="caption" style={{}}>
            {title}
          </Typography>
        </ListItemText>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: "56%",
          }}
        >
          <Typography
            variant="subtitle2"
            style={{
              flex: 1,
              textAlign: "right",
              color: colors["text-hint-color"],
            }}
          >
            {valueWithPlaceholder}
          </Typography>

          <Space horizontal size={1} />
          {!disabled && (
            <Icon
              className="fas fa-angle-right"
              style={{
                fontSize: 16,
                color: colors["text-hint-color"],
                textAlign: "center",
              }}
            />
          )}
        </Box>
      </ListItem>

      <Dialog
        open={dialog.on}
        fullWidth={true}
        maxWidth="xs"
        onClose={() => {
          if (loading) return;
          setNewValue(value);
          dialog.turnOff();
        }}
      >
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            variant="outlined"
            label={title}
            type={keyboardType}
            value={newValue}
            error={!!validationError}
            helperText={validationError || caption}
            onChange={({ target: { value: nextValue } }) => {
              setNewValue(nextValue);
            }}
            disabled={loading}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={dialog.turnOff} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              setLoading(true);
              onSubmit(newValue)
                .then(() => {
                  enqueueSnackbar(t("success.submit")!, { variant: "success" });
                  dialog.turnOff();
                  setLoading(false);
                })
                .catch((error) => {
                  // crashlytics().recordError(error);
                  enqueueSnackbar(
                    t("error.general", {
                      ns: "shared",
                      msg: error.message,
                    })!,
                    { variant: "error" }
                  );
                  setLoading(false);
                });
            }}
            color="primary"
            disabled={loading}
          >
            {loading ? t("updatingLabel")! : t("updateLabel")!}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
