import React from "react";
import TouchableOpacity, { TouchableOpacityProps } from "./TouchableOpacity";
import { Box, Typography } from "@material-ui/core";
import { ReactComponent as ButtonBubble } from "assets/pattern/button-bubble.svg";
import { ReactComponent as ArrowRightSmall } from "assets/pattern/arrow-right-small.svg";
import { colors } from "common/colors";

export interface BubbleButtonProps extends TouchableOpacityProps {
  title: string;
  titleColor?: keyof typeof colors;
  color?: keyof typeof colors;
  showArrow?: boolean;
}

export const BubbleButton: React.FC<BubbleButtonProps> = ({
  title,
  titleColor = "txt-color-white-500",
  color = "bg-color-brown-500",
  showArrow = true,
  ...props
}) => {
  return (
    <TouchableOpacity {...props} activeOpacity={0.7}>
      <Box
        style={{
          display: "inline-flex",
          alignSelf: "flex-start",
          position: "relative",
        }}
      >
        <Box
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <ButtonBubble fill={colors[color]} height="100%" width="100%" />
        </Box>
        <Box
          style={{
            padding: "18px 30px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Typography
            variant="subtitle1"
            style={{
              textAlign: "center",
              color: colors[titleColor],
              fontWeight: 600,
            }}
          >
            {title}
          </Typography>
          {showArrow && (
            <ArrowRightSmall
              fill={colors[titleColor]}
              height="16"
              width="16"
              style={{ marginLeft: 12 }}
            />
          )}
        </Box>
      </Box>
    </TouchableOpacity>
  );
};
