import { ResidentWithStatus } from "contract/data-collection/flight";
import useVillager from "hooks/useVillager";
import React from "react";
import { useTranslation } from "react-i18next";
import { getResidentDetails } from "utils/getResidentDetails";
import TouchableImageListItem, { TouchableImageListItemProps } from "./Item";

export interface VillagerItemProps
  extends Omit<TouchableImageListItemProps, "title" | "image"> {
  id: string;
  status?: ResidentWithStatus["status"];
}

const VillagerItem: React.FC<VillagerItemProps> = ({
  id,
  status,
  ...props
}) => {
  const { t } = useTranslation("TouchableImageItemResident");
  const villager = useVillager(id);

  if (!villager) return null;

  const villagerDetailed = getResidentDetails(t, villager, status);

  return (
    <TouchableImageListItem
      title={villager.name}
      image={villager.source}
      statusImage={villagerDetailed?.statusImageSource}
      {...props}
    />
  );
};

export default VillagerItem;
