import { Gender } from "assets/acdb/villagers";
import { Personality } from "assets/acdb/villagers";
import acdb from "modules/acdb";
import React from "react";
import { capitalizeAll } from "utils/capitaliseAll";

export interface Villager {
  id: string;
  name: string;
  source: string;
  birthday: string;
  species: string;
  gender: Gender;
  personality: Personality;
  favoriteSaying: string;
}

export default function useVillager(id: string): Villager | undefined {
  const [villager, setVillager] = React.useState<Villager | undefined>(
    undefined
  );

  React.useEffect(() => {
    acdb.getVillagerById(id).then((villager) => {
      setVillager({
        id,
        name: capitalizeAll(villager.name),
        source: villager.iconImage,
        birthday: villager.birthday,
        species: villager.species,
        gender: villager.gender,
        personality: villager.personality,
        favoriteSaying: villager.favoriteSaying,
      });
    });
  }, [id]);

  if (!villager) return undefined;

  return villager;
}
