import { CatalogAndWishlistItem } from "contract/data-collection/user";
import { CatalogAndWishlistItemType } from "contract/common";

export function filterCatalogAndWishlist(
  list: CatalogAndWishlistItem[] | undefined,
  type: CatalogAndWishlistItemType,
): string[] {
  return (list || [])
    .filter((item) => item.type === type)
    .map((item) => item.id);
}
