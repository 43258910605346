import { isDate } from "./isDate";

export function createSafeDate(a: any) {
  const d = new Date(a);

  if (isDate(d)) {
    return d;
  }

  return new Date();
}
