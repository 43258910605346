import React from "react";

export function useSwitchState() {
  const [state, setState] = React.useState(false);

  const turnOn = React.useCallback(() => {
    setState(true);
  }, []);

  const turnOff = React.useCallback(() => {
    setState(false);
  }, []);

  return {
    on: state,
    turnOff,
    turnOn,
  };
}
