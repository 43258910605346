import React from "react";
import { DisplayName } from "components/DisplayName";
import { FlightQueuePassenger } from "contract/view-collection/flight";
import { useUserProfile } from "hooks/useUserProfile";
import { Space } from "components/Space";
import { formatDistanceToNow } from "date-fns";
import { QueueApi } from "api/queue";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Box, Button, Typography } from "@material-ui/core";
import { colors } from "common/colors";

export interface QueueListItemProps {
  index: number;
  passengerUid: string;
  passenger: FlightQueuePassenger;
  visiting: boolean;
  isHost: boolean;
}

export const QueueListItem: React.FC<QueueListItemProps> = ({
  index,
  passenger,
  passengerUid,
  visiting,
  isHost,
}) => {
  const { t } = useTranslation("QueueListItem");
  const { enqueueSnackbar } = useSnackbar();
  const passengerProfile = useUserProfile(passengerUid);

  const [loading, setLoading] = React.useState(false);

  const kickUser = React.useCallback(() => {
    setLoading(true);
    QueueApi.kick({
      uid: passengerUid,
    })
      .then(() => {
        enqueueSnackbar(t("success.general", { ns: "shared" })!, {
          variant: "success",
        });
        setLoading(false);
      })
      .catch((e) => {
        enqueueSnackbar(t("error.general", { ns: "shared", msg: e.message })!, {
          variant: "error",
        });
        setLoading(false);
      });
  }, [enqueueSnackbar]);

  return (
    <Box
      style={{
        padding: 8,
        backgroundColor: visiting
          ? colors["bg-color-yellow-transparent-500"]
          : undefined,
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography variant="caption">{index + 1}.</Typography>
        <Space size={0.5} horizontal />
        <Box style={{ flex: 1, display: "flex" }}>
          <Box
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <DisplayName profile={passengerProfile} category="caption" />
          </Box>
          <Box style={{ flexDirection: "row" }}>
            <Typography
              variant="caption"
              style={{ color: colors["text-hint-color"], fontSize: 12 }}
            >
              {formatDistanceToNow(passenger.joinedAt, { addSuffix: true })}
            </Typography>
          </Box>
        </Box>
        {isHost && (
          <>
            <Space size={1} horizontal />
            <Box>
              <Button
                color="secondary"
                variant="contained"
                size="small"
                onClick={kickUser}
                disabled={loading}
              >
                {t("label.kick")!}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
