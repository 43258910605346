import React from "react";
import { Box, Icon, useTheme } from "@material-ui/core";
import { Space } from "./Space";

export interface LinkButtonProps {
  title: string;
  link: string;
}

export const LinkButton: React.FC<LinkButtonProps> = ({ title, link }) => {
  const theme = useTheme();
  return (
    <Box
      component="a"
      // @ts-ignore
      href={link}
      py={1}
      px={2}
      bgcolor={theme.palette.background.default}
      borderRadius={8}
      color="#6B5C43"
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      fontWeight={600}
      style={{ textDecoration: "none", height: 18 }}
    >
      <Box>{title}</Box>
      <Space horizontal size={1} />
      <Icon className="fas fa-angle-right" style={{ fontSize: 14 }} />
    </Box>
  );
};
