import React from "react";
import firebase from "firebase";
import { Ref } from "api/ref";

export interface AirportData {
  turnips?: number;
  general?: number;
}

export function useAirportData() {
  const [state, setState] = React.useState<AirportData>({
    turnips: undefined,
    general: undefined,
  });

  React.useEffect(() => {
    const ref = Ref.airport;
    const callback = ref.on(
      "value",
      (snapshot: firebase.database.DataSnapshot) => {
        if (!snapshot.exists()) {
          return;
        }

        const newData: AirportData = {};

        newData.turnips = snapshot.child("turnipsFlightCount").val() || 0;

        newData.general = snapshot.child("generalFlightCount").val() || 0;

        setState(newData);
      }
    );

    return () => {
      ref.off("value", callback);
    };
  }, []);

  return state;
}
