import { ReportRecord } from "contract/data-collection/report";
import { useSwitchState } from "hooks/useSwitchState";
import React from "react";

export interface ReportModalParams {
  reporteeUid: ReportRecord["reporteeUid"];
  reporteeMessage?: string;
  subject: ReportRecord["subject"];
  subjectMetadata?: ReportRecord["subjectMetadata"];
}

export type OpenReportModal = (params: ReportModalParams) => void;

export function useReportModal() {
  const modal = useSwitchState();
  const [params, setParams] = React.useState<undefined | ReportModalParams>(
    undefined
  );

  return {
    visible: modal.on,
    close: () => {
      modal.turnOff();
      setParams(undefined);
    },
    open: (params: ReportModalParams) => {
      setParams(params);
      modal.turnOn();
    },
    params,
  };
}
