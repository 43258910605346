import { DisplayName } from "components/DisplayName";
import { Space } from "components/Space";
import { Post, PostMetadata } from "contract/data-collection/post";
import { formatDistanceToNow } from "date-fns";
import { useUserProfile } from "hooks/useUserProfile";
import React from "react";
import PostItemOffer from "./PostItemOffer";
import PostItemRequest from "./PostItemRequest";
import PostItemResident from "./PostItemResident";
import { AuthContext } from "modules/auth/AuthContext";
import { useTranslation } from "react-i18next";
import { Avatar, Box, Button, Icon, Typography } from "@material-ui/core";
import { colors } from "common/colors";

export interface PostItemContentProps {
  post: Post;
  postMetadata: PostMetadata | null | undefined;
}

const PostItemContent: React.FC<PostItemContentProps> = ({
  post,
  postMetadata,
}) => {
  const { t } = useTranslation("PostItemContent");
  const user = React.useContext(AuthContext);
  const authorProfile = useUserProfile(post.uid);
  const isAuthor = user?.uid === post.uid;

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Avatar
          variant="rounded"
          src={authorProfile?.photoURL}
          style={{
            borderColor: colors["bg-color-off-white-500"],
            borderWidth: 2,
            width: 32,
            height: 32,
          }}
        />
        <Space horizontal size={1} />
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <DisplayName profile={authorProfile} category="subtitle1" />
          <Typography
            variant="caption"
            style={{ color: colors["text-hint-color"] }}
          >
            {formatDistanceToNow(post.createdAt)}
          </Typography>
        </Box>
      </Box>
      <Space size={1} />
      <Box>
        <Typography variant="body2">{post.text}</Typography>
      </Box>
      {post.resident && (
        <>
          <Space size={2} />
          <PostItemResident
            postId={post.id}
            active={!post.interactionFinished}
            resident={post.resident}
            shouldShowCTA={!isAuthor}
          />
        </>
      )}
      {post.itemOffer && (
        <>
          <Space size={2} />
          <PostItemOffer
            active={!post.interactionFinished}
            postId={post.id}
            offer={post.itemOffer}
            shouldShowCTA={!isAuthor}
          />
        </>
      )}
      {post.itemRequest && (
        <>
          <Space size={2} />
          <PostItemRequest
            active={!post.interactionFinished}
            postId={post.id}
            request={post.itemRequest}
            shouldShowCTA={!isAuthor}
          />
        </>
      )}

      {post.images && post.images.length > 0 && (
        <>
          <Space size={2} />

          <Box style={{ borderRadius: 8, position: "relative" }}>
            <img
              src={post.images[0]}
              style={{
                width: "100%",
                height: 240,
                borderRadius: 8,
                objectFit: "cover",
              }}
            />
            {post.images.length > 1 && (
              <Box
                style={{
                  position: "absolute",
                  bottom: 3,
                  right: 0,
                  backgroundColor: "rgba(0,0,0,.15)",
                  padding: 4,
                  borderBottomRightRadius: 8,
                }}
              >
                <Icon className="fas fa-images" style={{ color: "white" }} />
              </Box>
            )}
          </Box>
        </>
      )}
      <Space size={1} />
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Button variant="text" size="small">
          <Icon
            className="far fa-comment"
            style={{ fontSize: 16, marginRight: 4 }}
          />
          {postMetadata?.replyCount ? postMetadata?.replyCount : ""}
        </Button>
        <Button variant="text" size="small" style={{ minWidth: 32 }}>
          <Icon
            className="far fa-heart"
            style={{ fontSize: 16, marginRight: 4 }}
          />
          {postMetadata?.likeCount ? postMetadata?.likeCount : ""}
        </Button>
      </Box>
    </Box>
  );
};

export default PostItemContent;
