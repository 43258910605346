import React from "react";
import firebase from "common/firebase";
import { useSnackbar } from "notistack";

export function useSignOut() {
  const { enqueueSnackbar } = useSnackbar();
  const signOut = React.useCallback(() => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        enqueueSnackbar("Signed out!");
      })
      .catch(console.log);
  }, []);

  return signOut;
}
