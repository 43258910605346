import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import React from "react";

export interface CloseFlightDialogProps {
  loading: boolean;
  visible: boolean;
  onRequestClose: () => void;
  onPressConfirm: () => void;
}

const CloseFlightDialog: React.FC<CloseFlightDialogProps> = ({
  loading,
  visible,
  onRequestClose,
  onPressConfirm,
}) => {
  return (
    <Dialog open={visible} onClose={onRequestClose}>
      <DialogTitle>{"End flight"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Ending the flight will remove all current queueing visitors
          immediately.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onRequestClose} disabled={loading}>
          Cancel
        </Button>
        <Button onClick={onPressConfirm} color="secondary" disabled={loading}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CloseFlightDialog;
