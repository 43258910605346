import React from "react";
import {
  ChatRecord,
  ChatRecordSystemContentType,
} from "contract/view-collection/chat";
import { ChatRecordContentType, FlightPassengerType } from "contract/common";
import { useTranslation } from "react-i18next";
import { ChatMessageSystem } from "./ChatMessageSystem";
import { ChatMessageUser } from "./ChatMessageUser";
import { formatEndTime } from "utils/formatEndTime";
import { getDateOrUndefined } from "utils/getDateOrUndefined";
import { OpenReportModal } from "../Report/useReportModal";

export function getChatContent(t: any, message: ChatRecord) {
  if (message.contentType === ChatRecordContentType.JSON) {
    try {
      const {
        type,
        // before,
        after,
      } = JSON.parse(message.content);

      switch (type) {
        case ChatRecordSystemContentType.FLIGHT_ALLOWED_PASSENGER_TYPE:
          return t(
            after === FlightPassengerType.TRUSTED
              ? "updated.allowedPassengerTypeTrusted"
              : "updated.allowedPassengerTypeAll"
          );
        case ChatRecordSystemContentType.FLIGHT_DODO_CODE:
          return t("updated.dodoCode");
        case ChatRecordSystemContentType.FLIGHT_END:
          return t("updated.end");
        case ChatRecordSystemContentType.FLIGHT_ENTRY_FEE:
          return t("updated.entryFee");
        case ChatRecordSystemContentType.FLIGHT_MAX_ALLOWED:
          return t("updated.maxAllowed", { maxAllowed: after });
        case ChatRecordSystemContentType.FLIGHT_MAX_QUEUE_SIZE:
          return t("updated.maxQueueSize", { maxQueueSize: after });
        case ChatRecordSystemContentType.FLIGHT_MAX_STAY_TIME:
          return t("updated.maxStayTime", { maxStayTime: after });
        case ChatRecordSystemContentType.FLIGHT_MESSAGE:
          return t("updated.message");
        case ChatRecordSystemContentType.FLIGHT_OPEN:
          return t("updated.open");
        case ChatRecordSystemContentType.FLIGHT_PASSWORD:
          return t("updated.password");
        case ChatRecordSystemContentType.FLIGHT_PLAT_TO_CLOSE_AT: {
          const d = getDateOrUndefined(after);
          const endTime = d ? formatEndTime(d) : "-";
          return t("updated.planToCloseAt", { endTime });
        }
        case ChatRecordSystemContentType.FLIGHT_PRICE:
          return t("updated.price", { price: after });
        case ChatRecordSystemContentType.FLIGHT_REQUIRE_PASSWORD:
          return t(
            after === true
              ? "updated.requirePasswordYes"
              : "updated.requirePasswordNo"
          );
        case ChatRecordSystemContentType.FLIGHT_RESIDENTS:
          return t("updated.residents");
        case ChatRecordSystemContentType.FLIGHT_SPECIAL_VISITORS:
          return t("updated.specialVisitors");
        case ChatRecordSystemContentType.FLIGHT_TURNIPS_FLIGHT_TYPE:
          return t("updated.turnipsFlightType");
      }
    } catch (error) {
      // crashlytics().recordError(error);
    }
  }

  return message.content;
}

export interface ChatMessageProps {
  message: ChatRecord;
  flightCreatorId: string;
  flightId: string;
  onPressReport: OpenReportModal;
}

export const ChatMessage: React.FC<ChatMessageProps> = ({
  flightCreatorId,
  flightId,
  message,
  onPressReport,
}) => {
  const { t } = useTranslation("ChatMessage");

  const content = getChatContent(t, message);

  if (message.uid === "SYSTEM") {
    return <ChatMessageSystem content={content} />;
  }

  return (
    <ChatMessageUser
      flightCreatorId={flightCreatorId}
      flightId={flightId}
      chatId={message.id}
      uid={message.uid}
      content={content}
      at={message.at}
      onPressReport={onPressReport}
    />
  );
};
