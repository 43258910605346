export const colors = {
  "color-basic-100": "#FFFFFF",
  "color-basic-200": "#F7F9FC",
  "color-basic-300": "#EDF1F7",
  "color-basic-400": "#E4E9F2",
  "color-basic-500": "#C5CEE0",
  "color-basic-600": "#8F9BB3",
  "color-basic-700": "#2E3A59",
  "color-basic-800": "#222B45",
  "color-basic-900": "#1A2138",
  "color-basic-1000": "#151A30",
  "color-basic-1100": "#101426",
  "color-basic-transparent-100": "rgba(143, 155, 179, 0.08)",
  "color-basic-transparent-200": "rgba(143, 155, 179, 0.16)",
  "color-basic-transparent-300": "rgba(143, 155, 179, 0.24)",
  "color-basic-transparent-400": "rgba(143, 155, 179, 0.32)",
  "color-basic-transparent-500": "rgba(143, 155, 179, 0.40)",
  "color-basic-transparent-600": "rgba(143, 155, 179, 0.48)",
  "color-basic-control-transparent-100": "rgba(255, 255, 255, 0.08)",
  "color-basic-control-transparent-200": "rgba(255, 255, 255, 0.16)",
  "color-basic-control-transparent-300": "rgba(255, 255, 255, 0.24)",
  "color-basic-control-transparent-400": "rgba(255, 255, 255, 0.32)",
  "color-basic-control-transparent-500": "rgba(255, 255, 255, 0.40)",
  "color-basic-control-transparent-600": "rgba(255, 255, 255, 0.48)",
  "color-basic-focus": "#E4E9F2",
  "color-basic-hover": "#F7F9FC",
  "color-basic-default": "#EDF1F7",
  "color-basic-active": "#E4E9F2",
  "color-basic-disabled": "rgba(143, 155, 179, 0.24)",
  "color-basic-focus-border": "#C5CEE0",
  "color-basic-hover-border": "#F7F9FC",
  "color-basic-default-border": "#EDF1F7",
  "color-basic-active-border": "#E4E9F2",
  "color-basic-disabled-border": "rgba(143, 155, 179, 0.24)",
  "color-basic-transparent-focus": "rgba(143, 155, 179, 0.24)",
  "color-basic-transparent-hover": "rgba(143, 155, 179, 0.16)",
  "color-basic-transparent-default": "rgba(143, 155, 179, 0.08)",
  "color-basic-transparent-active": "rgba(143, 155, 179, 0.24)",
  "color-basic-transparent-disabled": "rgba(143, 155, 179, 0.16)",
  "color-basic-transparent-focus-border": "#8F9BB3",
  "color-basic-transparent-hover-border": "#8F9BB3",
  "color-basic-transparent-default-border": "#8F9BB3",
  "color-basic-transparent-active-border": "#8F9BB3",
  "color-basic-transparent-disabled-border": "rgba(143, 155, 179, 0.24)",
  "color-primary-focus": "#5C4A30",
  "color-primary-hover": "#A69677",
  "color-primary-default": "#6B5C43",
  "color-primary-active": "#5C4A30",
  "color-primary-disabled": "rgba(143, 155, 179, 0.24)",
  "color-primary-focus-border": "#4D3921",
  "color-primary-hover-border": "#A69677",
  "color-primary-default-border": "#6B5C43",
  "color-primary-active-border": "#5C4A30",
  "color-primary-disabled-border": "rgba(143, 155, 179, 0.24)",
  "color-primary-transparent-focus": "rgba(107, 92, 67, 0.24)",
  "color-primary-transparent-hover": "rgba(107, 92, 67, 0.16)",
  "color-primary-transparent-default": "rgba(107, 92, 67, 0.08)",
  "color-primary-transparent-active": "rgba(107, 92, 67, 0.24)",
  "color-primary-transparent-disabled": "rgba(143, 155, 179, 0.16)",
  "color-primary-transparent-focus-border": "#6B5C43",
  "color-primary-transparent-hover-border": "#6B5C43",
  "color-primary-transparent-default-border": "#6B5C43",
  "color-primary-transparent-active-border": "#6B5C43",
  "color-primary-transparent-disabled-border": "rgba(143, 155, 179, 0.24)",
  "color-success-focus": "#00686B",
  "color-success-hover": "#2CB19C",
  "color-success-default": "#007d75",
  "color-success-active": "#00686B",
  "color-success-disabled": "rgba(143, 155, 179, 0.24)",
  "color-success-focus-border": "#004F59",
  "color-success-hover-border": "#2CB19C",
  "color-success-default-border": "#007d75",
  "color-success-active-border": "#00686B",
  "color-success-disabled-border": "rgba(143, 155, 179, 0.24)",
  "color-success-transparent-focus": "rgba(0, 125, 117, 0.24)",
  "color-success-transparent-hover": "rgba(0, 125, 117, 0.16)",
  "color-success-transparent-default": "rgba(0, 125, 117, 0.08)",
  "color-success-transparent-active": "rgba(0, 125, 117, 0.24)",
  "color-success-transparent-disabled": "rgba(143, 155, 179, 0.16)",
  "color-success-transparent-focus-border": "#007d75",
  "color-success-transparent-hover-border": "#007d75",
  "color-success-transparent-default-border": "#007d75",
  "color-success-transparent-active-border": "#007d75",
  "color-success-transparent-disabled-border": "rgba(143, 155, 179, 0.24)",
  "color-info-focus": "#205180",
  "color-info-hover": "#5B99BF",
  "color-info-default": "#2d6895",
  "color-info-active": "#205180",
  "color-info-disabled": "rgba(143, 155, 179, 0.24)",
  "color-info-focus-border": "#163D6B",
  "color-info-hover-border": "#5B99BF",
  "color-info-default-border": "#2d6895",
  "color-info-active-border": "#205180",
  "color-info-disabled-border": "rgba(143, 155, 179, 0.24)",
  "color-info-transparent-focus": "rgba(45, 104, 149, 0.24)",
  "color-info-transparent-hover": "rgba(45, 104, 149, 0.16)",
  "color-info-transparent-default": "rgba(45, 104, 149, 0.08)",
  "color-info-transparent-active": "rgba(45, 104, 149, 0.24)",
  "color-info-transparent-disabled": "rgba(143, 155, 179, 0.16)",
  "color-info-transparent-focus-border": "#2d6895",
  "color-info-transparent-hover-border": "#2d6895",
  "color-info-transparent-default-border": "#2d6895",
  "color-info-transparent-active-border": "#2d6895",
  "color-info-transparent-disabled-border": "rgba(143, 155, 179, 0.24)",
  "color-warning-focus": "#CFBF51",
  "color-warning-hover": "#F6EC92",
  "color-warning-default": "#f1e26f",
  "color-warning-active": "#CFBF51",
  "color-warning-disabled": "rgba(143, 155, 179, 0.24)",
  "color-warning-focus-border": "#AD9D37",
  "color-warning-hover-border": "#F6EC92",
  "color-warning-default-border": "#f1e26f",
  "color-warning-active-border": "#CFBF51",
  "color-warning-disabled-border": "rgba(143, 155, 179, 0.24)",
  "color-warning-transparent-focus": "rgba(241, 226, 111, 0.24)",
  "color-warning-transparent-hover": "rgba(241, 226, 111, 0.16)",
  "color-warning-transparent-default": "rgba(241, 226, 111, 0.08)",
  "color-warning-transparent-active": "rgba(241, 226, 111, 0.24)",
  "color-warning-transparent-disabled": "rgba(143, 155, 179, 0.16)",
  "color-warning-transparent-focus-border": "#f1e26f",
  "color-warning-transparent-hover-border": "#f1e26f",
  "color-warning-transparent-default-border": "#f1e26f",
  "color-warning-transparent-active-border": "#f1e26f",
  "color-warning-transparent-disabled-border": "rgba(143, 155, 179, 0.24)",
  "color-danger-focus": "#CA5261",
  "color-danger-hover": "#F39994",
  "color-danger-default": "#ec7175",
  "color-danger-active": "#CA5261",
  "color-danger-disabled": "rgba(143, 155, 179, 0.24)",
  "color-danger-focus-border": "color-danger-700",
  "color-danger-hover-border": "#F39994",
  "color-danger-default-border": "#ec7175",
  "color-danger-active-border": "#CA5261",
  "color-danger-disabled-border": "rgba(143, 155, 179, 0.24)",
  "color-danger-transparent-focus": "rgba(236, 113, 117, 0.24)",
  "color-danger-transparent-hover": "rgba(236, 113, 117, 0.16)",
  "color-danger-transparent-default": "rgba(236, 113, 117, 0.08)",
  "color-danger-transparent-active": "rgba(236, 113, 117, 0.24)",
  "color-danger-transparent-disabled": "rgba(143, 155, 179, 0.16)",
  "color-danger-transparent-focus-border": "#ec7175",
  "color-danger-transparent-hover-border": "#ec7175",
  "color-danger-transparent-default-border": "#ec7175",
  "color-danger-transparent-active-border": "#ec7175",
  "color-danger-transparent-disabled-border": "rgba(143, 155, 179, 0.24)",
  "color-control-focus": "#EDF1F7",
  "color-control-hover": "#F7F9FC",
  "color-control-default": "#FFFFFF",
  "color-control-active": "#EDF1F7",
  "color-control-disabled": "rgba(143, 155, 179, 0.24)",
  "color-control-focus-border": "#C5CEE0",
  "color-control-hover-border": "#F7F9FC",
  "color-control-default-border": "#FFFFFF",
  "color-control-active-border": "#EDF1F7",
  "color-control-disabled-border": "rgba(143, 155, 179, 0.24)",
  "color-control-transparent-focus": "rgba(255, 255, 255, 0.24)",
  "color-control-transparent-hover": "rgba(255, 255, 255, 0.16)",
  "color-control-transparent-default": "rgba(255, 255, 255, 0.08)",
  "color-control-transparent-active": "rgba(255, 255, 255, 0.24)",
  "color-control-transparent-disabled": "rgba(143, 155, 179, 0.16)",
  "color-control-transparent-focus-border": "#FFFFFF",
  "color-control-transparent-hover-border": "#FFFFFF",
  "color-control-transparent-default-border": "#FFFFFF",
  "color-control-transparent-active-border": "#FFFFFF",
  "color-control-transparent-disabled-border": "rgba(143, 155, 179, 0.24)",
  "background-basic-color-1": "#FFFFFF",
  "background-basic-color-2": "#F7F9FC",
  "background-basic-color-3": "#EDF1F7",
  "background-basic-color-4": "#E4E9F2",
  "background-alternative-color-1": "#222B45",
  "background-alternative-color-2": "#1A2138",
  "background-alternative-color-3": "#151A30",
  "background-alternative-color-4": "#101426",
  "border-basic-color-1": "#FFFFFF",
  "border-basic-color-2": "#F7F9FC",
  "border-basic-color-3": "#EDF1F7",
  "border-basic-color-4": "#E4E9F2",
  "border-basic-color-5": "#C5CEE0",
  "border-alternative-color-1": "#222B45",
  "border-alternative-color-2": "#1A2138",
  "border-alternative-color-3": "#151A30",
  "border-alternative-color-4": "#101426",
  "border-alternative-color-5": "#101426",
  "border-primary-color-1": "#6B5C43",
  "border-primary-color-2": "#5C4A30",
  "border-primary-color-3": "#4D3921",
  "border-primary-color-4": "#3E2A15",
  "border-primary-color-5": "#331F0C",
  "border-success-color-1": "#007d75",
  "border-success-color-2": "#00686B",
  "border-success-color-3": "#004F59",
  "border-success-color-4": "#003948",
  "border-success-color-5": "#002A3B",
  "border-info-color-1": "#2d6895",
  "border-info-color-2": "#205180",
  "border-info-color-3": "#163D6B",
  "border-info-color-4": "#0E2B56",
  "border-info-color-5": "#081E47",
  "border-warning-color-1": "#f1e26f",
  "border-warning-color-2": "#CFBF51",
  "border-warning-color-3": "#AD9D37",
  "border-warning-color-4": "#8B7B23",
  "border-warning-color-5": "#736415",
  "border-danger-color-1": "#ec7175",
  "border-danger-color-2": "#CA5261",
  "border-danger-color-3": "#A93850",
  "border-danger-color-4": "#882442",
  "border-danger-color-5": "#711539",
  "text-basic-color": "#222B45",
  "text-alternate-color": "#FFFFFF",
  "text-control-color": "#FFFFFF",
  "text-disabled-color": "rgba(143, 155, 179, 0.48)",
  "text-hint-color": "#8F9BB3",
  "text-primary-color": "#6B5C43",
  "text-primary-focus-color": "#5C4A30",
  "text-primary-hover-color": "#A69677",
  "text-primary-active-color": "#5C4A30",
  "text-primary-disabled-color": "#A69677",
  "text-success-color": "#007d75",
  "text-success-focus-color": "#00686B",
  "text-success-hover-color": "#2CB19C",
  "text-success-active-color": "#00686B",
  "text-success-disabled-color": "#2CB19C",
  "text-info-color": "#2d6895",
  "text-info-focus-color": "#205180",
  "text-info-hover-color": "#5B99BF",
  "text-info-active-color": "#205180",
  "text-info-disabled-color": "#5B99BF",
  "text-warning-color": "#f1e26f",
  "text-warning-focus-color": "#CFBF51",
  "text-warning-hover-color": "#F6EC92",
  "text-warning-active-color": "#CFBF51",
  "text-warning-disabled-color": "#F6EC92",
  "text-danger-color": "#ec7175",
  "text-danger-focus-color": "#CA5261",
  "text-danger-hover-color": "#F39994",
  "text-danger-active-color": "#CA5261",
  "text-danger-disabled-color": "#F39994",
  "outline-color": "rgba(143, 155, 179, 0.16)",
  "color-primary-100": "#F7F3E5",
  "color-primary-200": "#F0E6CC",
  "color-primary-300": "#D2C4A3",
  "color-primary-400": "#A69677",
  "color-primary-500": "#6B5C43",
  "color-primary-600": "#5C4A30",
  "color-primary-700": "#4D3921",
  "color-primary-800": "#3E2A15",
  "color-primary-900": "#331F0C",
  "color-primary-transparent-100": "rgba(107, 92, 67, 0.08)",
  "color-primary-transparent-200": "rgba(107, 92, 67, 0.16)",
  "color-primary-transparent-300": "rgba(107, 92, 67, 0.24)",
  "color-primary-transparent-400": "rgba(107, 92, 67, 0.32)",
  "color-primary-transparent-500": "rgba(107, 92, 67, 0.4)",
  "color-primary-transparent-600": "rgba(107, 92, 67, 0.48)",
  "color-success-100": "#C6F8E3",
  "color-success-200": "#91F2D1",
  "color-success-300": "#56D8B8",
  "color-success-400": "#2CB19C",
  "color-success-500": "#007d75",
  "color-success-600": "#00686B",
  "color-success-700": "#004F59",
  "color-success-800": "#003948",
  "color-success-900": "#002A3B",
  "color-success-transparent-100": "rgba(0, 125, 117, 0.08)",
  "color-success-transparent-200": "rgba(0, 125, 117, 0.16)",
  "color-success-transparent-300": "rgba(0, 125, 117, 0.24)",
  "color-success-transparent-400": "rgba(0, 125, 117, 0.32)",
  "color-success-transparent-500": "rgba(0, 125, 117, 0.4)",
  "color-success-transparent-600": "rgba(0, 125, 117, 0.48)",
  "color-info-100": "#D6F2F9",
  "color-info-200": "#B0E2F4",
  "color-info-300": "#81C1DF",
  "color-info-400": "#5B99BF",
  "color-info-500": "#2d6895",
  "color-info-600": "#205180",
  "color-info-700": "#163D6B",
  "color-info-800": "#0E2B56",
  "color-info-900": "#081E47",
  "color-info-transparent-100": "rgba(45, 104, 149, 0.08)",
  "color-info-transparent-200": "rgba(45, 104, 149, 0.16)",
  "color-info-transparent-300": "rgba(45, 104, 149, 0.24)",
  "color-info-transparent-400": "rgba(45, 104, 149, 0.32)",
  "color-info-transparent-500": "rgba(45, 104, 149, 0.4)",
  "color-info-transparent-600": "rgba(45, 104, 149, 0.48)",
  "color-warning-100": "#FEFCE2",
  "color-warning-200": "#FDF9C6",
  "color-warning-300": "#FAF3A9",
  "color-warning-400": "#F6EC92",
  "color-warning-500": "#f1e26f",
  "color-warning-600": "#CFBF51",
  "color-warning-700": "#AD9D37",
  "color-warning-800": "#8B7B23",
  "color-warning-900": "#736415",
  "color-warning-transparent-100": "rgba(241, 226, 111, 0.08)",
  "color-warning-transparent-200": "rgba(241, 226, 111, 0.16)",
  "color-warning-transparent-300": "rgba(241, 226, 111, 0.24)",
  "color-warning-transparent-400": "rgba(241, 226, 111, 0.32)",
  "color-warning-transparent-500": "rgba(241, 226, 111, 0.4)",
  "color-warning-transparent-600": "rgba(241, 226, 111, 0.48)",
  "color-danger-100": "#FEECE3",
  "color-danger-200": "#FDD4C8",
  "color-danger-300": "#F9B6AB",
  "color-danger-400": "#F39994",
  "color-danger-500": "#ec7175",
  "color-danger-600": "#CA5261",
  "color-danger-700": "#A93850",
  "color-danger-800": "#882442",
  "color-danger-900": "#711539",
  "color-danger-transparent-100": "rgba(236, 113, 117, 0.08)",
  "color-danger-transparent-200": "rgba(236, 113, 117, 0.16)",
  "color-danger-transparent-300": "rgba(236, 113, 117, 0.24)",
  "color-danger-transparent-400": "rgba(236, 113, 117, 0.32)",
  "color-danger-transparent-500": "rgba(236, 113, 117, 0.4)",
  "color-danger-transparent-600": "rgba(236, 113, 117, 0.48)",
  "txt-color-blue-100": "#D6F2F9",
  "txt-color-blue-200": "#B0E2F4",
  "txt-color-blue-300": "#81C1DF",
  "txt-color-blue-400": "#5B99BF",
  "txt-color-blue-500": "#2d6895",
  "txt-color-blue-600": "#205180",
  "txt-color-blue-700": "#163D6B",
  "txt-color-blue-800": "#0E2B56",
  "txt-color-blue-900": "#081E47",
  "txt-color-blue-transparent-100": "rgba(45, 104, 149, 0.08)",
  "txt-color-blue-transparent-200": "rgba(45, 104, 149, 0.16)",
  "txt-color-blue-transparent-300": "rgba(45, 104, 149, 0.24)",
  "txt-color-blue-transparent-400": "rgba(45, 104, 149, 0.32)",
  "txt-color-blue-transparent-500": "rgba(45, 104, 149, 0.4)",
  "txt-color-blue-transparent-600": "rgba(45, 104, 149, 0.48)",
  "bg-color-blue-100": "#D6F2F9",
  "bg-color-blue-200": "#B0E2F4",
  "bg-color-blue-300": "#81C1DF",
  "bg-color-blue-400": "#5B99BF",
  "bg-color-blue-500": "#2d6895",
  "bg-color-blue-600": "#205180",
  "bg-color-blue-700": "#163D6B",
  "bg-color-blue-800": "#0E2B56",
  "bg-color-blue-900": "#081E47",
  "bg-color-blue-transparent-100": "rgba(45, 104, 149, 0.08)",
  "bg-color-blue-transparent-200": "rgba(45, 104, 149, 0.16)",
  "bg-color-blue-transparent-300": "rgba(45, 104, 149, 0.24)",
  "bg-color-blue-transparent-400": "rgba(45, 104, 149, 0.32)",
  "bg-color-blue-transparent-500": "rgba(45, 104, 149, 0.4)",
  "bg-color-blue-transparent-600": "rgba(45, 104, 149, 0.48)",
  "bg-color-blue-transparent-700": "rgba(45, 104, 149, 0.56)",
  "bg-color-blue-transparent-800": "rgba(45, 104, 149, 0.64)",
  "bg-color-blue-transparent-900": "rgba(45, 104, 149, 0.72)",
  "bg-color-blue-transparent-999": "rgba(45, 104, 149, 0.8)",
  "txt-color-brown-100": "#F7F3E5",
  "txt-color-brown-200": "#F0E6CC",
  "txt-color-brown-300": "#D2C4A3",
  "txt-color-brown-400": "#A69677",
  "txt-color-brown-500": "#6B5C43",
  "txt-color-brown-600": "#5C4A30",
  "txt-color-brown-700": "#4D3921",
  "txt-color-brown-800": "#3E2A15",
  "txt-color-brown-900": "#331F0C",
  "txt-color-brown-transparent-100": "rgba(107, 92, 67, 0.08)",
  "txt-color-brown-transparent-200": "rgba(107, 92, 67, 0.16)",
  "txt-color-brown-transparent-300": "rgba(107, 92, 67, 0.24)",
  "txt-color-brown-transparent-400": "rgba(107, 92, 67, 0.32)",
  "txt-color-brown-transparent-500": "rgba(107, 92, 67, 0.4)",
  "txt-color-brown-transparent-600": "rgba(107, 92, 67, 0.48)",
  "bg-color-brown-100": "#F7F3E5",
  "bg-color-brown-200": "#F0E6CC",
  "bg-color-brown-300": "#D2C4A3",
  "bg-color-brown-400": "#A69677",
  "bg-color-brown-500": "#6B5C43",
  "bg-color-brown-600": "#5C4A30",
  "bg-color-brown-700": "#4D3921",
  "bg-color-brown-800": "#3E2A15",
  "bg-color-brown-900": "#331F0C",
  "bg-color-brown-transparent-100": "rgba(107, 92, 67, 0.08)",
  "bg-color-brown-transparent-200": "rgba(107, 92, 67, 0.16)",
  "bg-color-brown-transparent-300": "rgba(107, 92, 67, 0.24)",
  "bg-color-brown-transparent-400": "rgba(107, 92, 67, 0.32)",
  "bg-color-brown-transparent-500": "rgba(107, 92, 67, 0.4)",
  "bg-color-brown-transparent-600": "rgba(107, 92, 67, 0.48)",
  "txt-color-blue-green-100": "#C6F8D8",
  "txt-color-blue-green-200": "#91F2BD",
  "txt-color-blue-green-300": "#56D99E",
  "txt-color-blue-green-400": "#2CB383",
  "txt-color-blue-green-500": "#008160",
  "txt-color-blue-green-600": "#006E5D",
  "txt-color-blue-green-700": "#005C57",
  "txt-color-blue-green-800": "#00484A",
  "txt-color-blue-green-900": "#00353D",
  "txt-color-blue-green-transparent-100": "rgba(0, 129, 96, 0.08)",
  "txt-color-blue-green-transparent-200": "rgba(0, 129, 96, 0.16)",
  "txt-color-blue-green-transparent-300": "rgba(0, 129, 96, 0.24)",
  "txt-color-blue-green-transparent-400": "rgba(0, 129, 96, 0.32)",
  "txt-color-blue-green-transparent-500": "rgba(0, 129, 96, 0.4)",
  "txt-color-blue-green-transparent-600": "rgba(0, 129, 96, 0.48)",
  "bg-color-blue-green-100": "#C6F8D8",
  "bg-color-blue-green-200": "#91F2BD",
  "bg-color-blue-green-300": "#56D99E",
  "bg-color-blue-green-400": "#2CB383",
  "bg-color-blue-green-500": "#008160",
  "bg-color-blue-green-600": "#006E5D",
  "bg-color-blue-green-700": "#005C57",
  "bg-color-blue-green-800": "#00484A",
  "bg-color-blue-green-900": "#00353D",
  "bg-color-blue-green-transparent-100": "rgba(0, 129, 96, 0.08)",
  "bg-color-blue-green-transparent-200": "rgba(0, 129, 96, 0.16)",
  "bg-color-blue-green-transparent-300": "rgba(0, 129, 96, 0.24)",
  "bg-color-blue-green-transparent-400": "rgba(0, 129, 96, 0.32)",
  "bg-color-blue-green-transparent-500": "rgba(0, 129, 96, 0.4)",
  "bg-color-blue-green-transparent-600": "rgba(0, 129, 96, 0.48)",
  "txt-color-coral-100": "#FEECE3",
  "txt-color-coral-200": "#FDD4C8",
  "txt-color-coral-300": "#F9B6AB",
  "txt-color-coral-400": "#F39994",
  "txt-color-coral-500": "#ec7175",
  "txt-color-coral-600": "#CA5261",
  "txt-color-coral-700": "#A93850",
  "txt-color-coral-800": "#882442",
  "txt-color-coral-900": "#711539",
  "txt-color-coral-transparent-100": "rgba(236, 113, 117, 0.08)",
  "txt-color-coral-transparent-200": "rgba(236, 113, 117, 0.16)",
  "txt-color-coral-transparent-300": "rgba(236, 113, 117, 0.24)",
  "txt-color-coral-transparent-400": "rgba(236, 113, 117, 0.32)",
  "txt-color-coral-transparent-500": "rgba(236, 113, 117, 0.4)",
  "txt-color-coral-transparent-600": "rgba(236, 113, 117, 0.48)",
  "bg-color-coral-100": "#FEECE3",
  "bg-color-coral-200": "#FDD4C8",
  "bg-color-coral-300": "#F9B6AB",
  "bg-color-coral-400": "#F39994",
  "bg-color-coral-500": "#ec7175",
  "bg-color-coral-600": "#CA5261",
  "bg-color-coral-700": "#A93850",
  "bg-color-coral-800": "#882442",
  "bg-color-coral-900": "#711539",
  "bg-color-coral-transparent-100": "rgba(236, 113, 117, 0.08)",
  "bg-color-coral-transparent-200": "rgba(236, 113, 117, 0.16)",
  "bg-color-coral-transparent-300": "rgba(236, 113, 117, 0.24)",
  "bg-color-coral-transparent-400": "rgba(236, 113, 117, 0.32)",
  "bg-color-coral-transparent-500": "rgba(236, 113, 117, 0.4)",
  "bg-color-coral-transparent-600": "rgba(236, 113, 117, 0.48)",
  "txt-color-dark-green-100": "#CDF6C4",
  "txt-color-dark-green-200": "#96ED8E",
  "txt-color-dark-green-300": "#50C951",
  "txt-color-dark-green-400": "#259430",
  "txt-color-dark-green-500": "#004d0f",
  "txt-color-dark-green-600": "#004213",
  "txt-color-dark-green-700": "#003715",
  "txt-color-dark-green-800": "#002C15",
  "txt-color-dark-green-900": "#002415",
  "txt-color-dark-green-transparent-100": "rgba(0, 77, 15, 0.08)",
  "txt-color-dark-green-transparent-200": "rgba(0, 77, 15, 0.16)",
  "txt-color-dark-green-transparent-300": "rgba(0, 77, 15, 0.24)",
  "txt-color-dark-green-transparent-400": "rgba(0, 77, 15, 0.32)",
  "txt-color-dark-green-transparent-500": "rgba(0, 77, 15, 0.4)",
  "txt-color-dark-green-transparent-600": "rgba(0, 77, 15, 0.48)",
  "bg-color-dark-green-100": "#CDF6C4",
  "bg-color-dark-green-200": "#96ED8E",
  "bg-color-dark-green-300": "#50C951",
  "bg-color-dark-green-400": "#259430",
  "bg-color-dark-green-500": "#004d0f",
  "bg-color-dark-green-600": "#004213",
  "bg-color-dark-green-700": "#003715",
  "bg-color-dark-green-800": "#002C15",
  "bg-color-dark-green-900": "#002415",
  "bg-color-dark-green-transparent-100": "rgba(0, 77, 15, 0.08)",
  "bg-color-dark-green-transparent-200": "rgba(0, 77, 15, 0.16)",
  "bg-color-dark-green-transparent-300": "rgba(0, 77, 15, 0.24)",
  "bg-color-dark-green-transparent-400": "rgba(0, 77, 15, 0.32)",
  "bg-color-dark-green-transparent-500": "rgba(0, 77, 15, 0.4)",
  "bg-color-dark-green-transparent-600": "rgba(0, 77, 15, 0.48)",
  "txt-color-dirty-yellow-100": "#FCF8D9",
  "txt-color-dirty-yellow-200": "#F9EFB4",
  "txt-color-dirty-yellow-300": "#EEDE8B",
  "txt-color-dirty-yellow-400": "#DEC96A",
  "txt-color-dirty-yellow-500": "#C8AC3D",
  "txt-color-dirty-yellow-600": "#AC8F2C",
  "txt-color-dirty-yellow-700": "#90741E",
  "txt-color-dirty-yellow-800": "#745A13",
  "txt-color-dirty-yellow-900": "#60470B",
  "txt-color-dirty-yellow-transparent-100": "rgba(200, 172, 61, 0.08)",
  "txt-color-dirty-yellow-transparent-200": "rgba(200, 172, 61, 0.16)",
  "txt-color-dirty-yellow-transparent-300": "rgba(200, 172, 61, 0.24)",
  "txt-color-dirty-yellow-transparent-400": "rgba(200, 172, 61, 0.32)",
  "txt-color-dirty-yellow-transparent-500": "rgba(200, 172, 61, 0.4)",
  "txt-color-dirty-yellow-transparent-600": "rgba(200, 172, 61, 0.48)",
  "bg-color-dirty-yellow-100": "#FCF8D9",
  "bg-color-dirty-yellow-200": "#F9EFB4",
  "bg-color-dirty-yellow-300": "#EEDE8B",
  "bg-color-dirty-yellow-400": "#DEC96A",
  "bg-color-dirty-yellow-500": "#C8AC3D",
  "bg-color-dirty-yellow-600": "#AC8F2C",
  "bg-color-dirty-yellow-700": "#90741E",
  "bg-color-dirty-yellow-800": "#745A13",
  "bg-color-dirty-yellow-900": "#60470B",
  "bg-color-dirty-yellow-transparent-100": "rgba(200, 172, 61, 0.08)",
  "bg-color-dirty-yellow-transparent-200": "rgba(200, 172, 61, 0.16)",
  "bg-color-dirty-yellow-transparent-300": "rgba(200, 172, 61, 0.24)",
  "bg-color-dirty-yellow-transparent-400": "rgba(200, 172, 61, 0.32)",
  "bg-color-dirty-yellow-transparent-500": "rgba(200, 172, 61, 0.4)",
  "bg-color-dirty-yellow-transparent-600": "rgba(200, 172, 61, 0.48)",
  "txt-color-fpo-100": "#FFCCD7",
  "txt-color-fpo-200": "#FF99BB",
  "txt-color-fpo-300": "#FF66A8",
  "txt-color-fpo-400": "#FF3FA5",
  "txt-color-fpo-500": "#ff00a1",
  "txt-color-fpo-600": "#DB00A0",
  "txt-color-fpo-700": "#B70098",
  "txt-color-fpo-800": "#930089",
  "txt-color-fpo-900": "#77007A",
  "txt-color-fpo-transparent-100": "rgba(255, 0, 161, 0.08)",
  "txt-color-fpo-transparent-200": "rgba(255, 0, 161, 0.16)",
  "txt-color-fpo-transparent-300": "rgba(255, 0, 161, 0.24)",
  "txt-color-fpo-transparent-400": "rgba(255, 0, 161, 0.32)",
  "txt-color-fpo-transparent-500": "rgba(255, 0, 161, 0.4)",
  "txt-color-fpo-transparent-600": "rgba(255, 0, 161, 0.48)",
  "bg-color-fpo-100": "#FFCCD7",
  "bg-color-fpo-200": "#FF99BB",
  "bg-color-fpo-300": "#FF66A8",
  "bg-color-fpo-400": "#FF3FA5",
  "bg-color-fpo-500": "#ff00a1",
  "bg-color-fpo-600": "#DB00A0",
  "bg-color-fpo-700": "#B70098",
  "bg-color-fpo-800": "#930089",
  "bg-color-fpo-900": "#77007A",
  "bg-color-fpo-transparent-100": "rgba(255, 0, 161, 0.08)",
  "bg-color-fpo-transparent-200": "rgba(255, 0, 161, 0.16)",
  "bg-color-fpo-transparent-300": "rgba(255, 0, 161, 0.24)",
  "bg-color-fpo-transparent-400": "rgba(255, 0, 161, 0.32)",
  "bg-color-fpo-transparent-500": "rgba(255, 0, 161, 0.4)",
  "bg-color-fpo-transparent-600": "rgba(255, 0, 161, 0.48)",
  "txt-color-gray-100": "#F5F5F5",
  "txt-color-gray-200": "#ECECEC",
  "txt-color-gray-300": "#C7C7C7",
  "txt-color-gray-400": "#909090",
  "txt-color-gray-500": "#464646",
  "txt-color-gray-600": "#3C3333",
  "txt-color-gray-700": "#322325",
  "txt-color-gray-800": "#28161B",
  "txt-color-gray-900": "#210D14",
  "txt-color-gray-transparent-100": "rgba(70, 70, 70, 0.08)",
  "txt-color-gray-transparent-200": "rgba(70, 70, 70, 0.16)",
  "txt-color-gray-transparent-300": "rgba(70, 70, 70, 0.24)",
  "txt-color-gray-transparent-400": "rgba(70, 70, 70, 0.32)",
  "txt-color-gray-transparent-500": "rgba(70, 70, 70, 0.4)",
  "txt-color-gray-transparent-600": "rgba(70, 70, 70, 0.48)",
  "bg-color-gray-100": "#F5F5F5",
  "bg-color-gray-200": "#ECECEC",
  "bg-color-gray-300": "#C7C7C7",
  "bg-color-gray-400": "#909090",
  "bg-color-gray-500": "#464646",
  "bg-color-gray-600": "#3C3333",
  "bg-color-gray-700": "#322325",
  "bg-color-gray-800": "#28161B",
  "bg-color-gray-900": "#210D14",
  "bg-color-gray-transparent-100": "rgba(70, 70, 70, 0.08)",
  "bg-color-gray-transparent-200": "rgba(70, 70, 70, 0.16)",
  "bg-color-gray-transparent-300": "rgba(70, 70, 70, 0.24)",
  "bg-color-gray-transparent-400": "rgba(70, 70, 70, 0.32)",
  "bg-color-gray-transparent-500": "rgba(70, 70, 70, 0.4)",
  "bg-color-gray-transparent-600": "rgba(70, 70, 70, 0.48)",
  "txt-color-green-100": "#CFFAD2",
  "txt-color-green-200": "#A2F6B0",
  "txt-color-green-300": "#70E68F",
  "txt-color-green-400": "#49CD7A",
  "txt-color-green-500": "#19ad5e",
  "txt-color-green-600": "#12945C",
  "txt-color-green-700": "#0C7C57",
  "txt-color-green-800": "#07644E",
  "txt-color-green-900": "#045348",
  "txt-color-green-transparent-100": "rgba(25, 173, 94, 0.08)",
  "txt-color-green-transparent-200": "rgba(25, 173, 94, 0.16)",
  "txt-color-green-transparent-300": "rgba(25, 173, 94, 0.24)",
  "txt-color-green-transparent-400": "rgba(25, 173, 94, 0.32)",
  "txt-color-green-transparent-500": "rgba(25, 173, 94, 0.4)",
  "txt-color-green-transparent-600": "rgba(25, 173, 94, 0.48)",
  "bg-color-green-100": "#CFFAD2",
  "bg-color-green-200": "#A2F6B0",
  "bg-color-green-300": "#70E68F",
  "bg-color-green-400": "#49CD7A",
  "bg-color-green-500": "#19ad5e",
  "bg-color-green-600": "#12945C",
  "bg-color-green-700": "#0C7C57",
  "bg-color-green-800": "#07644E",
  "bg-color-green-900": "#045348",
  "bg-color-green-transparent-100": "rgba(25, 173, 94, 0.08)",
  "bg-color-green-transparent-200": "rgba(25, 173, 94, 0.16)",
  "bg-color-green-transparent-300": "rgba(25, 173, 94, 0.24)",
  "bg-color-green-transparent-400": "rgba(25, 173, 94, 0.32)",
  "bg-color-green-transparent-500": "rgba(25, 173, 94, 0.4)",
  "bg-color-green-transparent-600": "rgba(25, 173, 94, 0.48)",
  "txt-color-light-blue-100": "#ECFDFC",
  "txt-color-light-blue-200": "#DAFBFC",
  "txt-color-light-blue-300": "#C5F2F6",
  "txt-color-light-blue-400": "#B3E5EE",
  "txt-color-light-blue-500": "#98d2e3",
  "txt-color-light-blue-600": "#6FAAC3",
  "txt-color-light-blue-700": "#4C83A3",
  "txt-color-light-blue-800": "#305E83",
  "txt-color-light-blue-900": "#1D436C",
  "txt-color-light-blue-transparent-100": "rgba(152, 210, 227, 0.08)",
  "txt-color-light-blue-transparent-200": "rgba(152, 210, 227, 0.16)",
  "txt-color-light-blue-transparent-300": "rgba(152, 210, 227, 0.24)",
  "txt-color-light-blue-transparent-400": "rgba(152, 210, 227, 0.32)",
  "txt-color-light-blue-transparent-500": "rgba(152, 210, 227, 0.4)",
  "txt-color-light-blue-transparent-600": "rgba(152, 210, 227, 0.48)",
  "bg-color-light-blue-100": "#ECFDFC",
  "bg-color-light-blue-200": "#DAFBFC",
  "bg-color-light-blue-300": "#C5F2F6",
  "bg-color-light-blue-400": "#B3E5EE",
  "bg-color-light-blue-500": "#98d2e3",
  "bg-color-light-blue-600": "#6FAAC3",
  "bg-color-light-blue-700": "#4C83A3",
  "bg-color-light-blue-800": "#305E83",
  "bg-color-light-blue-900": "#1D436C",
  "bg-color-light-blue-transparent-100": "rgba(152, 210, 227, 0.08)",
  "bg-color-light-blue-transparent-200": "rgba(152, 210, 227, 0.16)",
  "bg-color-light-blue-transparent-300": "rgba(152, 210, 227, 0.24)",
  "bg-color-light-blue-transparent-400": "rgba(152, 210, 227, 0.32)",
  "bg-color-light-blue-transparent-500": "rgba(152, 210, 227, 0.4)",
  "bg-color-light-blue-transparent-600": "rgba(152, 210, 227, 0.48)",
  "txt-color-light-blue-2-100": "#F4FEFE",
  "txt-color-light-blue-2-200": "#EAFDFE",
  "txt-color-light-blue-2-300": "#DFF9FC",
  "txt-color-light-blue-2-400": "#D6F4FA",
  "txt-color-light-blue-2-500": "#C8ECF7",
  "txt-color-light-blue-2-600": "#92C0D4",
  "txt-color-light-blue-2-700": "#6494B1",
  "txt-color-light-blue-2-800": "#3F6B8F",
  "txt-color-light-blue-2-900": "#264C76",
  "txt-color-light-blue-2-transparent-100": "rgba(200, 236, 247, 0.08)",
  "txt-color-light-blue-2-transparent-200": "rgba(200, 236, 247, 0.16)",
  "txt-color-light-blue-2-transparent-300": "rgba(200, 236, 247, 0.24)",
  "txt-color-light-blue-2-transparent-400": "rgba(200, 236, 247, 0.32)",
  "txt-color-light-blue-2-transparent-500": "rgba(200, 236, 247, 0.4)",
  "txt-color-light-blue-2-transparent-600": "rgba(200, 236, 247, 0.48)",
  "bg-color-light-blue-2-100": "#F4FEFE",
  "bg-color-light-blue-2-200": "#EAFDFE",
  "bg-color-light-blue-2-300": "#DFF9FC",
  "bg-color-light-blue-2-400": "#D6F4FA",
  "bg-color-light-blue-2-500": "#C8ECF7",
  "bg-color-light-blue-2-600": "#92C0D4",
  "bg-color-light-blue-2-700": "#6494B1",
  "bg-color-light-blue-2-800": "#3F6B8F",
  "bg-color-light-blue-2-900": "#264C76",
  "bg-color-light-blue-2-transparent-100": "rgba(200, 236, 247, 0.08)",
  "bg-color-light-blue-2-transparent-200": "rgba(200, 236, 247, 0.16)",
  "bg-color-light-blue-2-transparent-300": "rgba(200, 236, 247, 0.24)",
  "bg-color-light-blue-2-transparent-400": "rgba(200, 236, 247, 0.32)",
  "bg-color-light-blue-2-transparent-500": "rgba(200, 236, 247, 0.4)",
  "bg-color-light-blue-2-transparent-600": "rgba(200, 236, 247, 0.48)",
  "txt-color-light-brown-100": "#F8F4E8",
  "txt-color-light-brown-200": "#F1E9D2",
  "txt-color-light-brown-300": "#D7CAAD",
  "txt-color-light-brown-400": "#AFA184",
  "txt-color-light-brown-500": "#7b6c53",
  "txt-color-light-brown-600": "#69573C",
  "txt-color-light-brown-700": "#584329",
  "txt-color-light-brown-800": "#47311A",
  "txt-color-light-brown-900": "#3B240F",
  "txt-color-light-brown-transparent-100": "rgba(123, 108, 83, 0.08)",
  "txt-color-light-brown-transparent-200": "rgba(123, 108, 83, 0.16)",
  "txt-color-light-brown-transparent-300": "rgba(123, 108, 83, 0.24)",
  "txt-color-light-brown-transparent-400": "rgba(123, 108, 83, 0.32)",
  "txt-color-light-brown-transparent-500": "rgba(123, 108, 83, 0.4)",
  "txt-color-light-brown-transparent-600": "rgba(123, 108, 83, 0.48)",
  "bg-color-light-brown-100": "#F8F4E8",
  "bg-color-light-brown-200": "#F1E9D2",
  "bg-color-light-brown-300": "#D7CAAD",
  "bg-color-light-brown-400": "#AFA184",
  "bg-color-light-brown-500": "#7b6c53",
  "bg-color-light-brown-600": "#69573C",
  "bg-color-light-brown-700": "#584329",
  "bg-color-light-brown-800": "#47311A",
  "bg-color-light-brown-900": "#3B240F",
  "bg-color-light-brown-transparent-100": "rgba(123, 108, 83, 0.08)",
  "bg-color-light-brown-transparent-200": "rgba(123, 108, 83, 0.16)",
  "bg-color-light-brown-transparent-300": "rgba(123, 108, 83, 0.24)",
  "bg-color-light-brown-transparent-400": "rgba(123, 108, 83, 0.32)",
  "bg-color-light-brown-transparent-500": "rgba(123, 108, 83, 0.4)",
  "bg-color-light-brown-transparent-600": "rgba(123, 108, 83, 0.48)",
  "txt-color-light-orange-100": "#FEF3E0",
  "txt-color-light-orange-200": "#FDE4C2",
  "txt-color-light-orange-300": "#FBD0A2",
  "txt-color-light-orange-400": "#F7BD8A",
  "txt-color-light-orange-500": "#f39e64",
  "txt-color-light-orange-600": "#D07849",
  "txt-color-light-orange-700": "#AE5632",
  "txt-color-light-orange-800": "#8C391F",
  "txt-color-light-orange-900": "#742313",
  "txt-color-light-orange-transparent-100": "rgba(243, 158, 100, 0.08)",
  "txt-color-light-orange-transparent-200": "rgba(243, 158, 100, 0.16)",
  "txt-color-light-orange-transparent-300": "rgba(243, 158, 100, 0.24)",
  "txt-color-light-orange-transparent-400": "rgba(243, 158, 100, 0.32)",
  "txt-color-light-orange-transparent-500": "rgba(243, 158, 100, 0.4)",
  "txt-color-light-orange-transparent-600": "rgba(243, 158, 100, 0.48)",
  "bg-color-light-orange-100": "#FEF3E0",
  "bg-color-light-orange-200": "#FDE4C2",
  "bg-color-light-orange-300": "#FBD0A2",
  "bg-color-light-orange-400": "#F7BD8A",
  "bg-color-light-orange-500": "#f39e64",
  "bg-color-light-orange-600": "#D07849",
  "bg-color-light-orange-700": "#AE5632",
  "bg-color-light-orange-800": "#8C391F",
  "bg-color-light-orange-900": "#742313",
  "bg-color-light-orange-transparent-100": "rgba(243, 158, 100, 0.08)",
  "bg-color-light-orange-transparent-200": "rgba(243, 158, 100, 0.16)",
  "bg-color-light-orange-transparent-300": "rgba(243, 158, 100, 0.24)",
  "bg-color-light-orange-transparent-400": "rgba(243, 158, 100, 0.32)",
  "bg-color-light-orange-transparent-500": "rgba(243, 158, 100, 0.4)",
  "bg-color-light-orange-transparent-600": "rgba(243, 158, 100, 0.48)",
  "txt-color-light-yellow-100": "#FFFEF0",
  "txt-color-light-yellow-200": "#FFFDE2",
  "txt-color-light-yellow-300": "#FFFBD3",
  "txt-color-light-yellow-400": "#FFFAC9",
  "txt-color-light-yellow-500": "#fff8b7",
  "txt-color-light-yellow-600": "#DBD285",
  "txt-color-light-yellow-700": "#B7AC5C",
  "txt-color-light-yellow-800": "#93883A",
  "txt-color-light-yellow-900": "#7A6E23",
  "txt-color-light-yellow-transparent-100": "rgba(255, 248, 183, 0.08)",
  "txt-color-light-yellow-transparent-200": "rgba(255, 248, 183, 0.16)",
  "txt-color-light-yellow-transparent-300": "rgba(255, 248, 183, 0.24)",
  "txt-color-light-yellow-transparent-400": "rgba(255, 248, 183, 0.32)",
  "txt-color-light-yellow-transparent-500": "rgba(255, 248, 183, 0.4)",
  "txt-color-light-yellow-transparent-600": "rgba(255, 248, 183, 0.48)",
  "bg-color-light-yellow-100": "#FFFEF0",
  "bg-color-light-yellow-200": "#FFFDE2",
  "bg-color-light-yellow-300": "#FFFBD3",
  "bg-color-light-yellow-400": "#FFFAC9",
  "bg-color-light-yellow-500": "#fff8b7",
  "bg-color-light-yellow-600": "#DBD285",
  "bg-color-light-yellow-700": "#B7AC5C",
  "bg-color-light-yellow-800": "#93883A",
  "bg-color-light-yellow-900": "#7A6E23",
  "bg-color-light-yellow-transparent-100": "rgba(255, 248, 183, 0.08)",
  "bg-color-light-yellow-transparent-200": "rgba(255, 248, 183, 0.16)",
  "bg-color-light-yellow-transparent-300": "rgba(255, 248, 183, 0.24)",
  "bg-color-light-yellow-transparent-400": "rgba(255, 248, 183, 0.32)",
  "bg-color-light-yellow-transparent-500": "rgba(255, 248, 183, 0.4)",
  "bg-color-light-yellow-transparent-600": "rgba(255, 248, 183, 0.48)",
  "txt-color-mint-100": "#E8FDEA",
  "txt-color-mint-200": "#D3FBDA",
  "txt-color-mint-300": "#B9F3C8",
  "txt-color-mint-400": "#A2E7BB",
  "txt-color-mint-500": "#82d7aa",
  "txt-color-mint-600": "#5FB892",
  "txt-color-mint-700": "#419A7D",
  "txt-color-mint-800": "#297C69",
  "txt-color-mint-900": "#18675D",
  "txt-color-mint-transparent-100": "rgba(130, 215, 170, 0.08)",
  "txt-color-mint-transparent-200": "rgba(130, 215, 170, 0.16)",
  "txt-color-mint-transparent-300": "rgba(130, 215, 170, 0.24)",
  "txt-color-mint-transparent-400": "rgba(130, 215, 170, 0.32)",
  "txt-color-mint-transparent-500": "rgba(130, 215, 170, 0.4)",
  "txt-color-mint-transparent-600": "rgba(130, 215, 170, 0.48)",
  "bg-color-mint-100": "#E8FDEA",
  "bg-color-mint-200": "#D3FBDA",
  "bg-color-mint-300": "#B9F3C8",
  "bg-color-mint-400": "#A2E7BB",
  "bg-color-mint-500": "#82d7aa",
  "bg-color-mint-600": "#5FB892",
  "bg-color-mint-700": "#419A7D",
  "bg-color-mint-800": "#297C69",
  "bg-color-mint-900": "#18675D",
  "bg-color-mint-transparent-100": "rgba(130, 215, 170, 0.08)",
  "bg-color-mint-transparent-200": "rgba(130, 215, 170, 0.16)",
  "bg-color-mint-transparent-300": "rgba(130, 215, 170, 0.24)",
  "bg-color-mint-transparent-400": "rgba(130, 215, 170, 0.32)",
  "bg-color-mint-transparent-500": "rgba(130, 215, 170, 0.4)",
  "bg-color-mint-transparent-600": "rgba(130, 215, 170, 0.48)",
  "bg-color-mint-transparent-700": "rgba(130, 215, 170, 0.56)",
  "bg-color-mint-transparent-800": "rgba(130, 215, 170, 0.64)",
  "bg-color-mint-transparent-900": "rgba(130, 215, 170, 0.72)",
  "bg-color-mint-transparent-999": "rgba(130, 215, 170, 0.8)",
  "txt-color-off-white-100": "#FEFEFD",
  "txt-color-off-white-200": "#FEFEFB",
  "txt-color-off-white-300": "#FCFCF8",
  "txt-color-off-white-400": "#FAFAF4",
  "txt-color-off-white-500": "#F8F8F0",
  "txt-color-off-white-600": "#D5D5AF",
  "txt-color-off-white-700": "#B2B278",
  "txt-color-off-white-800": "#8F8F4C",
  "txt-color-off-white-900": "#77772E",
  "txt-color-off-white-transparent-100": "rgba(248, 248, 240, 0.08)",
  "txt-color-off-white-transparent-200": "rgba(248, 248, 240, 0.16)",
  "txt-color-off-white-transparent-300": "rgba(248, 248, 240, 0.24)",
  "txt-color-off-white-transparent-400": "rgba(248, 248, 240, 0.32)",
  "txt-color-off-white-transparent-500": "rgba(248, 248, 240, 0.4)",
  "txt-color-off-white-transparent-600": "rgba(248, 248, 240, 0.48)",
  "bg-color-off-white-100": "#FEFEFD",
  "bg-color-off-white-200": "#FEFEFB",
  "bg-color-off-white-300": "#FCFCF8",
  "bg-color-off-white-400": "#FAFAF4",
  "bg-color-off-white-500": "#F8F8F0",
  "bg-color-off-white-600": "#D5D5AF",
  "bg-color-off-white-700": "#B2B278",
  "bg-color-off-white-800": "#8F8F4C",
  "bg-color-off-white-900": "#77772E",
  "bg-color-off-white-transparent-100": "rgba(248, 248, 240, 0.08)",
  "bg-color-off-white-transparent-200": "rgba(248, 248, 240, 0.16)",
  "bg-color-off-white-transparent-300": "rgba(248, 248, 240, 0.24)",
  "bg-color-off-white-transparent-400": "rgba(248, 248, 240, 0.32)",
  "bg-color-off-white-transparent-500": "rgba(248, 248, 240, 0.4)",
  "bg-color-off-white-transparent-600": "rgba(248, 248, 240, 0.48)",
  "txt-color-orange-100": "#FEF0D9",
  "txt-color-orange-200": "#FDDCB3",
  "txt-color-orange-300": "#FAC38C",
  "txt-color-orange-400": "#F5AA6F",
  "txt-color-orange-500": "#ef8341",
  "txt-color-orange-600": "#CD612F",
  "txt-color-orange-700": "#AC4420",
  "txt-color-orange-800": "#8A2C14",
  "txt-color-orange-900": "#721A0C",
  "txt-color-orange-transparent-100": "rgba(239, 131, 65, 0.08)",
  "txt-color-orange-transparent-200": "rgba(239, 131, 65, 0.16)",
  "txt-color-orange-transparent-300": "rgba(239, 131, 65, 0.24)",
  "txt-color-orange-transparent-400": "rgba(239, 131, 65, 0.32)",
  "txt-color-orange-transparent-500": "rgba(239, 131, 65, 0.4)",
  "txt-color-orange-transparent-600": "rgba(239, 131, 65, 0.48)",
  "bg-color-orange-100": "#FEF0D9",
  "bg-color-orange-200": "#FDDCB3",
  "bg-color-orange-300": "#FAC38C",
  "bg-color-orange-400": "#F5AA6F",
  "bg-color-orange-500": "#ef8341",
  "bg-color-orange-600": "#CD612F",
  "bg-color-orange-700": "#AC4420",
  "bg-color-orange-800": "#8A2C14",
  "bg-color-orange-900": "#721A0C",
  "bg-color-orange-transparent-100": "rgba(239, 131, 65, 0.08)",
  "bg-color-orange-transparent-200": "rgba(239, 131, 65, 0.16)",
  "bg-color-orange-transparent-300": "rgba(239, 131, 65, 0.24)",
  "bg-color-orange-transparent-400": "rgba(239, 131, 65, 0.32)",
  "bg-color-orange-transparent-500": "rgba(239, 131, 65, 0.4)",
  "bg-color-orange-transparent-600": "rgba(239, 131, 65, 0.48)",
  "txt-color-sand-100": "#FEFDF2",
  "txt-color-sand-200": "#FEFBE5",
  "txt-color-sand-300": "#FCF8D8",
  "txt-color-sand-400": "#FAF4CD",
  "txt-color-sand-500": "#f8eebc",
  "txt-color-sand-600": "#D5C789",
  "txt-color-sand-700": "#B2A15E",
  "txt-color-sand-800": "#8F7D3B",
  "txt-color-sand-900": "#776324",
  "txt-color-sand-transparent-100": "rgba(248, 238, 188, 0.08)",
  "txt-color-sand-transparent-200": "rgba(248, 238, 188, 0.16)",
  "txt-color-sand-transparent-300": "rgba(248, 238, 188, 0.24)",
  "txt-color-sand-transparent-400": "rgba(248, 238, 188, 0.32)",
  "txt-color-sand-transparent-500": "rgba(248, 238, 188, 0.4)",
  "txt-color-sand-transparent-600": "rgba(248, 238, 188, 0.48)",
  "bg-color-sand-100": "#FEFDF2",
  "bg-color-sand-200": "#FEFBE5",
  "bg-color-sand-300": "#FCF8D8",
  "bg-color-sand-400": "#FAF4CD",
  "bg-color-sand-500": "#f8eebc",
  "bg-color-sand-600": "#D5C789",
  "bg-color-sand-700": "#B2A15E",
  "bg-color-sand-800": "#8F7D3B",
  "bg-color-sand-900": "#776324",
  "bg-color-sand-transparent-100": "rgba(248, 238, 188, 0.08)",
  "bg-color-sand-transparent-200": "rgba(248, 238, 188, 0.16)",
  "bg-color-sand-transparent-300": "rgba(248, 238, 188, 0.24)",
  "bg-color-sand-transparent-400": "rgba(248, 238, 188, 0.32)",
  "bg-color-sand-transparent-500": "rgba(248, 238, 188, 0.4)",
  "bg-color-sand-transparent-600": "rgba(248, 238, 188, 0.48)",
  "txt-color-tan-100": "#FCF7EE",
  "txt-color-tan-200": "#FAEFDD",
  "txt-color-tan-300": "#F1DFC7",
  "txt-color-tan-400": "#E3CBB2",
  "txt-color-tan-500": "#d2b195",
  "txt-color-tan-600": "#B4896C",
  "txt-color-tan-700": "#97654B",
  "txt-color-tan-800": "#79452F",
  "txt-color-tan-900": "#642E1C",
  "txt-color-tan-transparent-100": "rgba(210, 177, 149, 0.08)",
  "txt-color-tan-transparent-200": "rgba(210, 177, 149, 0.16)",
  "txt-color-tan-transparent-300": "rgba(210, 177, 149, 0.24)",
  "txt-color-tan-transparent-400": "rgba(210, 177, 149, 0.32)",
  "txt-color-tan-transparent-500": "rgba(210, 177, 149, 0.4)",
  "txt-color-tan-transparent-600": "rgba(210, 177, 149, 0.48)",
  "bg-color-tan-100": "#FCF7EE",
  "bg-color-tan-200": "#FAEFDD",
  "bg-color-tan-300": "#F1DFC7",
  "bg-color-tan-400": "#E3CBB2",
  "bg-color-tan-500": "#d2b195",
  "bg-color-tan-600": "#B4896C",
  "bg-color-tan-700": "#97654B",
  "bg-color-tan-800": "#79452F",
  "bg-color-tan-900": "#642E1C",
  "bg-color-tan-transparent-100": "rgba(210, 177, 149, 0.08)",
  "bg-color-tan-transparent-200": "rgba(210, 177, 149, 0.16)",
  "bg-color-tan-transparent-300": "rgba(210, 177, 149, 0.24)",
  "bg-color-tan-transparent-400": "rgba(210, 177, 149, 0.32)",
  "bg-color-tan-transparent-500": "rgba(210, 177, 149, 0.4)",
  "bg-color-tan-transparent-600": "rgba(210, 177, 149, 0.48)",
  "txt-color-teal-100": "#C6F8E3",
  "txt-color-teal-200": "#91F2D1",
  "txt-color-teal-300": "#56D8B8",
  "txt-color-teal-400": "#2CB19C",
  "txt-color-teal-500": "#007d75",
  "txt-color-teal-600": "#00686B",
  "txt-color-teal-700": "#004F59",
  "txt-color-teal-800": "#003948",
  "txt-color-teal-900": "#002A3B",
  "txt-color-teal-transparent-100": "rgba(0, 125, 117, 0.08)",
  "txt-color-teal-transparent-200": "rgba(0, 125, 117, 0.16)",
  "txt-color-teal-transparent-300": "rgba(0, 125, 117, 0.24)",
  "txt-color-teal-transparent-400": "rgba(0, 125, 117, 0.32)",
  "txt-color-teal-transparent-500": "rgba(0, 125, 117, 0.4)",
  "txt-color-teal-transparent-600": "rgba(0, 125, 117, 0.48)",
  "bg-color-teal-100": "#C6F8E3",
  "bg-color-teal-200": "#91F2D1",
  "bg-color-teal-300": "#56D8B8",
  "bg-color-teal-400": "#2CB19C",
  "bg-color-teal-500": "#007d75",
  "bg-color-teal-600": "#00686B",
  "bg-color-teal-700": "#004F59",
  "bg-color-teal-800": "#003948",
  "bg-color-teal-900": "#002A3B",
  "bg-color-teal-transparent-100": "rgba(0, 125, 117, 0.08)",
  "bg-color-teal-transparent-200": "rgba(0, 125, 117, 0.16)",
  "bg-color-teal-transparent-300": "rgba(0, 125, 117, 0.24)",
  "bg-color-teal-transparent-400": "rgba(0, 125, 117, 0.32)",
  "bg-color-teal-transparent-500": "rgba(0, 125, 117, 0.4)",
  "bg-color-teal-transparent-600": "rgba(0, 125, 117, 0.48)",
  "txt-color-white-100": "#FFFFFF",
  "txt-color-white-200": "#FFFFFF",
  "txt-color-white-300": "#FFFFFF",
  "txt-color-white-400": "#FFFFFF",
  "txt-color-white-500": "#FFF",
  "txt-color-white-600": "#DBBABD",
  "txt-color-white-700": "#B7808A",
  "txt-color-white-800": "#935162",
  "txt-color-white-900": "#7A304A",
  "txt-color-white-transparent-100": "rgba(255, 255, 255, 0.08)",
  "txt-color-white-transparent-200": "rgba(255, 255, 255, 0.16)",
  "txt-color-white-transparent-300": "rgba(255, 255, 255, 0.24)",
  "txt-color-white-transparent-400": "rgba(255, 255, 255, 0.32)",
  "txt-color-white-transparent-500": "rgba(255, 255, 255, 0.4)",
  "txt-color-white-transparent-600": "rgba(255, 255, 255, 0.48)",
  "bg-color-white-100": "#FFFFFF",
  "bg-color-white-200": "#FFFFFF",
  "bg-color-white-300": "#FFFFFF",
  "bg-color-white-400": "#FFFFFF",
  "bg-color-white-500": "#FFF",
  "bg-color-white-600": "#DBBABD",
  "bg-color-white-700": "#B7808A",
  "bg-color-white-800": "#935162",
  "bg-color-white-900": "#7A304A",
  "bg-color-white-transparent-100": "rgba(255, 255, 255, 0.08)",
  "bg-color-white-transparent-200": "rgba(255, 255, 255, 0.16)",
  "bg-color-white-transparent-300": "rgba(255, 255, 255, 0.24)",
  "bg-color-white-transparent-400": "rgba(255, 255, 255, 0.32)",
  "bg-color-white-transparent-500": "rgba(255, 255, 255, 0.4)",
  "bg-color-white-transparent-600": "rgba(255, 255, 255, 0.48)",
  "txt-color-yellow-100": "#FEFCE2",
  "txt-color-yellow-200": "#FDF9C6",
  "txt-color-yellow-300": "#FAF3A9",
  "txt-color-yellow-400": "#F6EC92",
  "txt-color-yellow-500": "#f1e26f",
  "txt-color-yellow-600": "#CFBF51",
  "txt-color-yellow-700": "#AD9D37",
  "txt-color-yellow-800": "#8B7B23",
  "txt-color-yellow-900": "#736415",
  "txt-color-yellow-transparent-100": "rgba(241, 226, 111, 0.08)",
  "txt-color-yellow-transparent-200": "rgba(241, 226, 111, 0.16)",
  "txt-color-yellow-transparent-300": "rgba(241, 226, 111, 0.24)",
  "txt-color-yellow-transparent-400": "rgba(241, 226, 111, 0.32)",
  "txt-color-yellow-transparent-500": "rgba(241, 226, 111, 0.4)",
  "txt-color-yellow-transparent-600": "rgba(241, 226, 111, 0.48)",
  "bg-color-yellow-100": "#FEFCE2",
  "bg-color-yellow-200": "#FDF9C6",
  "bg-color-yellow-300": "#FAF3A9",
  "bg-color-yellow-400": "#F6EC92",
  "bg-color-yellow-500": "#f1e26f",
  "bg-color-yellow-600": "#CFBF51",
  "bg-color-yellow-700": "#AD9D37",
  "bg-color-yellow-800": "#8B7B23",
  "bg-color-yellow-900": "#736415",
  "bg-color-yellow-transparent-100": "rgba(241, 226, 111, 0.08)",
  "bg-color-yellow-transparent-200": "rgba(241, 226, 111, 0.16)",
  "bg-color-yellow-transparent-300": "rgba(241, 226, 111, 0.24)",
  "bg-color-yellow-transparent-400": "rgba(241, 226, 111, 0.32)",
  "bg-color-yellow-transparent-500": "rgba(241, 226, 111, 0.4)",
  "bg-color-yellow-transparent-600": "rgba(241, 226, 111, 0.48)",
  "bg-color-yellow-transparent-700": "rgba(241, 226, 111, 0.56)",
  "bg-color-yellow-transparent-800": "rgba(241, 226, 111, 0.64)",
  "bg-color-yellow-transparent-900": "rgba(241, 226, 111, 0.72)",
  "bg-color-yellow-transparent-999": "rgba(241, 226, 111, 0.8)",
  "text-card-navy": "#327a93",
  "text-card-red": "rgba(236,113,117,0.7)",
  "text-card-yellow": "rgba(241,226,111,0.8)",
};
