import React from "react";
import * as R from "ramda";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";

export interface PriceTagListProps {
  emptyLabel?: string;
  containerStyle?: React.CSSProperties;
  style?: React.CSSProperties;
}

export const PriceTagList: React.FC<PriceTagListProps> = ({
  children,
  emptyLabel,
  style,
  containerStyle,
}) => {
  const { t } = useTranslation("PriceTagList");

  const interspersedChildren = React.useMemo(
    () =>
      R.intersperse(
        <Typography variant="caption" style={{ color: "#8F8F4C" }}>
          or
        </Typography>,
        React.Children.toArray(children).filter((child) => !R.isNil(child))
      ),
    [children]
  );

  if (React.Children.count(children) === 0) {
    return (
      <Box
        style={{
          flex: 1,
          backgroundColor: "white",
          padding: 16,
          borderRadius: 8,
          ...containerStyle,
        }}
      >
        <Typography
          variant="subtitle2"
          style={{ textAlign: "center", color: "#8F8F4C" }}
        >
          {emptyLabel || t("label.emptyList")!}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      style={{
        flex: 1,
        backgroundColor: "white",
        borderRadius: 8,
        overflow: "auto",
        ...containerStyle,
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: 16,
          ...style,
        }}
      >
        {interspersedChildren}
      </Box>
    </Box>
  );
};
