import {
  Breadcrumbs,
  Link,
  CircularProgress,
  Box,
  Icon,
  Button,
} from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";
import { Announcement } from "../../components/Announcement";

import { Space } from "../../components/Space";
import { useParams } from "react-router-dom";
import { AuthContext } from "modules/auth/AuthContext";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import Row from "components/Row";
import PostItem from "pages/Campsite/components/PostItem";
import usePost from "hooks/usePost";

export const PostPage: React.FC = () => {
  const { t } = useTranslation("PostScreen");

  const { postId } = useParams<{ postId: string }>();

  const user = React.useContext(AuthContext);
  const post = usePost(postId);

  const universalLink = post
    ? `https://acnh.link/post/${postId}`
    : `https://acnh.link`;

  // TODO: reduce duplication
  if (post === null) {
    return (
      <>
        <Helmet>
          <title>Campsite - Link - The ACNH Community</title>
          <meta
            name="apple-itunes-app"
            content={`app-id=3Y3ND925XU, app-argument=${universalLink}`}
          />
        </Helmet>
        <Announcement />
        <Space size={2} />
        <Breadcrumbs aria-label="breadcrumb">
          <Link style={{ fontSize: "0.8em" }} color="inherit" href="/">
            Home
          </Link>
          <Link style={{ fontSize: "0.8em" }} color="inherit" href="/">
            Campsite
          </Link>
          <Link
            style={{ fontSize: "0.8em" }}
            color="textPrimary"
            aria-current="page"
          >
            Post
          </Link>
        </Breadcrumbs>
        <Space size={2} />
        <Alert variant="outlined" severity="warning">
          Couldn't find this post.
        </Alert>
      </>
    );
  }

  if (post === undefined) {
    return (
      <>
        <Helmet>
          <title>Campsite - Link - The ACNH Community</title>
          <meta
            name="apple-itunes-app"
            content={`app-id=3Y3ND925XU, app-argument=${universalLink}`}
          />
        </Helmet>
        <Announcement />
        <Space size={2} />
        <Breadcrumbs aria-label="breadcrumb">
          <Link style={{ fontSize: "0.8em" }} color="inherit" href="/">
            Home
          </Link>
          <Link style={{ fontSize: "0.8em" }} color="inherit" href="/">
            Campsite
          </Link>
          <Link
            style={{ fontSize: "0.8em" }}
            color="textPrimary"
            aria-current="page"
          >
            Post
          </Link>
        </Breadcrumbs>
        <Space size={2} />
        <Row center>
          <CircularProgress />
        </Row>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Campsite - Link - The ACNH Community</title>
        <meta
          name="apple-itunes-app"
          content={`app-id=3Y3ND925XU, app-argument=${universalLink}`}
        />
      </Helmet>
      <Announcement />
      <Space size={2} />
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          style={{ fontSize: "0.8em" }}
          color="inherit"
          href="/"
          onClick={() => {}}
        >
          Home
        </Link>
        <Link style={{ fontSize: "0.8em" }} color="inherit" href="/">
          Campsite
        </Link>
        <Link
          style={{ fontSize: "0.8em" }}
          color="textPrimary"
          aria-current="page"
        >
          Post
        </Link>
      </Breadcrumbs>
      <Space size={2} />
      <Alert
        severity="warning"
        icon={<Icon className="fas fa-star" style={{ fontSize: 16 }} />}
        action={
          <Button
            variant="contained"
            size="small"
            color="primary"
            href="https://acnhlink.page.link/app"
            style={{ textTransform: "unset" }}
          >
            Download now!
          </Button>
        }
      >
        Comment, like, reply, private message, review. Download the app and
        interact with this post now!
      </Alert>
      <Space size={2} />
      <Alert
        severity="warning"
        icon={<Icon className="fas fa-thumbs-up" style={{ fontSize: 16 }} />}
        action={
          <Button
            variant="contained"
            size="small"
            color="primary"
            href={universalLink}
            style={{ textTransform: "unset" }}
          >
            Open!
          </Button>
        }
      >
        Already installed? Open in the app!
      </Alert>
      <Space size={2} />
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <PostItem post={post} style={{ maxWidth: 480, width: "100%" }} />
      </Box>
    </>
  );
};
