import React from "react";
import { Helmet } from "react-helmet";

import { Hero } from "./Hero/Hero";
import { Showcase } from "../../components/Showcase/Showcase";
import { Space } from "../../components/Space";
import { Announcement } from "../../components/Announcement";

export const HomePage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Link - The ACNH Community</title>
        <meta
          name="apple-itunes-app"
          content="app-id=3Y3ND925XU, app-argument=https://acnh.link"
        />
      </Helmet>
      <Announcement />
      <Space size={2} />
      <Hero />
      <Space size={4} />
      <Showcase />
    </>
  );
};
