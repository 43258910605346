import {
  Backdrop,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { Space } from "./Space";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export interface InProgressModalProps {
  visible?: boolean;
}

export const InProgressModal: React.FC<InProgressModalProps> = ({
  visible = false,
}) => {
  const { t } = useTranslation("InProgressModal");
  const styles = useStyles();

  return (
    <Backdrop className={styles.backdrop} open={visible}>
      <CircularProgress color="inherit" />
      <Space size={1} horizontal />
      <Typography>
        {t("miscellaneous.inProgress", { ns: "shared" })!}
      </Typography>
    </Backdrop>
  );
};
