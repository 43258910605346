import { QueueType } from "contract/common";

import firebase from "common/firebase";

export interface QueueJoinPayload {
  flightCreatorId: string;
  queueType: QueueType;
}

export interface QueueJoinPrivatePayload {
  flightCreatorId: string;
  queueType: QueueType;
  password: string;
}

export interface QueueLeavePayload {
  flightCreatorId: string;
}

export interface QueueKickPayload {
  uid: string;
}

export class QueueApi {
  static join(payload: QueueJoinPayload) {
    return firebase.functions().httpsCallable("queueJoin")(payload);
  }

  static joinPrivate(payload: QueueJoinPrivatePayload) {
    return firebase.functions().httpsCallable("queueJoinPrivate")(payload);
  }

  static leave(payload: QueueLeavePayload) {
    return firebase.functions().httpsCallable("queueLeave")(payload);
  }

  static finish(payload: QueueLeavePayload) {
    return firebase.functions().httpsCallable("queueFinish")(payload);
  }

  static kick(payload: QueueKickPayload) {
    return firebase.functions().httpsCallable("queueKick")(payload);
  }

  static lock() {
    return firebase.functions().httpsCallable("queueLock")();
  }

  static unlock() {
    return firebase.functions().httpsCallable("queueUnlock")();
  }
}
