export enum Region {
  JAPAN = "Japan",
  THE_AMERICAS = "The Americas",
  EUROPE = "Europe",
  AUSTRALIA_NEW_ZEALAND = "Australia/New Zealand",
  HONG_KONG_TAIWAN_SOUTH_KOREA = "Hong Kong/Taiwan/South Korea",
}

export enum SpecialCharacter {
  CELESTE = "CELESTE",
  CJ = "CJ",
  DAISY_MAE = "DAISY_MAE",
  FLICK = "FLICK",
  KICKS = "KICKS",
  LEIF = "LEIF",
  REDD = "REDD",
  SAHARAH = "SAHARAH",
  TIMMY_AND_TOMMY = "TIMMY_AND_TOMMY",
}

export enum Fruit {
  Apple = "Apple",
  Cherry = "Cherry",
  Orange = "Orange",
  Peach = "Peach",
  Pear = "Pear",
}

export enum Flower {}

// To be defined...

export enum Terminal {
  TURNIPS = "TURNIPS",
  GENERAL = "GENERAL",
}

export enum TurnipsFlightType {
  SELLING = "SELLING", // High price for selling
  BUYING = "BUYING", // Daisy Mae is on island
}

export enum TurnipsPricePeriod {
  AM = "AM",
  PM = "PM",
}

export enum FlightStatus {
  CLOSE = "close",
  OPEN = "open",
}

export enum QueueType {
  STANDARD = "STANDARD",
  FIRST_CLASS = "FIRST_CLASS",
}

export enum QueueStatus {
  LOCKED = "locked",
  UNLOCKED = "unlocked",
}

export enum Membership {
  SUPPORTER = "SUPPORTER",
  RESIDENT = "RESIDENT",
  RESIDENT_PLUS = "RESIDENT_PLUS",
  CITIZEN = "CITIZEN",
  COUNCILOR = "COUNCILOR",
  INVESTOR = "INVESTOR",
  INVESTOR_PLUS = "INVESTOR_PLUS",
  STREAMER = "STREAMER",
  STREAMER_PLUS = "STREAMER_PLUS",
}

export enum FlightPassengerType {
  ALL = "ALL",
  TRUSTED = "TRUSTED",
}

export enum VerificationLevel {
  LEVEL_ONE = "1",
  LEVEL_TWO = "2",
}

export enum ResidentStatus {
  DIY = "DIY",
  MOVING_OUT = "MOVING_OUT",
}

export enum LeaveQueueReason {
  LEAVE = "LEAVE",
  TRIP_FINISHED = "TRIP_FINISHED",
  OFFLINE = "OFFLINE",
  REMOVED_BY_HOST = "REMOVED_BY_HOST",
  FLIGHT_CLOSED = "FLIGHT_CLOSED",
  MAX_CODE_SHOWN_TIME = "MAX_CODE_SHOWN_TIME",
  MAX_SHOULD_ACCESS_CODE_TIME = "MAX_SHOULD_ACCESS_CODE_TIME",
}

export enum EventType {
  FLIGHT_OPEN = "FLIGHT_OPEN",
  FLIGHT_UPDATE = "FLIGHT_UPDATE",
  FLIGHT_END = "FLIGHT_END",
  FLIGHT_CHAT_SEND = "FLIGHT_CHAT_SEND",
  FLIGHT_REPORT = "FLIGHT_REPORT",
  FLIGHT_QUEUE_JOIN = "FLIGHT_QUEUE_JOIN",
  FLIGHT_QUEUE_LEAVE = "FLIGHT_QUEUE_LEAVE",
  FLIGHT_QUEUE_LOCK = "FLIGHT_QUEUE_LOCK",
  REPORT_ADD = "REPORT_ADD",
}

export enum ArrAttrAction {
  ADD = "ADD",
  REMOVE = "REMOVE",
  UPDATE = "UPDATE",
}

export enum CatalogAndWishlistItemType {
  CATALOG = "CATALOG",
  WISHLIST = "WISHLIST",
}

export enum SupportedSocialPlatform {
  TWITTER = "Twitter",
  INSTAGRAM = "Instagram",
}

export enum QueueEvent {
  JOIN = "JOIN",
  LEAVE = "LEAVE",
}

export enum ReportSubject {
  ISLAND = "ISLAND",
  FLIGHT = "FLIGHT",
  CHAT = "CHAT",
  POST = "POST",
  POST_REPLY = "POST_REPLY",
  POST_DEAL_MESSAGE = "POST_DEAL_MESSAGE",
}

export enum ReportReason {
  SCAM = "SCAM",
  HATE_SPEECH = "HATE_SPEECH",
  OTHER = "OTHER",
}

export enum ReportResult {
  DISMISSED = "DISMISSED",
  CONFIRMED = "CONFIRMED",
  WITHDRAWN = "WITHDRAWN",
}

export enum ChatRecordContentType {
  String,
  JSON,
}

export enum ArticleColor {
  Navy = "Navy",
  Red = "Red",
  Yellow = "Yellow",
}

// Note: max 10 First Class users in a queue at a time
export const MAX_QUEUE_SIZE_FIRST_CLASS = 10;

export interface Fee {
  id: string;
  count: number;
}

export enum PushNotificationType {
  // FLIGHT
  NEW_FLIGHT = "NEW_FLIGHT",
  GOOD_PRICE = "GOOD_PRICE",
  HIGH_PRICE = "HIGH_PRICE",
  ENTERED_VISITING_QUEUE = "ENTERED_VISITING_QUEUE",
  QUEUE_LEAVE_FLIGHT_CLOSED = "QUEUE_LEAVE_FLIGHT_CLOSED",
  QUEUE_LEAVE_MAX_CODE_SHOWN_TIME = "QUEUE_LEAVE_MAX_CODE_SHOWN_TIME",
  QUEUE_LEAVE_MAX_SHOULD_ACCESS_CODE_TIME = "QUEUE_LEAVE_MAX_SHOULD_ACCESS_CODE_TIME",
  QUEUE_LEAVE_REMOVED_BY_HOST = "QUEUE_LEAVE_REMOVED_BY_HOST",

  // INBOX
  POST_REPLY = "POST_REPLY",
  POST_DEAL_MESSAGE = "POST_DEAL_MESSAGE",
  POST_DEAL_REVIEW = "POST_DEAL_REVIEW",
  POST_LIKE = "POST_LIKE",
  POST_REPLY_LIKE = "POST_REPLY_LIKE",
  POST_REPLY_REPLY = "POST_REPLY_REPLY",
  SYSTEM_NOTIFICATION = "SYSTEM_NOTIFICATION",
}
