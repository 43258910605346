import React from "react";
import { AuthContext } from "modules/auth/AuthContext";
import { useUserProfile } from "hooks/useUserProfile";
import { useFlightQueue } from "hooks/useFlightQueue";
import { getFlightInfo, FlightInfo, InQueueType } from "utils/getFlightInfo";
import { useFlight } from "hooks/useFlight";
import { ProfileContext } from "modules/auth/ProfileContext";
import { useTranslation } from "react-i18next";
import { Terminal } from "contract/common";
import { UserProfile } from "contract/view-collection/user";
import { ActionButtonItem } from "components/ActionButton/Item";
import { FlightPublic } from "contract/view-collection/flight";

export function getEyebrow(t: any, flight: FlightPublic | null | undefined) {
  if (!flight) return "-";

  let eyebrow = `${t(`terminal.${flight.terminal!}`, {
    ns: "shared",
  })}`;

  if (flight.terminal === Terminal.TURNIPS) {
    eyebrow += ` (${flight.price} ${t("miscellaneous.Bells", {
      ns: "shared",
    })})`;
  }

  return eyebrow.toUpperCase();
}

export function getTitle(
  t: any,
  flightInfo: FlightInfo,
  flightCreatorProfile: UserProfile | null | undefined
) {
  if (!flightCreatorProfile || !flightInfo.inQueue) return "-";

  if (flightInfo.inQueue?.type === InQueueType.VISITING) {
    return t("yourTurnLabel", { islandName: flightCreatorProfile.islandName })!;
  }

  return t("currentPositionLabel", {
    position: flightInfo.inQueue?.position,
    length: flightInfo.inQueue?.length,
    queueType: t(`queueType.${flightInfo.inQueue?.type}`, { ns: "shared" }),
    islandName: flightCreatorProfile.islandName,
  });
}

export interface CurrentQueueingItemProps {
  flightCreatorId: string;
}

export const CurrentQueueingItem: React.FC<CurrentQueueingItemProps> = ({
  flightCreatorId,
}) => {
  const { t } = useTranslation("CurrentQueueingItem");

  const user = React.useContext(AuthContext);
  const userProfile = React.useContext(ProfileContext);
  const flight = useFlight(flightCreatorId);
  const flightCreatorProfile = useUserProfile(flightCreatorId);
  const flightQueue = useFlightQueue(flightCreatorId);

  const flightInfo = getFlightInfo({
    flight,
    flightQueue,
    user,
    userProfile,
  });

  const loading =
    userProfile === undefined ||
    flight === undefined ||
    flightCreatorProfile === undefined ||
    flightQueue === undefined;

  const eyebrow = getEyebrow(t, flight);
  const title = getTitle(t, flightInfo, flightCreatorProfile);

  const onPress = React.useCallback(() => {
    window.open(`/island/${flightCreatorId}`, "_blank");
  }, [flightCreatorId]);

  return (
    <ActionButtonItem
      eyebrow={eyebrow}
      title={title}
      loading={loading}
      onPress={onPress}
    />
  );
};
