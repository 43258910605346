import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";

import theme from "./common/themes";
import { Container } from "@material-ui/core";
import { Space } from "./components/Space";
import { NavBar } from "./components/NavBar/NavBar";
import { Footer } from "./components/Footer/Footer";
import { HomePage } from "./pages/Home/Home";
import { NewsPage } from "./pages/News";
import { NewsListPage } from "./pages/NewsList";
import { AirportPage } from "./pages/Airport/Airport";
import { FlightFormPage } from "pages/FlightForm/FlightForm";
import { IslandPage } from "pages/Island/Island";
import { SignInPage } from "pages/SignIn/SignIn";
import { useFirebaseUser } from "modules/auth/useFirebaseUser";
import { AuthContext } from "modules/auth/AuthContext";
import { useUserProfile } from "hooks/useUserProfile";
import { ProfileContext } from "modules/auth/ProfileContext";
import { PassportPage } from "pages/Passport/Passport";
import { PostPage } from "pages/Post/Post";

const useAppStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  logo: {
    width: 32,
    height: 32,
    borderRadius: 8,
  },
});

export default function ResponsiveFontSizes() {
  const classes = useAppStyles();
  const user = useFirebaseUser();
  const userProfile = useUserProfile(user?.uid);

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <AuthContext.Provider value={user}>
          <ProfileContext.Provider value={userProfile}>
            <SnackbarProvider
              anchorOrigin={{ horizontal: "center", vertical: "top" }}
              autoHideDuration={3000}
            >
              <Container maxWidth="md" className={classes.container}>
                <NavBar />
                <Switch>
                  <Route path="/airport" exact>
                    <AirportPage />
                  </Route>
                  <Route path="/flight/manage" exact>
                    <FlightFormPage />
                  </Route>
                  <Route path="/island/:islandId">
                    <IslandPage />
                  </Route>
                  <Route path="/passport">
                    <PassportPage />
                  </Route>
                  <Route path="/news" exact>
                    <NewsListPage />
                  </Route>
                  <Route path="/news/:newsId">
                    <NewsPage />
                  </Route>
                  <Route path="/post/:postId">
                    <PostPage />
                  </Route>
                  <Route path="/sign-in">
                    <SignInPage />
                  </Route>
                  <Route path="/">
                    <HomePage />
                  </Route>
                </Switch>
                <Space size={4} />
                <Space size={4} />
                <Footer />
                <Space size={4} />
              </Container>
            </SnackbarProvider>
          </ProfileContext.Provider>
        </AuthContext.Provider>
      </ThemeProvider>
    </Router>
  );
}
