import React from "react";
import firebase from "../common/firebase";
import { FirebaseFirestore } from "@firebase/firestore-types";

export interface Announcement {
  title: string;
  url: string;
}

export function useAnnouncement() {
  const [state, setState] = React.useState<Announcement | undefined | null>(
    undefined
  );

  React.useEffect(() => {
    (firebase.firestore() as FirebaseFirestore)
      .collection("config")
      .doc("websiteAnnouncement")
      .onSnapshot((snapshot) => {
        if (!snapshot.exists) {
          setState(null);
          return;
        }

        setState({
          title: snapshot.get("title"),
          url: snapshot.get("url"),
        });
      });
  }, []);

  return state;
}
