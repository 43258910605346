import { Box, BoxProps } from "@material-ui/core";
import React from "react";

export interface RowProps extends BoxProps {
  center?: boolean;
}

const Row: React.FC<RowProps> = ({
  children,
  center = false,
  style,
  ...props
}) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        ...(center ? { justifyContent: "center", alignItems: "center" } : {}),
        ...style,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default Row;
