import { Typography } from "@material-ui/core";
import EntryFeeContainer from "components/EntryFee/Container";
import { EntryFeeItem } from "components/EntryFee/Item";
import { RoundedBackgroundLabel } from "components/RoundedBackgroundTitle";
import RoundedWhiteContainer from "components/RoundedWhiteContainer";
import { Space } from "components/Space";
import ItemItem from "components/TouchableImageList/ItemItem";
import TouchableImageListContainer from "components/TouchableImageList/List";
import VillagerItem from "components/TouchableImageList/VillagerItem";
import { UserProfile } from "contract/view-collection/user";
import { FlightPublic } from "contract/view-collection/flight";
import React from "react";
import { Passport } from "./Passport/Passport";
import { useTranslation } from "react-i18next";
import { HELP_ARTICLE } from "common/constants";
import { openHelpArticle } from "utils/openHelpArticle";
import SpecialCharacterItem from "components/TouchableImageList/SpecialVisitorItem";
import { Terminal } from "contract/common";
import { CharacterWithSpeeach } from "components/CharacterWithSpeech/CharacterWithSpeech";
import { colors } from "common/colors";
import { OpenReportModal } from "./Report/useReportModal";

export interface IslandInfoSectionProps {
  flight: FlightPublic | null;
  flightCreatorProfile: UserProfile;
  onPressReport: OpenReportModal;
}

const IslandInfoSection: React.FC<IslandInfoSectionProps> = ({
  flight,
  flightCreatorProfile,
  onPressReport,
}) => {
  const { t } = useTranslation("IslandScreen");

  return (
    <>
      <Passport profile={flightCreatorProfile} onPressReport={onPressReport} />
      {flight?.terminal === Terminal.TURNIPS ? (
        <React.Fragment>
          <Space size={1} />
          <CharacterWithSpeeach
            characterName={t("character.TimmyTommy", { ns: "shared" })}
            character="/special-character/Timmy_and_Tommy.png"
            text={
              <Typography
                variant="h6"
                style={{ color: colors["txt-color-brown-500"] }}
              >
                {t("currentPriceIs")!}
                <Typography
                  variant="h5"
                  style={{ color: "deepskyblue", display: "inline-block" }}
                >
                  {String(flight.price)} {t("bells")!}
                </Typography>{" "}
                {t("perTurnips")!}
              </Typography>
            }
            containerHeight={144}
            width={103 * 1.3}
            height={144}
            bubbleBgColor="txt-color-orange-500"
          />
          <Space size={6} />
        </React.Fragment>
      ) : (
        <Space size={4} />
      )}
      {!!flight && (
        <>
          <RoundedBackgroundLabel
            title={t("entryFeeLabel")}
            accessoryRight
            accessoryRightOnPress={() => {
              openHelpArticle(HELP_ARTICLE.FlightEntryFee);
            }}
          />
          <Space size={2} />
          <EntryFeeContainer>
            {flight.entryFees?.map((item, idx) => {
              return <EntryFeeItem {...item} id={item.id} key={item.id} />;
            })}
          </EntryFeeContainer>
          <Space size={4} />
        </>
      )}
      <RoundedBackgroundLabel
        title={t("residentsLabel")}
        accessoryRight
        accessoryRightOnPress={() => {
          openHelpArticle(HELP_ARTICLE.FlightResident);
        }}
      />
      <Space size={2} />
      <TouchableImageListContainer fillerSize={45}>
        {flight?.residents?.map((residentWithStatus, index) => {
          return (
            <VillagerItem
              id={residentWithStatus.id}
              status={residentWithStatus.status}
              size={45}
              key={residentWithStatus.id}
            />
          );
        })}
        {flightCreatorProfile?.residents
          ?.filter(
            (villagerId) =>
              !flight?.residents?.find((resident) => resident.id === villagerId)
          )
          .map((villagerId, index) => {
            return <VillagerItem id={villagerId} size={45} key={villagerId} />;
          })}
      </TouchableImageListContainer>
      <Space size={4} />
      <RoundedBackgroundLabel
        title={t("specialVisitorsLabel")}
        accessoryRight
        accessoryRightOnPress={() => {
          openHelpArticle(HELP_ARTICLE.FlightSpecialVisitor);
        }}
      />
      <Space size={2} />
      <TouchableImageListContainer fillerSize={45}>
        {flight?.specialVisitors?.map((specialVisitor, index) => {
          return (
            <SpecialCharacterItem
              specialVisitor={specialVisitor}
              size={45}
              key={specialVisitor.id}
            />
          );
        })}
      </TouchableImageListContainer>
      <Space size={4} />
      <RoundedBackgroundLabel
        title={t("catalogLabel")}
        accessoryRight
        accessoryRightOnPress={() => {
          openHelpArticle(HELP_ARTICLE.FlightCatalog);
        }}
      />
      <Space size={2} />
      <TouchableImageListContainer fillerSize={45}>
        {flight?.catalog?.map((itemId, index) => {
          return <ItemItem id={itemId} size={45} key={index} />;
        })}
      </TouchableImageListContainer>
      <Space size={4} />
      <RoundedBackgroundLabel
        title={t("wishlistLabel")}
        accessoryRight
        accessoryRightOnPress={() => {
          openHelpArticle(HELP_ARTICLE.FlightWishlist);
        }}
      />
      <Space size={2} />
      <TouchableImageListContainer fillerSize={45}>
        {flight?.wishlist?.map((itemId, index) => {
          return <ItemItem id={itemId} size={45} key={index} />;
        })}
      </TouchableImageListContainer>
      <Space size={4} />
      <RoundedBackgroundLabel
        title={t("messageLabel")}
        accessoryRight
        accessoryRightOnPress={() => {
          openHelpArticle(HELP_ARTICLE.FlightMessage);
        }}
      />
      <Space size={2} />
      <RoundedWhiteContainer>
        <Typography variant="body2" style={{ whiteSpace: "break-spaces" }}>
          {flight?.message ||
            flightCreatorProfile?.message ||
            t("messageEmpty")!}
        </Typography>
      </RoundedWhiteContainer>
    </>
  );
};

export default IslandInfoSection;
