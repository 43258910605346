import React from "react";
import { useTranslation } from "react-i18next";
import { useReports } from "./useReports";
import { Space } from "components/Space";
import Row from "components/Row";
import { ReportSubject } from "contract/common";
import { ReportForm } from "./ReportForm";
import { AuthContext } from "modules/auth/AuthContext";
import * as utils from "./utils";
import { ReportReported } from "./ReportReported";
import { HELP_ARTICLE } from "common/constants";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Link,
  Typography,
} from "@material-ui/core";
import { colors } from "common/colors";
import { ReportRecord } from "contract/data-collection/report";
import { useUserProfile } from "hooks/useUserProfile";
import { Alert, AlertTitle } from "@material-ui/lab";

export interface ReportModalProps {
  visible: boolean;
  onRequestClose: () => void;
  reporteeUid: ReportRecord["reporteeUid"];
  subject: ReportRecord["subject"];
  subjectMetadata: ReportRecord["subjectMetadata"];
  reporteeMessage?: string;
}

export const ReportModal: React.FC<ReportModalProps> = ({
  visible,
  onRequestClose,
  reporteeUid,
  subject,
  subjectMetadata,
  reporteeMessage,
}) => {
  const { t } = useTranslation("ReportScreen");
  const user = React.useContext(AuthContext);

  const reporteeProfile = useUserProfile(reporteeUid);

  const reports = useReports(reporteeUid);
  const hasReported = utils.hasReported(user?.uid, subject, reports);

  if (!reporteeUid || !subject) {
    return (
      <Box>
        <Typography>{t("label.notFound")!}</Typography>
      </Box>
    );
  }

  if (reports === undefined) {
    return (
      <Box style={{ padding: "24px 0" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Dialog
      open={visible}
      fullWidth={true}
      maxWidth="xs"
      onClose={onRequestClose}
    >
      <DialogContent>
        <Typography variant="h6" style={{ color: "#cb0635" }}>
          {t("label.report")!}
        </Typography>
        <Box>
          <Space size={1} />
          <Typography
            variant="subtitle1"
            style={{ color: colors["text-hint-color"] }}
          >
            {t("label.reportedNTimes", { n: reports.length })!}
          </Typography>
        </Box>
        <Space size={4} />
        <Alert severity="info">
          <AlertTitle>Community Standards</AlertTitle>
          Please read{" "}
          <Link href={`/news/${HELP_ARTICLE.Report}`} target="_blank">
            Reporting Guidelines
          </Link>{" "}
          and{" "}
          <Link href={`/news/${HELP_ARTICLE.Report}`} target="_blank">
            Community Standards
          </Link>{" "}
          to understand how Reporting works and avoid violating any rules.
        </Alert>
        <Space size={4} />
        <Box>
          <Row>
            <Typography
              style={{
                color: colors["text-hint-color"],
                fontSize: 13,
                width: 120,
              }}
            >
              {t("label.displayName")!}
            </Typography>
            <Space horizontal size={1}></Space>
            <Typography>{reporteeProfile?.displayName}</Typography>
          </Row>
          <Space size={0.5}></Space>
          <Row>
            <Typography
              style={{
                color: colors["text-hint-color"],
                fontSize: 13,
                width: 120,
              }}
            >
              {t("label.islandName")!}
            </Typography>
            <Space horizontal size={1}></Space>
            <Typography>{reporteeProfile?.islandName}</Typography>
          </Row>
          {subject === ReportSubject.CHAT && (
            <React.Fragment>
              <Space size={0.5}></Space>
              <Row>
                <Typography
                  style={{
                    color: colors["text-hint-color"],
                    fontSize: 13,
                    width: 120,
                  }}
                >
                  {t("label.message")!}
                </Typography>
                <Space horizontal size={1}></Space>
                <Typography
                  style={{
                    flex: 1,
                    wordBreak: "break-word",
                    maxHeight: 300,
                    overflowY: "auto",
                  }}
                >
                  {reporteeMessage}
                </Typography>
              </Row>
            </React.Fragment>
          )}
        </Box>
        <Space size={4} />
        {hasReported ? (
          <ReportReported report={hasReported} />
        ) : (
          <ReportForm
            reporteeUid={reporteeUid}
            subject={subject}
            subjectMetadata={subjectMetadata}
            onRequestClose={onRequestClose}
          />
        )}
        <Space size={4} />
      </DialogContent>
    </Dialog>
  );
};
