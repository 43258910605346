import React from "react";
import { Ref } from "api/ref";
import { Terminal } from "contract/common";
import { mapDataToFlight } from "./useFlight";
import { FlightPublic } from "contract/view-collection/flight";

export function mapSnapshotToFlightList(snapshot: any): FlightPublic[] {
  const val = snapshot.val();

  if (!val) {
    return [];
  }

  return Object.keys(val).map((key) => {
    return mapDataToFlight(val[key], key);
  });
}

export function useFlightList(terminal: Terminal): FlightPublic[] | undefined {
  const [state, setState] = React.useState<FlightPublic[] | undefined>(
    undefined
  );

  React.useEffect(() => {
    const ref = Ref.flightList.orderByChild("terminal").equalTo(terminal);
    const callback = ref.on(
      "value",
      (snapshot: firebase.database.DataSnapshot) => {
        setState(mapSnapshotToFlightList(snapshot));
      }
    );

    return () => {
      ref.off("value", callback);
    };
  }, []);

  return state;
}
