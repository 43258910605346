import React from "react";
import { useTranslation } from "react-i18next";
import { Space } from "components/Space";
import {
  ListItem,
  Icon,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Button,
  Box,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { useSwitchState } from "hooks/useSwitchState";
import { colors } from "common/colors";
import { useSnackbar } from "notistack";

export interface ProfileEditListItemSelectProps {
  title: string;
  disabled?: boolean;
  selected?: number;
  options: string[];
  onSubmit: (value: number) => Promise<any>;
}

export const ProfileEditListItemSelect: React.FC<ProfileEditListItemSelectProps> = ({
  title,
  selected,
  options,
  disabled = false,
  onSubmit,
}) => {
  const { t } = useTranslation("ProfileEditListItemSelect");
  const { enqueueSnackbar } = useSnackbar();

  const [selectedIndex, setSelectedIndex] = React.useState(selected);

  const dialog = useSwitchState();
  const [loading, setLoading] = React.useState(false);

  const valueWithPlaceholder = selected !== undefined ? options[selected] : "-";

  return (
    <React.Fragment>
      <ListItem
        onClick={dialog.turnOn}
        button
        disabled={disabled}
        style={{ padding: "4px 0" }}
      >
        <ListItemText>
          <Typography variant="caption" style={{}}>
            {title}
          </Typography>
        </ListItemText>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: "56%",
          }}
        >
          <Typography
            variant="subtitle2"
            style={{
              flex: 1,
              textAlign: "right",
              color: colors["text-hint-color"],
            }}
          >
            {valueWithPlaceholder}
          </Typography>

          <Space horizontal size={1} />
          {!disabled && (
            <Icon
              className="fas fa-angle-right"
              style={{
                fontSize: 16,
                color: colors["text-hint-color"],
                textAlign: "center",
              }}
            />
          )}
        </Box>
      </ListItem>

      <Dialog
        open={dialog.on}
        fullWidth={true}
        maxWidth="xs"
        onClose={() => {
          if (loading) return;
          setSelectedIndex(selected);
          dialog.turnOff();
        }}
      >
        <DialogContent>
          <FormControl variant="outlined" fullWidth disabled={loading}>
            <InputLabel>{title}</InputLabel>
            <Select
              value={selected ? options[selected] : undefined}
              onChange={({ target: { value } }) => {
                setSelectedIndex(options.indexOf(value as string));
              }}
              label={title}
            >
              {options.map((option) => (
                <MenuItem value={option} key={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={dialog.turnOff} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (selectedIndex === undefined) return;

              setLoading(true);
              onSubmit(selectedIndex)
                .then(() => {
                  enqueueSnackbar(t("success.submit")!, { variant: "success" });
                  dialog.turnOff();
                  setLoading(false);
                })
                .catch((error) => {
                  // crashlytics().recordError(error);
                  enqueueSnackbar(
                    t("error.general", {
                      ns: "shared",
                      msg: error.message,
                    })!,
                    { variant: "error" }
                  );
                  setLoading(false);
                });
            }}
            color="primary"
            disabled={loading}
          >
            {loading ? t("updatingLabel")! : t("updateLabel")!}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
