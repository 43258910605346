import React from "react";
import { ReportReason } from "contract/common";
import { useTranslation } from "react-i18next";
import { ReportApi } from "api/report";
import { ReportRecord } from "contract/data-collection/report";
import { useProgress } from "hooks/useProgress";

export interface FormField<T> {
  value?: T;
  label?: string;
  placeholder?: string;
  error?: string;
  helpText?: string;
  valid: boolean;
  touched: boolean;
  onChange: (newValue?: T) => void;
}

export interface UseFormFieldProps<T> {
  defaultValue?: T;
  label?: string;
  placeholder?: string;
  helpText?: string;
  validate: (value?: T) => string | undefined;
}

export function useFormField<T>({
  defaultValue,
  label,
  placeholder,
  helpText,
  validate,
}: UseFormFieldProps<T>): FormField<T> {
  const [value, setValue] = React.useState<T | undefined>(defaultValue);
  const [error, setError] = React.useState<string | undefined>();
  const [touched, setTouched] = React.useState<boolean>(false);

  React.useEffect(() => {
    setError(validate(defaultValue));
  }, [defaultValue]);

  const onChange = React.useCallback(
    (newValue: T | undefined) => {
      setValue(newValue);
      setError(validate(newValue));
      setTouched(true);
    },
    [validate]
  );

  return {
    value,
    label,
    placeholder,
    error: touched ? error : undefined,
    helpText,
    valid: error === undefined,
    touched,
    onChange,
  };
}

export interface UseReportFormProps {
  reporteeUid: ReportRecord["reporteeUid"];
  subject: ReportRecord["subject"];
  subjectMetadata: ReportRecord["subjectMetadata"];
}

export function useReportForm({
  reporteeUid,
  subject,
  subjectMetadata,
}: UseReportFormProps) {
  const { t } = useTranslation("useReportForm");

  const progress = useProgress(
    t("success.general", { ns: "shared" }),
    (e: Error) => t("error.general", { ns: "shared", msg: e.message })
  );

  const validateReason = React.useCallback(
    (newValue: ReportReason | undefined) => {
      if (newValue === undefined) return t("error.reasonRequired");
      return;
    },
    []
  );

  const reason = useFormField<ReportReason>({
    label: t("label.reason"),
    placeholder: t("placeholder.reason"),
    helpText: t("helpText.reason"),
    validate: validateReason,
  });

  const validateDescription = React.useCallback((newValue: string | undefined):
    | string
    | undefined => {
    return;
  }, []);

  const description = useFormField<string>({
    label: t("label.description"),
    placeholder: t("placeholder.description"),
    helpText: t("helpText.description"),
    validate: validateDescription,
    defaultValue: "",
  });

  const submit = () => {
    progress.start();
    ReportApi.report({
      subject,
      subjectMetadata,
      reporteeUid,
      reason: reason.value,
      reasonDescription: description.value,
    })
      .then(() => {
        progress.succeeded();
      })
      .catch((e: any) => {
        progress.failed(e);
      });
  };

  return {
    reason,
    description,
    submit,
    canSubmit: reason.valid && !progress.loading,
    loading: progress.loading,
  };
}
