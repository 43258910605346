import { FlightApi } from "api/flight";
import {
  FlightPassengerType,
  Terminal,
  TurnipsFlightType,
} from "contract/common";
import {
  EntryFee,
  FlightForm as FlightFormRaw,
  ResidentWithStatus,
  SpecialCharacterWithStatus,
} from "contract/data-collection/flight";
import useFlightFormData from "hooks/useFlightFormData";
import { useSnackbar } from "notistack";
import * as R from "ramda";
import React from "react";
import { useTranslation } from "react-i18next";
import { getDateOrUndefined } from "utils/getDateOrUndefined";
import { getInOneHour } from "utils/getInOneHour";
import { isWithinHours } from "utils/isWIthinHours";

export enum FlightFormField {
  Terminal = "Terminal",
  Message = "Message",

  TurnipsFlightType = "TurnipsFlightType",
  Price = "Price",

  Residents = "Residents",
  SpecialVisitors = "SpecialVisitors",

  EntryFees = "EntryFees",

  PlanToCloseAt = "PlanToCloseAt",
  MaxQueueSize = "MaxQueueSize",
  MaxAllowed = "MaxAllowed",
  MaxStayTime = "MaxStayTime",

  FlightPassengerType = "FlightPassengerType",
  RequirePassword = "RequirePassword",

  Code = "Code",
}

export interface FlightForm {
  [FlightFormField.Terminal]?: Terminal;
  [FlightFormField.Message]?: string;

  [FlightFormField.TurnipsFlightType]?: TurnipsFlightType;
  [FlightFormField.Price]?: number;

  [FlightFormField.Residents]?: ResidentWithStatus[];
  [FlightFormField.SpecialVisitors]?: SpecialCharacterWithStatus[];

  [FlightFormField.EntryFees]?: EntryFee[];

  [FlightFormField.PlanToCloseAt]?: number; // timestamp or UTC string
  [FlightFormField.MaxQueueSize]?: number;
  [FlightFormField.MaxAllowed]?: number;
  [FlightFormField.MaxStayTime]?: number;

  [FlightFormField.FlightPassengerType]?: FlightPassengerType;
  [FlightFormField.RequirePassword]?: boolean;

  [FlightFormField.Code]?: string;
}

export interface FlightFormError {
  [FlightFormField.Terminal]?: string;
  [FlightFormField.Message]?: string;

  [FlightFormField.TurnipsFlightType]?: string;
  [FlightFormField.Price]?: string;

  [FlightFormField.Residents]?: string;
  [FlightFormField.SpecialVisitors]?: string;

  [FlightFormField.EntryFees]?: string;

  [FlightFormField.PlanToCloseAt]?: string;
  [FlightFormField.MaxQueueSize]?: string;
  [FlightFormField.MaxAllowed]?: string;
  [FlightFormField.MaxStayTime]?: string;

  [FlightFormField.FlightPassengerType]?: string;
  [FlightFormField.RequirePassword]?: string;

  [FlightFormField.Code]?: string;
}

export type FlightFormHelptext = {
  [k in FlightFormField]: string;
};

export type FlightFormLabel = {
  [k in FlightFormField]: string;
};

export type FlightFormPlaceholder = {
  [k in FlightFormField]: string;
};

export type FlightFormUpdateField = (
  fieldName: FlightFormField,
  value:
    | string
    | number
    | boolean
    | Date
    | EntryFee[]
    | ResidentWithStatus[]
    | SpecialCharacterWithStatus[]
    | undefined,
  currentForm: FlightForm | undefined,
  initialised?: boolean
) => void;

export type FlightFormSubmit = () => void;

export const MAX_QUEUE_SIZE_OPTIONS = [99, 50, 25];
export const MAX_ALLOWED_OPTIONS = [1, 2, 3, 4, 5, 6, 7];
export const MAX_STAY_TIME_OPTIONS = [0, 10, 15, 20];
export const END_TIME_OPTIONS = [
  30,
  60,
  90,
  120,
  150,
  180,
  210,
  240,
  270,
  300,
  330,
  360,
  390,
  420,
  450,
  480,
  510,
  540,
];
export const REQUIRE_PASSWORD_OPTIONS = [false, true];

export function prepareFormResidents(
  profile: any,
  flight: FlightFormRaw | null
): ResidentWithStatus[] {
  if (!profile.residents || profile.residents.length === 0) {
    return [];
  }

  if (!flight || !flight.residents || flight.residents.length === 0) {
    return profile.residents.map((id: string) => ({ id }));
  }

  const result = profile.residents.map(
    (id: string): ResidentWithStatus => ({ id })
  );
  flight.residents.forEach((resident) => {
    if (profile.residents.includes(resident.id)) {
      result[profile.residents.indexOf(resident.id)].status = resident.status;
    }
  });
  return result;
}

export function useFlightForm(
  flightCreatorId: string | undefined,
  flightCreatorProfile: any,
  flightCreatorVerified: boolean
) {
  const { t } = useTranslation("useFlightForm");
  const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = React.useState<undefined | FlightForm>(undefined);
  const [error, setError] = React.useState<FlightFormError>({});
  const [touched, setTouched] = React.useState<undefined | boolean>(undefined);
  const [submitting, setSubmitting] = React.useState(false);

  const flight = useFlightFormData(flightCreatorId);

  const isFlightCreated = !!flight;

  const updateFormForField = React.useCallback(
    <T extends FlightFormField>(fieldName: T, value: FlightForm[T]) => {
      setForm((prev) => {
        if (prev === undefined) {
          return {
            [fieldName]: value,
          };
        }

        return {
          ...prev,
          [fieldName]: value,
        };
      });
    },
    []
  );

  const updateErrorForField = React.useCallback(
    (fieldName: FlightFormField, error: string | undefined) => {
      setError((prev) => ({
        ...prev,
        [fieldName]: error ? t(`error.${error}`) : undefined,
      }));
    },
    [t]
  );

  const updateField: FlightFormUpdateField = React.useCallback<FlightFormUpdateField>(
    (
      fieldName,
      value,
      currentForm,
      /**
       * This is used for resetting related fields.
       * Because there is only one place where initialisation
       * happens, we just default it to `true` so we don't
       * need to pass in extra value for the `true` case.
       * This helps us to avoid depending on a initialisation
       * flag, which could potentially cause repetitive resets.
       */
      initialised = true
    ) => {
      const optionalFields = [
        FlightFormField.Message,
        FlightFormField.EntryFees,
        FlightFormField.SpecialVisitors,
        FlightFormField.Residents,
        FlightFormField.FlightPassengerType,
        FlightFormField.RequirePassword,
      ];

      if (value === undefined) {
        if (!optionalFields.includes(fieldName)) {
          updateErrorForField(fieldName, "required");

          setTouched((prev) => (prev === false ? true : prev));
          return;
        }

        updateErrorForField(fieldName, undefined);
        updateFormForField(fieldName, undefined);

        setTouched((prev) => (prev === false ? true : prev));
        return;
      }

      switch (fieldName) {
        case FlightFormField.Terminal: {
          const typedValue = value as Terminal;
          if (!Object.values(Terminal).includes(typedValue)) {
            updateErrorForField(fieldName, "terminalInvalid");
            updateFormForField(fieldName, undefined);
            break;
          }

          if (initialised) {
            if (
              currentForm?.Terminal !== typedValue &&
              typedValue === Terminal.TURNIPS
            ) {
              updateField(
                FlightFormField.TurnipsFlightType,
                TurnipsFlightType.BUYING,
                undefined
              );
            }
          }

          updateFormForField(fieldName, value as Terminal);
          break;
        }
        case FlightFormField.Message: {
          const typedValue = value as string;

          if (typedValue.length > 3600) {
            updateErrorForField(fieldName, "messageLength");
          } else {
            updateErrorForField(fieldName, undefined);
          }

          updateFormForField(fieldName, typedValue);
          break;
        }

        case FlightFormField.EntryFees: {
          const typedValue = value as EntryFee[];

          if (typedValue === undefined) {
            updateErrorForField(fieldName, "entryFeeInvalid");
          } else {
            updateErrorForField(fieldName, undefined);
          }

          updateFormForField(fieldName, [...typedValue]);
          break;
        }
        case FlightFormField.TurnipsFlightType: {
          const typedValue = value as TurnipsFlightType;

          if (!Object.values(TurnipsFlightType).includes(typedValue)) {
            updateErrorForField(fieldName, "turnipsFlightTypeInvalid");
            updateFormForField(fieldName, undefined);
            break;
          }

          if (initialised) {
            if (
              currentForm?.TurnipsFlightType !== undefined &&
              currentForm?.TurnipsFlightType !== typedValue
            ) {
              updateField(FlightFormField.Price, 0, undefined);
            }
          }

          updateErrorForField(fieldName, undefined);
          updateFormForField(fieldName, typedValue as TurnipsFlightType);
          break;
        }
        case FlightFormField.Price: {
          const n = value as number;

          if (initialised) {
            if (n === undefined) {
              updateErrorForField(fieldName, "priceInvalid");
              updateFormForField(fieldName, 0);
              break;
            }

            if (
              currentForm?.TurnipsFlightType === TurnipsFlightType.BUYING &&
              (n < 90 || n > 110)
            ) {
              updateErrorForField(fieldName, "priceOutOfRangeBuying");
            } else if (n < 1 || n > 660) {
              updateErrorForField(fieldName, "priceOutOfRangeSelling");
            } else {
              updateErrorForField(fieldName, undefined);
            }
          }

          updateFormForField(fieldName, n);
          break;
        }
        case FlightFormField.Residents: {
          const typedValue = value as ResidentWithStatus[];

          if (typedValue === undefined) {
            updateErrorForField(fieldName, "residentsInvalid");
          } else {
            updateErrorForField(fieldName, undefined);
          }

          updateFormForField(fieldName, [...typedValue]);
          break;
        }
        case FlightFormField.SpecialVisitors: {
          const typedValue = value as SpecialCharacterWithStatus[];

          if (typedValue === undefined) {
            updateErrorForField(fieldName, "specialVisitorsInvalid");
          } else {
            updateErrorForField(fieldName, undefined);
          }

          updateFormForField(fieldName, [...typedValue]);
          break;
        }

        case FlightFormField.PlanToCloseAt: {
          const typedValue = value as Date;
          const d = getDateOrUndefined(typedValue);

          if (!d) {
            updateErrorForField(fieldName, "planToCloseAtInvalid");
            updateFormForField(fieldName, undefined);
            break;
          }

          // max open time is 9 hours from now
          if (!isWithinHours(9)(d)) {
            updateErrorForField(fieldName, "planToCloseAtOutOfRange");
          } else {
            updateErrorForField(fieldName, undefined);
          }

          updateFormForField(fieldName, d.getTime());
          break;
        }
        case FlightFormField.MaxQueueSize: {
          const n = value as number;

          if (n === undefined) {
            updateErrorForField(fieldName, "maxQueueSizeInvalid");
            updateFormForField(fieldName, undefined);
            break;
          }

          updateErrorForField(fieldName, undefined);

          updateFormForField(fieldName, parseInt(`${n}`));
          break;
        }
        case FlightFormField.MaxAllowed: {
          const n = value as number;

          if (n === undefined) {
            updateErrorForField(fieldName, "maxAllowedInvalid");
            updateFormForField(fieldName, undefined);
            break;
          }

          if (n < 1 || n > 4) {
            updateErrorForField(fieldName, "maxAllowedOutOfRange");
          } else {
            updateErrorForField(fieldName, undefined);
          }

          updateFormForField(fieldName, parseInt(`${n}`));
          break;
        }
        case FlightFormField.MaxStayTime: {
          const n = value as number;

          if (n === undefined) {
            updateErrorForField(fieldName, "maxStayTimeInvalid");
            updateFormForField(fieldName, undefined);
            break;
          }

          updateErrorForField(fieldName, undefined);

          updateFormForField(fieldName, parseInt(`${n}`));
          break;
        }

        case FlightFormField.FlightPassengerType: {
          const typedValue = value as FlightPassengerType;
          if (!Object.values(FlightPassengerType).includes(typedValue)) {
            updateErrorForField(fieldName, "flightPassengerTypeInvalid");
            updateFormForField(fieldName, undefined);
            break;
          }

          updateErrorForField(fieldName, undefined);
          updateFormForField(fieldName, typedValue as FlightPassengerType);
          break;
        }
        case FlightFormField.RequirePassword: {
          const typedValue = value as boolean;
          if (typedValue === undefined) {
            updateErrorForField(fieldName, "requirePasswordInvalid");
            updateFormForField(fieldName, undefined);
            break;
          }

          updateErrorForField(fieldName, undefined);
          updateFormForField(fieldName, typedValue);
          break;
        }

        case FlightFormField.Code: {
          const typedValue = value as string;

          if (typedValue.length !== 5) {
            updateErrorForField(fieldName, "dodoCodeLength");
          } else {
            updateErrorForField(fieldName, undefined);
          }

          updateFormForField(fieldName, typedValue.toUpperCase());
          break;
        }
      }

      setTouched((prev) => (prev === false ? true : prev));
      return;
    },
    [updateErrorForField, updateFormForField]
  );

  React.useEffect(() => {
    if (flight === undefined || flightCreatorProfile === undefined) return;
    if (flight === null) {
      updateField(FlightFormField.Terminal, Terminal.TURNIPS, undefined, false);
      updateField(FlightFormField.Message, undefined, undefined, false);

      updateField(FlightFormField.EntryFees, undefined, undefined, false);
      updateField(
        FlightFormField.TurnipsFlightType,
        TurnipsFlightType.SELLING,
        undefined,
        false
      );
      updateField(FlightFormField.Price, undefined, undefined, false);
      updateField(
        FlightFormField.Residents,
        prepareFormResidents(flightCreatorProfile, flight),
        undefined,
        false
      );
      updateField(FlightFormField.SpecialVisitors, undefined, undefined, false);

      updateField(
        FlightFormField.PlanToCloseAt,
        getInOneHour().toISOString(),
        undefined,
        false
      );
      updateField(FlightFormField.MaxQueueSize, 99, undefined, false);
      updateField(FlightFormField.MaxAllowed, 2, undefined, false);
      updateField(FlightFormField.MaxStayTime, 15, undefined, false);

      updateField(
        FlightFormField.FlightPassengerType,
        FlightPassengerType.ALL,
        undefined,
        false
      );
      updateField(FlightFormField.RequirePassword, false, undefined, false);

      updateField(FlightFormField.Code, undefined, undefined, false);

      setTouched(false);
      return;
    }

    const flightClone = R.clone(flight);

    updateField(
      FlightFormField.Terminal,
      flightClone.terminal,
      undefined,
      false
    );
    updateField(FlightFormField.Message, flightClone.message, undefined, false);

    updateField(
      FlightFormField.EntryFees,
      flightClone.entryFees,
      undefined,
      false
    );
    updateField(
      FlightFormField.TurnipsFlightType,
      flightClone.turnipsFlightType,
      undefined,
      false
    );
    updateField(FlightFormField.Price, flightClone.price, undefined, false);
    updateField(
      FlightFormField.Residents,
      prepareFormResidents(flightCreatorProfile, flight),
      undefined,
      false
    );
    updateField(
      FlightFormField.SpecialVisitors,
      flightClone.specialVisitors,
      undefined,
      false
    );

    // NOTE: PlanToCloseAt has to be a date string for updateField, but it's actual value should be timestamp
    updateField(
      FlightFormField.PlanToCloseAt,
      flightClone.planToCloseAt !== undefined &&
        getDateOrUndefined(flightClone.planToCloseAt)
        ? getDateOrUndefined(flightClone.planToCloseAt)?.toUTCString()
        : undefined,
      undefined,
      false
    );
    updateField(
      FlightFormField.MaxQueueSize,
      String(flightClone.maxQueueSize || 0),
      undefined,
      false
    );
    updateField(
      FlightFormField.MaxAllowed,
      String(flightClone.maxAllowed),
      undefined,
      false
    );
    updateField(
      FlightFormField.MaxStayTime,
      String(flightClone.maxStayTime || 0),
      undefined,
      false
    );

    updateField(
      FlightFormField.FlightPassengerType,
      flightClone.allowedPassengerType,
      undefined,
      false
    );
    updateField(
      FlightFormField.RequirePassword,
      !!flightClone.requirePassword,
      undefined,
      false
    );

    updateField(FlightFormField.Code, flightClone.dodoCode, undefined, false);
    setTouched(false);
  }, [flight, flightCreatorProfile, updateField]);

  const validated = React.useMemo(() => {
    const errorCopy = {
      ...error,
    };

    if (form?.Terminal === Terminal.TURNIPS) {
      delete errorCopy.Residents;
      delete errorCopy.SpecialVisitors;
    } else {
      delete errorCopy.TurnipsFlightType;
      delete errorCopy.Price;
    }

    return Object.values(errorCopy).reduce(
      (acc, cur) => acc && cur === undefined,
      true
    );
  }, [form, error]);

  const label: FlightFormLabel = React.useMemo(
    () => ({
      [FlightFormField.Terminal]: t("label.terminal"),
      [FlightFormField.Message]: t("label.message"),

      [FlightFormField.TurnipsFlightType]: t("label.turnipsFlightType"),
      [FlightFormField.Price]: t("label.price"),

      [FlightFormField.Residents]: t("label.residents"),
      [FlightFormField.SpecialVisitors]: t("label.specialVisitors"),

      [FlightFormField.EntryFees]: t("label.entryFees"),

      [FlightFormField.PlanToCloseAt]: t("label.planToCloseAt"),
      [FlightFormField.MaxQueueSize]: t("label.maxQueueSize"),
      [FlightFormField.MaxAllowed]: t("label.maxAllowed"),
      [FlightFormField.MaxStayTime]: t("label.maxStayTime"),

      [FlightFormField.FlightPassengerType]: t("label.flightPassengerType"),
      [FlightFormField.RequirePassword]: t("label.requirePassword"),

      [FlightFormField.Code]: t("label.code"),
    }),
    [t]
  );

  const placeholder: FlightFormLabel = React.useMemo(
    () => ({
      [FlightFormField.Terminal]: t("placeholder.terminal"),
      [FlightFormField.Message]: t("placeholder.message"),

      [FlightFormField.TurnipsFlightType]: t("placeholder.turnipsFlightType"),
      [FlightFormField.Price]: t("placeholder.price"),

      [FlightFormField.Residents]: t("placeholder.residents"),
      [FlightFormField.SpecialVisitors]: t("placeholder.specialVisitors"),

      [FlightFormField.EntryFees]: t("placeholder.entryFees"),

      [FlightFormField.PlanToCloseAt]: t("placeholder.planToCloseAt"),
      [FlightFormField.MaxQueueSize]: t("placeholder.maxQueueSize"),
      [FlightFormField.MaxAllowed]: t("placeholder.maxAllowed"),
      [FlightFormField.MaxStayTime]: t("placeholder.maxStayTime"),

      [FlightFormField.FlightPassengerType]: t(
        "placeholder.flightPassengerType"
      ),
      [FlightFormField.RequirePassword]: t("placeholder.requirePassword"),

      [FlightFormField.Code]: t("placeholder.code"),
    }),
    [t]
  );

  const helptext: FlightFormHelptext = React.useMemo(
    () => ({
      [FlightFormField.Terminal]:
        error[FlightFormField.Terminal] || t("helptext.terminal"),
      [FlightFormField.Message]:
        error[FlightFormField.Message] || t("helptext.message"),

      [FlightFormField.TurnipsFlightType]:
        error[FlightFormField.TurnipsFlightType] ||
        t("helptext.turnipsFlightType"),
      [FlightFormField.Price]:
        error[FlightFormField.Price] || t("helptext.price"),

      [FlightFormField.Residents]:
        error[FlightFormField.Residents] || t("helptext.residents"),
      [FlightFormField.SpecialVisitors]:
        error[FlightFormField.SpecialVisitors] || t("helptext.specialVisitors"),

      [FlightFormField.EntryFees]:
        error[FlightFormField.EntryFees] || t("helptext.entryFees"),

      [FlightFormField.PlanToCloseAt]:
        error[FlightFormField.PlanToCloseAt] || t("helptext.planToCloseAt"),
      [FlightFormField.MaxQueueSize]:
        error[FlightFormField.MaxQueueSize] || t("helptext.maxQueueSize"),
      [FlightFormField.MaxAllowed]:
        error[FlightFormField.MaxAllowed] || t("helptext.maxAllowed"),
      [FlightFormField.MaxStayTime]:
        error[FlightFormField.MaxStayTime] || t("helptext.maxStayTime"),

      [FlightFormField.FlightPassengerType]:
        error[FlightFormField.FlightPassengerType] ||
        t("helptext.flightPassengerType"),
      [FlightFormField.RequirePassword]:
        error[FlightFormField.RequirePassword] || t("helptext.requirePassword"),

      [FlightFormField.Code]: error[FlightFormField.Code] || t("helptext.code"),
    }),
    [error, t]
  );

  const submit = React.useCallback(() => {
    if (!form || !validated || !touched || flight === undefined) return;

    setSubmitting(true);

    if (!isFlightCreated) {
      FlightApi.open({
        terminal: form.Terminal,
        message: form.Message,

        entryFees: form.EntryFees,

        turnipsFlightType: form.TurnipsFlightType,
        price: form.Price,

        residents: form.Residents,
        specialVisitors: form.SpecialVisitors,

        planToCloseAt: form.PlanToCloseAt,
        maxQueueSize: form.MaxQueueSize,
        maxAllowed: form.MaxAllowed,
        maxStayTime: form.MaxStayTime,

        allowedPassengerType: form.FlightPassengerType,
        requirePassword: form.RequirePassword,

        dodoCode: form.Code,
      })
        .then(() => {
          enqueueSnackbar(
            t(isFlightCreated ? "success.update" : "success.create"),
            { variant: "success" }
          );
          setSubmitting(false);
        })
        .catch((error: Error) => {
          enqueueSnackbar(
            t(isFlightCreated ? "error.update" : "error.create", {
              msg: error.message,
            }),
            { variant: "error" }
          );
          setSubmitting(false);
        });
      return;
    }

    FlightApi.update(
      {
        terminal: form.Terminal,
        message: form.Message,

        entryFees: form.EntryFees,

        turnipsFlightType: form.TurnipsFlightType,
        price: form.Price,

        residents: form.Residents,
        specialVisitors: form.SpecialVisitors,

        planToCloseAt: form.PlanToCloseAt,
        maxQueueSize: form.MaxQueueSize,
        maxAllowed: form.MaxAllowed,
        maxStayTime: form.MaxStayTime,

        allowedPassengerType: form.FlightPassengerType,
        requirePassword: form.RequirePassword,

        dodoCode: form.Code,
      },
      flight
    )
      .then(() => {
        enqueueSnackbar(
          t(isFlightCreated ? "success.update" : "success.create"),
          { variant: "success" }
        );
        setSubmitting(false);
      })
      .catch((error: Error) => {
        enqueueSnackbar(
          t(isFlightCreated ? "error.update" : "error.create", {
            msg: error.message,
          }),
          { variant: "error" }
        );
        setSubmitting(false);
      });
  }, [
    form,
    validated,
    touched,
    flight,
    flightCreatorVerified,
    isFlightCreated,
    t,
  ]);

  const submitDisabled = submitting || !touched || !validated;

  return {
    form,
    error,
    label,
    placeholder,
    helptext,
    validated,
    touched: !!touched,
    updateField,
    submit,
    submitting,
    submitDisabled,
    isFlightCreated,
    isFlightOpen: flight ? flight.status === "open" : false,
  };
}
