import {
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Space } from "components/Space";
import { QueueType } from "contract/common";
import { useInputState } from "hooks/useInputState";
import React from "react";
import { useTranslation } from "react-i18next";
import { ChipWithCaption } from "../ChipWithCaption";

export interface JoinPrivateFlightModalProps {
  loading: boolean;
  visible: boolean;
  onRequestClose: () => void;
  onPressJoinPrivate: (password: string) => void;
}

const JoinPrivateFlightModal: React.FC<JoinPrivateFlightModalProps> = ({
  loading,
  visible,
  onRequestClose,
  onPressJoinPrivate,
}) => {
  const { t } = useTranslation("QueueSection");

  const joinPrivateFlightPasswordInput = useInputState();

  return (
    <Dialog open={!loading && visible} onClose={onRequestClose}>
      <DialogContent>
        <ChipWithCaption icon="key" caption={t("privateFlightLabelInModal")!} />
        <Space size={2} />
        <TextField
          autoFocus
          margin="dense"
          label="Enter flight password"
          fullWidth
          {...joinPrivateFlightPasswordInput}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onRequestClose}>Cancel</Button>
        <Button
          disabled={loading}
          onClick={() => {
            onPressJoinPrivate(joinPrivateFlightPasswordInput.value);
          }}
          color="primary"
        >
          Join
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default JoinPrivateFlightModal;
