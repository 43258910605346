import React from "react";
import { Ref } from "api/ref";
import { FlightPublic } from "contract/view-collection/flight";
import { getDateOrUndefined } from "utils/getDateOrUndefined";

export function mapDataToFlight(
  data: any,
  flightCreatorId: string
): FlightPublic {
  const {
    flightId,

    terminal,
    message,

    entryFees,

    turnipsFlightType,
    price,

    residents,
    specialVisitors,

    planToCloseAt,
    maxQueueSize,
    maxAllowed,
    maxStayTime,

    allowedPassengerType,
    requirePassword,

    queueSize,
    queueStatus,

    hostVerified,
    catalog,
    wishlist,

    dodoCodeLastChangedAt,
  } = data;

  return {
    flightId,
    flightCreatorId,

    terminal,
    message,

    entryFees,

    turnipsFlightType,
    price: price ? parseInt(price) : price,

    residents,
    specialVisitors,

    planToCloseAt: getDateOrUndefined(planToCloseAt) || new Date(),
    maxQueueSize: maxQueueSize ? parseInt(maxQueueSize) : maxQueueSize,
    maxAllowed: maxAllowed ? parseInt(maxAllowed) : maxAllowed,
    maxStayTime: maxStayTime ? parseInt(maxStayTime) : maxStayTime,

    allowedPassengerType,
    requirePassword,

    queueSize,
    queueLocked: queueStatus === "locked", // TODO: refactor

    hostVerified,
    catalog,
    wishlist,

    dodoCodeLastChangedAt,
  };
}

export function useFlight(flightCreatorId?: string) {
  const [state, setState] = React.useState<FlightPublic | null | undefined>(
    undefined
  );

  React.useEffect(() => {
    if (!flightCreatorId) return;
    const ref = Ref.flight(flightCreatorId);
    const callback = ref.on(
      "value",
      (snapshot: firebase.database.DataSnapshot) => {
        if (!snapshot.exists()) {
          setState(null);
          return;
        }
        setState(mapDataToFlight(snapshot.val(), snapshot.key!));
      }
    );

    return () => {
      ref.off("value", callback);
    };
  }, [flightCreatorId]);

  return state;
}
