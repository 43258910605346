import { ChatRecordContentType } from "../common";

export interface ChatRecord {
  id: string;
  uid: string; // "SYSTEM" reserved uid for system notifications
  contentType: ChatRecordContentType;
  content: string;
  at: Date;
}

export enum ChatRecordSystemContentType {
  FLIGHT_OPEN = "FLIGHT_OPEN",
  FLIGHT_END = "FLIGHT_END",
  FLIGHT_MESSAGE = "FLIGHT_MESSAGE",
  FLIGHT_ENTRY_FEE = "FLIGHT_ENTRY_FEE",
  FLIGHT_TURNIPS_FLIGHT_TYPE = "FLIGHT_TURNIPS_FLIGHT_TYPE",
  FLIGHT_PRICE = "FLIGHT_PRICE",
  FLIGHT_RESIDENTS = "FLIGHT_RESIDENTS",
  FLIGHT_SPECIAL_VISITORS = "FLIGHT_SPECIAL_VISITORS",
  FLIGHT_PLAT_TO_CLOSE_AT = "FLIGHT_PLAN_TO_CLOSE_AT",
  FLIGHT_MAX_QUEUE_SIZE = "FLIGHT_MAX_QUEUE_SIZE",
  FLIGHT_MAX_ALLOWED = "FLIGHT_MAX_ALLOWED",
  FLIGHT_MAX_STAY_TIME = "FLIGHT_MAX_STAY_TIME",
  FLIGHT_ALLOWED_PASSENGER_TYPE = "FLIGHT_ALLOWED_PASSENGER_TYPE",
  FLIGHT_REQUIRE_PASSWORD = "FLIGHT_REQUIRE_PASSWORD",
  FLIGHT_PASSWORD = "FLIGHT_PASSWORD",
  FLIGHT_DODO_CODE = "FLIGHT_DODO_CODE",
}

export interface ChatRecordSystemContent {
  type: ChatRecordSystemContentType;
  before?: any;
  after?: any;
}
