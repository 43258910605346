import {
  Box,
  Typography,
  Breadcrumbs,
  Link,
  CircularProgress,
} from "@material-ui/core";
import { useIsMobile } from "hooks/useIsMobile";
import React from "react";
import { Helmet } from "react-helmet";
import { Announcement } from "../../components/Announcement";

import { Space } from "../../components/Space";
import { IslandBanner } from "./components/Banner/Banner";
import { useFlight } from "hooks/useFlight";
import { useUserProfile } from "hooks/useUserProfile";
import { useParams } from "react-router-dom";
import { AuthContext } from "modules/auth/AuthContext";
import { ProfileContext } from "modules/auth/ProfileContext";
import { useFlightQueue } from "hooks/useFlightQueue";
import { getFlightInfo } from "utils/getFlightInfo";
import { Alert } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import FlightInteractionSection from "./components/FlightInteractionSection";
import { colors } from "common/colors";
import IslandInfoSection from "./components/IslandInfoSection";
import Row from "components/Row";
import { ReportModal } from "./components/Report/ReportModal";
import { useReportModal } from "./components/Report/useReportModal";

export const IslandPage: React.FC = () => {
  const { t } = useTranslation("IslandScreen");
  const isMobile = useIsMobile();

  const { islandId: flightCreatorId } = useParams<{ islandId: string }>();

  const flight = useFlight(flightCreatorId);
  const flightCreatorProfile = useUserProfile(flightCreatorId);

  const user = React.useContext(AuthContext);
  const profile = React.useContext(ProfileContext);
  const flightQueue = useFlightQueue(flight?.flightCreatorId);

  const flightInfo = getFlightInfo({
    flight,
    flightQueue,
    user,
    userProfile: profile,
  });

  // TODO: refactor Report Modal 💩
  const reportModal = useReportModal();

  // TODO: reduce duplication
  if (flightCreatorProfile === null) {
    return (
      <>
        <Helmet>
          <title>Island - Link - The ACNH Community</title>
          <meta
            name="apple-itunes-app"
            content={`app-id=3Y3ND925XU, app-argument=${
              user
                ? `https://acnh.link/island/${user.uid}`
                : "https://acnh.link"
            }`}
          />
        </Helmet>
        <Announcement />
        <Space size={2} />
        <Breadcrumbs aria-label="breadcrumb">
          <Link style={{ fontSize: "0.8em" }} color="inherit" href="/airport">
            Airport
          </Link>
          <Link
            style={{ fontSize: "0.8em" }}
            color="textPrimary"
            aria-current="page"
          >
            Unknown Island
          </Link>
        </Breadcrumbs>
        <Space size={2} />
        <Alert variant="outlined" severity="warning">
          Couldn't find this island.
        </Alert>
      </>
    );
  }

  if (flight === undefined || flightCreatorProfile === undefined) {
    return (
      <>
        <Helmet>
          <title>Island - Link - The ACNH Community</title>
          <meta
            name="apple-itunes-app"
            content={`app-id=3Y3ND925XU, app-argument=${
              user
                ? `https://acnh.link/island/${user.uid}`
                : "https://acnh.link"
            }`}
          />
        </Helmet>
        <Announcement />
        <Space size={2} />
        <Breadcrumbs aria-label="breadcrumb">
          <Link style={{ fontSize: "0.8em" }} color="inherit" href="/airport">
            Airport
          </Link>
          <Link
            style={{ fontSize: "0.8em" }}
            color="textPrimary"
            aria-current="page"
          >
            Unknown Island
          </Link>
        </Breadcrumbs>
        <Space size={2} />
        <Row center>
          <CircularProgress />
        </Row>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{profile?.islandName} Island - Link - The ACNH Community</title>
        <meta
          name="apple-itunes-app"
          content={`app-id=3Y3ND925XU, app-argument=${
            user ? `https://acnh.link/island/${user.uid}` : "https://acnh.link"
          }`}
        />
      </Helmet>
      <Announcement />
      <Space size={2} />
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          style={{ fontSize: "0.8em" }}
          color="inherit"
          href="/airport"
          onClick={() => {}}
        >
          Airport
        </Link>
        <Link
          style={{ fontSize: "0.8em" }}
          color="textPrimary"
          href="/components/breadcrumbs/"
          onClick={() => {}}
          aria-current="page"
        >
          Zhizhi Island
        </Link>
      </Breadcrumbs>
      <Space size={2} />
      {!!flight && <IslandBanner flight={flight} flightInfo={flightInfo} />}
      <Space size={2} />
      <Box
        style={{ display: "flex", flexDirection: isMobile ? "column" : "row" }}
      >
        <Box style={{ flex: 1 }}>
          <IslandInfoSection
            flight={flight}
            flightCreatorProfile={flightCreatorProfile}
            onPressReport={reportModal.open}
          />
        </Box>
        <Box style={{ width: 32, height: 32 }}></Box>
        <Box
          style={{
            backgroundColor: "white",
            flex: 1,
            padding: "48px 16px",
            borderRadius: 8,
            height: "fit-content",
          }}
        >
          {flight ? (
            <FlightInteractionSection
              flight={flight}
              flightInfo={flightInfo}
              flightQueue={flightQueue}
              onPressReport={reportModal.open}
            />
          ) : (
            <Typography
              variant="caption"
              style={{
                display: "block",
                textAlign: "center",
                color: colors["text-hint-color"],
              }}
            >
              This island is not open for visit now.
            </Typography>
          )}
        </Box>
      </Box>
      {!!user && reportModal.visible && (
        <ReportModal
          visible={reportModal.visible}
          onRequestClose={reportModal.close}
          reporteeUid={reportModal.params?.reporteeUid}
          reporteeMessage={reportModal.params?.reporteeMessage}
          subject={reportModal.params?.subject}
          subjectMetadata={reportModal.params?.subjectMetadata}
        />
      )}
    </>
  );
};
