import React from "react";
import { Helmet } from "react-helmet";
import { Announcement } from "components/Announcement";
import { Space } from "components/Space";
import { Box, Breadcrumbs, Link } from "@material-ui/core";
import { InProgressModal } from "components/InProgressModal";
import { ProfileContext } from "modules/auth/ProfileContext";
import { useIsMobile } from "hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import { AuthContext } from "modules/auth/AuthContext";
import { Redirect } from "react-router-dom";
import BasicInfoSection from "./components/BasicInfoSection";
import VisitMyIslandButton from "./components/VisitMyIslandButton";
import DetailsSection from "./components/DetailsSection";
import { useUserPrivate } from "hooks/useUserPrivate";

export const PassportPage: React.FC = () => {
  const { t } = useTranslation("PassportScreen");
  const isMobile = useIsMobile();
  const user = React.useContext(AuthContext);
  const profile = React.useContext(ProfileContext);
  const userPrivate = useUserPrivate(user?.uid);

  if (user === null || profile === null) {
    return <Redirect to="/sign-in" />;
  }

  if (
    user === undefined ||
    profile === undefined ||
    userPrivate === undefined
  ) {
    return (
      <>
        <Announcement />
        <Space size={2} />
        <Breadcrumbs aria-label="breadcrumb">
          <Link style={{ fontSize: "0.8em" }} color="inherit" href="/">
            Home
          </Link>
          <Link
            style={{ fontSize: "0.8em" }}
            color="textPrimary"
            aria-current="page"
          >
            Passport
          </Link>
        </Breadcrumbs>
        <Space size={2} />
        <InProgressModal visible={true} />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Passport - Link - The ACNH Community</title>
        <meta
          name="apple-itunes-app"
          content={`app-id=3Y3ND925XU, app-argument=https://acnh.link/passport`}
        />
      </Helmet>
      <Announcement />
      <Space size={2} />
      <Breadcrumbs aria-label="breadcrumb">
        <Link style={{ fontSize: "0.8em" }} color="inherit" href="/">
          Home
        </Link>
        <Link
          style={{ fontSize: "0.8em" }}
          color="textPrimary"
          aria-current="page"
        >
          Passport
        </Link>
      </Breadcrumbs>
      <Space size={2} />
      <Box
        style={{ display: "flex", flexDirection: isMobile ? "column" : "row" }}
      >
        <Box
          style={{
            flex: 1,
            height: "fit-content",
          }}
        >
          <BasicInfoSection user={user} profile={profile} />
          <VisitMyIslandButton user={user} />
        </Box>
        <Box style={{ width: 32, height: 32 }}></Box>
        <Box style={{ flex: 1 }}>
          <DetailsSection profile={profile} userPrivate={userPrivate} />
        </Box>
      </Box>
    </>
  );
};
