import React from "react";
import { Helmet } from "react-helmet";
import { Breadcrumbs, CircularProgress, Link } from "@material-ui/core";
import { Announcement } from "components/Announcement";
import { Space } from "components/Space";
import { FlightForm } from "./components/FlightForm/FlightForm";
import { AuthContext } from "modules/auth/AuthContext";
import { Redirect } from "react-router-dom";
import Row from "components/Row";
import { useTranslation } from "react-i18next";

export const FlightFormPage: React.FC = () => {
  const { t } = useTranslation("FlightFormScreen");
  const user = React.useContext(AuthContext);

  if (user === null) {
    return <Redirect to="/sign-in" />;
  }

  if (user === undefined) {
    return (
      <>
        <Helmet>
          <title>Manage Flight - Link - The ACNH Community</title>
          <meta
            name="apple-itunes-app"
            content="app-id=3Y3ND925XU, app-argument=https://acnh.link/flight/manage"
          />
        </Helmet>
        <Announcement />
        <Space size={2} />
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            style={{ fontSize: "0.8em" }}
            color="inherit"
            href="/airport"
            onClick={() => {}}
          >
            Airport
          </Link>
          <Link
            style={{ fontSize: "0.8em" }}
            color="textPrimary"
            href="/flight/manage"
            onClick={() => {}}
            aria-current="page"
          >
            Manage Flight
          </Link>
        </Breadcrumbs>
        <Space size={2} />
        <Row center>
          <CircularProgress />
        </Row>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Manage Flight - Link - The ACNH Community</title>
        <meta
          name="apple-itunes-app"
          content="app-id=3Y3ND925XU, app-argument=https://acnh.link/flight/manage"
        />
      </Helmet>
      <Announcement />
      <Space size={2} />
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          style={{ fontSize: "0.8em" }}
          color="inherit"
          href="/airport"
          onClick={() => {}}
        >
          Airport
        </Link>
        <Link
          style={{ fontSize: "0.8em" }}
          color="textPrimary"
          href="/flight/manage"
          onClick={() => {}}
          aria-current="page"
        >
          Manage Flight
        </Link>
      </Breadcrumbs>
      <Space size={2} />
      <FlightForm />
    </>
  );
};
