import React from "react";
import firebase from "../common/firebase";
import { getDateOrUndefined } from "../utils/getDateOrUndefined";
import { FirebaseFirestore } from "@firebase/firestore-types";

export interface Article {
  id: string;
  title: string;
  content: string;
  author: string;
  image?: string;
  publishedAt: Date;
}

export function useArticle(id: string | undefined) {
  const [state, setState] = React.useState<Article | undefined | null>(
    undefined
  );

  React.useEffect(() => {
    if (id === undefined) return;

    (firebase.firestore() as FirebaseFirestore)
      .collection("article")
      .doc(id)
      .get()
      .then((snapshot) => {
        if (!snapshot.exists) {
          setState(null);
          return;
        }
        const { title, content, author, image, publishedAt } = snapshot.data()!;

        setState({
          id,
          title,
          content,
          author,
          image,
          publishedAt: getDateOrUndefined(publishedAt) || new Date(), // TODO: refactor
        });
      });
  }, [id]);

  return state;
}
