import { Button, Paper, Typography } from "@material-ui/core";
import { PostApi } from "api/post";
import { colors } from "common/colors";
import { Space } from "components/Space";
import VillagerItem from "components/TouchableImageList/VillagerItem";
import { ResidentStatus } from "contract/common";
import { ResidentWithStatus } from "contract/data-collection/flight";
import { AuthContext } from "modules/auth/AuthContext";
import React from "react";
import { useTranslation } from "react-i18next";
import InactiveOverlay from "./InactiveOverlay";

export interface PostItemResidentProps {
  postId: string;
  resident: ResidentWithStatus;
  active: boolean;
  shouldShowCTA?: boolean;
}

const PostItemResident: React.FC<PostItemResidentProps> = ({
  postId,
  resident,
  active,
  shouldShowCTA = true,
}) => {
  const { t } = useTranslation("PostItemResident");
  const user = React.useContext(AuthContext);

  return (
    <Paper
      elevation={0}
      style={{
        borderRadius: 8,
        padding: 8,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: colors["background-basic-color-2"],
      }}
    >
      <VillagerItem size={32} id={resident.id} status={resident.status} />
      <Space horizontal size={1} />
      <Typography variant="caption" style={{ flex: 1 }}>
        {t(`status.${resident.status}`)!}
      </Typography>
      {resident.status === ResidentStatus.MOVING_OUT && shouldShowCTA && (
        <Button size="small" variant="outlined" disabled>
          {t("cta")!}
        </Button>
      )}
      {!active && <InactiveOverlay />}
    </Paper>
  );
};

export default PostItemResident;
