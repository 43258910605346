import { Variant } from "../assets/acdb/items";

export function getItemImage(variant: Variant): string | undefined {
  return (
    variant.inventoryImage ||
    variant.framedImage ||
    variant.image ||
    variant.closetImage ||
    variant.albumImage ||
    undefined
  );
}
