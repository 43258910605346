import { Box, Typography } from "@material-ui/core";
import useItem from "hooks/useItem";
import React from "react";

export interface EntryFeeItemProps {
  id: string;
  count: number;
  desc?: string;
  size?: number;
}

export const EntryFeeItem: React.FC<EntryFeeItemProps> = ({
  id,
  count,
  desc,
  size = 14,
}) => {
  const item = useItem(id);

  if (!item || !item.image) return null;

  const finalDesc = desc
    ? desc
    : id === "MwHcZBfqcJ5vJzRqZ"
    ? "99k"
    : undefined;

  return (
    <Box
      style={{
        display: "inline-flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "0 8px",
      }}
    >
      <img
        src={item.image}
        style={{ width: size * 2, height: size * 2 }}
        alt={desc || "item"}
      />
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {!!finalDesc && (
          <Typography
            style={{
              fontSize: size * 0.8,
              fontWeight: 600,
            }}
          >
            {" "}
            {finalDesc}{" "}
          </Typography>
        )}
        <Typography style={{ fontSize: size * 0.8, padding: "0 4px" }}>
          {" "}
          x{" "}
        </Typography>
        <Typography
          style={{
            fontSize: size,
            fontWeight: 600,
          }}
        >
          {String(count)}
        </Typography>
      </Box>
    </Box>
  );
};
