import { Ref } from "api/ref";
import { PostReplyMetadata } from "contract/data-collection/post";
import React from "react";

export function getPostReplyMetadata(
  data: firebase.firestore.DocumentSnapshot
): PostReplyMetadata {
  return {
    likeCount: data.get("likeCount") || 0,
  };
}

export default function usePostReplyMetadata(
  postId: string,
  postReplyId: string
) {
  const [state, setState] = React.useState<
    PostReplyMetadata | null | undefined
  >();

  React.useEffect(() => {
    Ref.postReplyMetadata(postId, postReplyId).onSnapshot((snapshot) => {
      if (!snapshot || !snapshot.exists) {
        setState(null);
        return;
      }

      setState(getPostReplyMetadata(snapshot));
    });
  }, [postId, postReplyId]);

  return state;
}
