import React from "react";
import { AuthContext } from "modules/auth/AuthContext";
import { useUserQueueing } from "hooks/useUserQueueing";
import { CurrentQueueingItem } from "./CurrentQueueingItem";
import { Box } from "@material-ui/core";

export interface CurrentQueueingProps {}

export const CurrentQueueing: React.FC<CurrentQueueingProps> = () => {
  const user = React.useContext(AuthContext);
  const queues = useUserQueueing(user);

  return (
    <Box>
      {queues?.map((flightCreatorId) => (
        <CurrentQueueingItem
          key={flightCreatorId}
          flightCreatorId={flightCreatorId}
        />
      ))}
    </Box>
  );
};
