import { Items } from "assets/acdb/items";
import { Villagers } from "assets/acdb/villagers";

export class ACDB {
  init() {}

  async getItemById(id: string): Promise<Items> {
    return fetch(
      `https://unpkg.com/acnh-db-json@0.2.0/json/items/${id}.json`
    ).then((response) => response.json());
  }

  async getVillagerById(id: string): Promise<Villagers> {
    return fetch(
      `https://unpkg.com/acnh-db-json@0.2.0/json/villagers/${id}.json`
    ).then((response) => response.json());
  }
}
