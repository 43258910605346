import React from "react";
import { Ref } from "api/ref";

export function usePostReplyLiked(
  postId: string,
  postReplyId: string,
  uid: string | undefined,
) {
  const [state, setState] = React.useState(false);

  React.useEffect(() => {
    if (!uid) return;

    Ref.postReplyLike(postId, postReplyId, uid).onSnapshot((snapshot) => {
      if (!snapshot || !snapshot.exists) {
        setState(false);
        return;
      }

      setState(true);
    });
  }, [postId, postReplyId, uid]);

  return state;
}
