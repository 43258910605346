import { Typography } from "@material-ui/core";
import { colors } from "common/colors";
import React from "react";

export interface ChatMessageSystemProps {
  content: string;
}

export const ChatMessageSystem: React.FC<ChatMessageSystemProps> = ({
  content,
}) => {
  return (
    <Typography
      variant="caption"
      style={{
        color: colors["txt-color-brown-400"],
        textAlign: "center",
        padding: "4px 16px",
      }}
    >
      {content}
    </Typography>
  );
};
