import { Ref } from "./ref";
import { ChatRecord } from "contract/data-collection/chat";
import { ChatRecordContentType } from "contract/common";
import firebase from "common/firebase";
import { clean } from "common/word-filter";

export class ChatApi {
  static send(flightCreatorId: string, message: string) {
    if (firebase.auth().currentUser === null) {
      return Promise.reject("Unauthorised.");
    }

    return new Promise<void>((resolve, reject) => {
      const chatRecord: ChatRecord = {
        uid: firebase.auth().currentUser!.uid,
        contentType: ChatRecordContentType.String,
        content: clean(message),
        at: firebase.database.ServerValue.TIMESTAMP,
      };
      return Ref.flightChat(flightCreatorId).push(chatRecord, (error) => {
        if (error) {
          // crashlytics().recordError(error);
          reject(error);
          return;
        }

        resolve();
      });
    });
  }
}
