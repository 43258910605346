import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";

const useStyles = (disabled: boolean, selected: boolean) =>
  makeStyles((theme) => ({
    container: {
      flex: 1,
      paddingRight: 16,
      maxWidth: 160,
      cursor: disabled ? "not-allowed" : "pointer",
      "&:active": {
        opacity: 0.8,
      },
      position: "relative",
      opacity: selected ? 0.8 : 1,
      textDecoration: "none",
    },
  }));

export interface ColouredCellProps {
  bgColor?: string; // Note: should be one of the colors in mapping
  eyebrow: string;
  eyebrowColor?: string;
  image: any;
  title: string;
  onPress?: () => void;
  href?: string;
  titleColor?: string;
  disabled?: boolean;
  selected?: boolean;
}

export function ColouredCell({
  bgColor = "#f1e26f",
  eyebrow,
  eyebrowColor = "#8F8F4C",
  image,
  title,
  onPress,
  href,
  titleColor = "#6B5C43",
  disabled = false,
  selected = false,
}: ColouredCellProps) {
  const styles = useStyles(disabled, selected)();

  return (
    <Box
      component={href ? "a" : undefined}
      // @ts-ignore
      href={href}
      onClick={disabled ? undefined : onPress}
      className={styles.container}
    >
      <Box
        style={{
          padding: 16,
          backgroundColor: bgColor,
          borderRadius: 16,
        }}
      >
        <Typography
          variant="caption"
          style={{
            color: eyebrowColor,
          }}
        >
          {eyebrow}
        </Typography>
        <Typography
          variant="h6"
          style={{
            color: titleColor,
            lineHeight: 1.2,
          }}
        >
          {title}
        </Typography>
      </Box>
      <img
        alt="cell"
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          width: 48,
          height: 48,
        }}
        src={image}
      />
    </Box>
  );
}
