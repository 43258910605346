import { FlightPublic } from "contract/view-collection/flight";

export function filterGeneralFlights(
  flights: FlightPublic[],
  filters: {
    hasVillager: boolean;
    hasSpecialVisitor: boolean;
    hasCatalog: boolean;
    hasWishlist: boolean;
  }
): FlightPublic[] {
  return flights.filter((flight) => {
    if (filters.hasVillager) {
      if (flight.residents && flight.residents.length > 0) {
        return true;
      } else {
        return false;
      }
    }

    if (filters.hasSpecialVisitor) {
      if (flight.specialVisitors && flight.specialVisitors.length > 0) {
        return true;
      } else {
        return false;
      }
    }

    // HACKY: handled by cell
    if (filters.hasCatalog) {
      return true;
    }

    // HACKY: handled by cell
    if (filters.hasWishlist) {
      return true;
    }

    return true;
  });
}
