import { FlightForm } from "contract/data-collection/flight";
import React from "react";
import { Ref } from "api/ref";
import {
  Terminal,
  TurnipsFlightType,
  FlightPassengerType,
} from "contract/common";

// Note: only use this for flight host
export default function useFlightFormData(flightCreatorId: string | undefined) {
  const [flight, setFlight] = React.useState<FlightForm | null | undefined>(
    undefined
  );

  React.useEffect(() => {
    if (!flightCreatorId) {
      setFlight(undefined);
      return;
    }
    const unsubscribe = Ref.flightCurrent(flightCreatorId).onSnapshot(
      (snapshot: any) => {
        if (!snapshot.exists) {
          setFlight(null);
          return;
        }

        const {
          terminal = Terminal.TURNIPS,
          message,

          turnipsFlightType = TurnipsFlightType.SELLING,
          price,

          residents,
          specialVisitors,
          catalogAndWishlist,

          entryFees,

          planToCloseAt,
          maxQueueSize = 99,
          maxAllowed = 2,
          maxStayTime = 0,

          allowedPassengerType = FlightPassengerType.ALL,
          requirePassword = false,

          dodoCode,
          password,

          status = "closed",
        } = snapshot.data();

        const data: FlightForm = {
          terminal,
          message,

          turnipsFlightType,
          price,

          residents,
          specialVisitors,
          catalogAndWishlist,

          entryFees,

          planToCloseAt,
          maxQueueSize: maxQueueSize ? parseInt(maxQueueSize) : maxQueueSize,
          maxAllowed: maxAllowed ? parseInt(maxAllowed) : maxAllowed,
          maxStayTime: maxStayTime ? parseInt(maxStayTime) : maxStayTime,

          allowedPassengerType,
          requirePassword,

          dodoCode,
          password,

          status,
        };

        setFlight(data);
      },
      (error: any) => {
        // crashlytics().recordError(error);
        setFlight(null);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [flightCreatorId]);

  return flight;
}
