import React from "react";
import * as utils from "common/persist/utils";

export const PERSIST_KEY = "EULAConsent";

export function useEULAConsent() {
  const [agreed, setAgreed] = React.useState<boolean | undefined>(undefined);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    utils.get(PERSIST_KEY).then((persisted) => {
      if (!persisted) {
        setAgreed(false);
        setLoading(false);
        return;
      }

      setAgreed(persisted === "true");
      setLoading(false);
    });
  }, []);

  const onChange = React.useCallback(({ target: { checked } }) => {
    setLoading(true);
    utils
      .set(PERSIST_KEY, checked ? "true" : "false")
      .then(() => {
        setAgreed(checked);
      })
      .catch((error) => {
        // pass
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return {
    consented: agreed,
    onChange,
    loading,
  };
}
